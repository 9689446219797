import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useGlobal from '../../../store';
import cssIf from '../../../scripts/helpers/class.add.if';
import { ReactComponent as PlusIcon } from "../../components/smeta/icons/plus.svg";
import "./windows.to.suppliers.scss";
import WindowPartnerRow from '../window.partner.row';
import Textfield from '../textfield';
import API from '../../../settings/api';
import TextfieldCopyToClipboard from '../textfield.clipboard';

const WindowsToSuppliers = ({ opened = false, close = () => {} }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { project_id, windows_partners } = globalState;

  const windowsSchemeUrl = `${ API.CONSTRUCTOR_URL }/windows/${ project_id }`;

  return (

    <div className = {`picker_texture smeta ${ cssIf( opened, `picker_texture--opened` )} balkon_settings windows_to_suppliers`}>

      <div className="smeta__head flex justify-between items-center">
        
        <div className="smeta__head__title font-medium">Отправить окна на оценку</div>

        <div 
          className = {`smeta__head__btn smeta__head__btn--close flex items-center font-medium`}
          onClick = { close }
        >
          
          назад

          <PlusIcon />

        </div>

      </div>

      <TextfieldCopyToClipboard
      
        title = "Скопируйте ссылку на чертёж:"
        value = { windowsSchemeUrl }

      />

      <div className="windows_to_suppliers__list scrollable">

        { windows_partners?.length > 0 && windows_partners.map(( partner, key ) => (

          <WindowPartnerRow

            key = { key }
            company_name = { partner?.company_name || "" }
            owner_name = { partner?.owner_name || "" }
            email = { partner?.email || "" }
            whatsapp = { partner?.whatsapp || "" }
            telegram = { partner?.telegram || "" }
            schemeUrl = { windowsSchemeUrl }
            
          />

        ))}

      </div>


    </div>

  );

}

export default WindowsToSuppliers;