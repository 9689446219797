import Header from "../header";
import "./container.scss";

const Container = ({ className = "", style = {}, children }) => {

  return (

    <div className = "container flex-col flex-1" draggable="false">

      <Header />
      <div className = { className } style = { style } draggable="false">{ children }</div>

    </div>

  );

}

export default Container;