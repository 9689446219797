import { useEffect, useRef } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import cssIf from '../../../scripts/helpers/class.add.if';

import BalconBlockTwo from './balcon.block.two';
import BalconBlockOne from './balcon.block.one';
import InnerWindow    from './inner.window';

import { ReactComponent as MoveIcon } from '../window/icons/move.svg';
import { ReactComponent as RotateIcon } from '../window/icons/rotate.svg';
import { ReactComponent as Arrow } from '../window/icons/arrow.svg';

import './window.inner.scss';
import { inner_windows_types } from '../../../settings/constants';

export const WindowInner = ( props ) => {

  const { 

    id, 
    type = 1, 
    selected = false, 
    mirrored = false, 
    action = false, 
     
  } = props;

  const thisDOM = useRef();

  const WINDOWS = {

    1: <BalconBlockOne { ...props } />,
    2: <BalconBlockTwo { ...props } />,
    3: <InnerWindow    { ...props } />,

  }

  useEffect(() => { thisDOM.current.scrollIntoViewIfNeeded(); }, []);

  return ( 

    <div

      ref = { thisDOM }
      onClick = { () => action ? action() : null }
      className = {`window window--inner flex justify-center items-center window-${ type } ${ cssIf( selected === id, `window--selected` ) } ${ cssIf( mirrored, `window--mirrored` ) }`}

    >
      
      { WINDOWS[ type ] }

      { selected === id && <Arrow className = "selected_arrow" /> }

    </div> 

  );

}

const DragHandler = SortableHandle( () => <MoveIcon className = "window-anchor window-move-anchor" /> );
const RotateHandler = ({ action }) => <RotateIcon className = "window-anchor window-rotate-anchor" onClick = { action } />;

const SortableInnerWindow = SortableElement(({ window, sortIndex, select, selected, rotate, saveWindowSize }) => {

  let sizes = {};

  if ( 

    +window.type === inner_windows_types.balkon_block_cheburashka 
    || 
    +window.type === inner_windows_types.balkon_block_s_oknom
    
  ) {

    sizes = {

      a: window?.a,
      b: window?.b,
      c: window?.c,
      d: window?.d

    }

  } else {

    sizes = { 
      
      x: window?.x,
      y: window?.y
    
    }

  }
  
  return (

    <li className = "relative">
      
      <DragHandler />

      { window.type !== 3 && 
        
        <RotateHandler action = { () => { rotate( window.id ) }} /> 
        
      }

      <WindowInner
                
        key = { sortIndex }
        id = { window.id }
        type = { window.type }
        { ...sizes }
        
        selected = { selected }
        mirrored = { window.mirrored }
        action   = { () => { selected !== window.id && select( window.id ) }}
        save     = { saveWindowSize }
        
      />

    </li>

  )}
  
);


export const SortableInnerWindows = SortableContainer(( props ) => {

  const { 

    windows_schema_inner, 
    select, 
    selected, 
    rotate,
    saveWindowSize

  } = props;
  
  return (

    <div className="schema schema--inner schema_draggable flex items-center">

      <ul>

        { windows_schema_inner?.length > 0 && windows_schema_inner.map(( window, index ) => (

          <SortableInnerWindow

            key = { `item-${index}` }
            index = { index }
            sortIndex = { index }
            window = { window }
            select = { select }
            selected = { selected }
            rotate = { rotate }
            saveWindowSize = { saveWindowSize }

          />

        ))}

      </ul>

    </div>

  )

});