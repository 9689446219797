function handleSetSize( input, setValue ) {

  setValue(

    input
      .replace(",",".")
      .replace(/[^.\d]+/g,"")
      .replace( /^([^\.]*\.)|\./g, '$1' )
      
  );

}

export default handleSetSize;