
import React, { useState } from "react";
import useGlobal from "../../../store";
import Button from "../button";
import Textfield from "../textfield";

const SmetaAeratedMasonry = ({ close }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { aeratedMasonry } = globalState;
  const { environment } = globalActions;
  
  const [ length, setLength ] = useState( aeratedMasonry.length );
  const [ height, setHeight ] = useState( aeratedMasonry.height );

  function handleAddAeratedMasonry() {
    
    environment.addEnvironmentToSmeta("aeratedMasonry", { added: true, length, height } );
    close();

  }
  
  return (

    <div className="smeta__isolation p-14" draggable="false">
      
      <div 
      
        className = "flex inputs items-end"
        style = {{

          maxWidth: "220px",
          margin: "auto",
          textAlign: "center"

        }}

      >

        <Textfield

          number
          title = "Длина"
          value = { length }
          set = { setLength }

        />

        <Textfield

          number
          title = {`Высота`}
          value = { height }
          set = { setHeight }

        />

      </div>

      <Button

        text = { !aeratedMasonry.added ? "Добавить" : "Сохранить" }
        action = { handleAddAeratedMasonry }
        disabled = { length.length < 1 && height.length < 1 }

      />

    </div>

  );

}

export default SmetaAeratedMasonry;