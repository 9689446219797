import React, { useState } from 'react';
import WindowInnerSizeField from '../window.inner.size.field';
import { ReactComponent as Body } from './icons/body.svg';
import './balcon.block.two.scss';

const BalconBlockTwo = ({ a = "", b = "", c = "", d = "", save = () => {} }) => {

  const [ A, setA ] = useState( a );
  const [ B, setB ] = useState( b );
  const [ C, setC ] = useState( c );
  const [ D, setD ] = useState( d );

  return (

    <div className="balcon-block-two relative">

      <Body />
      
      <WindowInnerSizeField
              
        value = { A }
        set = { setA }        
        save = { () => save( "a", A ) }
        sortName = "A"
        
      />

      <WindowInnerSizeField
              
        value = { B }
        set = { setB }        
        save = { () => save( "b", B ) }
        sortName = "B"
        
      />

      <WindowInnerSizeField
              
        value = { C }
        set = { setC }        
        save = { () => save( "c", C ) }
        sortName = "C"
      
      />
      
      <WindowInnerSizeField
      
        value = { D }
        set = { setD }        
        save = { () => save( "d", D ) }
        sortName = "D"
        
      />

    </div>

  );

}

export default BalconBlockTwo;