import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useGlobal from "../../../store";
import Loading from "../../components/loading";
import WindowsConstructor from "../../components/windows.constructor";
import WindowsSettings from "../../components/windows.settings";
import "./windows.scheme.scss";

const WindowsSchemeShared = () => {

  const { id } = useParams();
  const [ globalState, globalActions ] = useGlobal();
  const { windows_id } = globalState;
  const { windows } = globalActions;

  useEffect(() => {

    ( id && id !== "" ) && windows.get( id );

  }, [ id ])

  return (

    windows_id === 0 ? <Loading /> :

    <div className = "balkon_settings__windows balkon_scheme_public flex flex-col">

      <div className="balkon_settings__title text-center">Окна:</div>
      
      <WindowsConstructor readOnly />
      <WindowsSettings readOnly />

    </div>

  );

}

export default WindowsSchemeShared;