import { useState, useEffect, useRef } from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import './window.scss';

import { ReactComponent as ApplyIcon } from '../../../images/icons/apply.svg';
import { ReactComponent as CancelIcon } from '../../../images/icons/cancel.svg';

import { ReactComponent as WindowOne } from './img/1.svg';
import { ReactComponent as WindowTwo } from './img/2.svg';
import { ReactComponent as WindowThree } from './img/3.svg';
import { ReactComponent as WindowFour } from './img/4.svg';
import { ReactComponent as WindowFive } from './img/5.svg';
import { ReactComponent as WindowSix } from './img/6.svg';
import { ReactComponent as WindowSeven } from './img/7.svg';

import { ReactComponent as MoveIcon } from './icons/move.svg';
import { ReactComponent as Arrow } from './icons/arrow.svg';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

export const Window = ({ id, type = 1, selected = false, mirrored = false, width = false, height = false, action = false }) => {

  const thisDOM = useRef();

  const WINDOWS = {

    1: <WindowOne />,
    2: <WindowTwo />,
    3: <WindowThree />,
    4: <WindowFour />,
    5: <WindowFive />,
    6: <WindowSix />,
    7: <WindowSeven />,

  }

  useEffect(() => { thisDOM.current.scrollIntoViewIfNeeded(); }, [])

  return ( 

    <div

      ref = { thisDOM }
      onClick = { () => action ? action() : null }
      className = {`window flex justify-center items-center window-${ type } ${ cssIf( selected === id, `window--selected` ) } ${ cssIf( mirrored, `window--mirrored` ) }`}

    >
      
      { WINDOWS[ type ] }

      {/* {( width && height ) && <div className="window__size">{ width }/{ height }</div> } */}

      { selected === id && <Arrow className = "selected_arrow" /> }

    </div> 

  );

}

const DragHandler = SortableHandle( () => <MoveIcon className = "window-move-anchor" /> );

const SortableWindow = SortableElement(({ window, sortIndex, select, selected, rotate }) => (

  <li className = "relative">
    
    <DragHandler />

    <Window 
              
      key = { sortIndex }
      id = { window.id }
      type = { window.type }
      // width = { window?.width ? window.width : false }
      // height = { window?.height ? window.height : false }
      selected = { selected }
      mirrored = { window.mirrored }
      action = { () => {
        
        if ( selected !== window.id ) {

          select( window.id );

        } else if ( window.type !== 2 && window.type !== 4 ) {
          
          rotate( window.id );
        
        }
      
      }}
      
    />

  </li>

));

export const PartOfWindows = ({ index, space, size = 0, set = () => {} }) => {

  const thisInput = useRef( null );
  const [ showInput, setShowInput ] = useState( false );
  const [ partSize, setPartSize ] = useState( size );

  function closeInput() { setShowInput( false ); }

  function handleShowInput() {

    setShowInput( true );

    setTimeout(() => {

      thisInput.current.focus();

    }, 200 );

  }

  function handleSaveSize() {

    set( index, partSize );
    closeInput();

  }

  function handleResetSize() {

    setPartSize( size );
    closeInput();

  }

  return (

    <div 

      className = "schema__partsize__el"
      style = {{ width: `${ space }${ cssIf( space.toString().indexOf('%') === -1, "px" ) }` }}

    >

      { !showInput

        ? <div 
            className = "schema__partsize__el__size"
            onClick = { handleShowInput }
          >

            { partSize }

          </div>

        : <div className="flex items-center justify-between">

            <CancelIcon onClick = { handleResetSize } />

            <input

              type = "text"
              ref = { thisInput }
              value = { partSize }
              className = "schema__partsize__el__size"
              onChange = { ( e ) => setPartSize( e.target.value ) }
              onBlur = { handleSaveSize }
              onKeyUp = { ( e ) => {

                e.key === "Enter" 

                  ? handleSaveSize()
                  : e.key === "Escape" 

                    && handleResetSize()

              }}

            />

            <ApplyIcon onClick = { handleSaveSize } />

          </div>
      
      }


    </div>

  );

}

export const WindowsHeight = ({ size = 0, set = () => {} }) => {

  const thisInput = useRef( null );
  const [ showInput, setShowInput ] = useState( false );
  const [ partSize, setPartSize ] = useState( size );

  function closeInput() { setShowInput( false ); }

  function handleShowInput() {

    setShowInput( true );

    setTimeout(() => {

      thisInput.current.focus();

    }, 200 );

  }

  function handleSaveSize() {

    set( partSize );
    closeInput();

  }

  function handleResetSize() {

    setPartSize( size );
    closeInput();

  }

  return (

    <div className="schema__heightsize">

      { !showInput

        ? <div 
            className = "schema__partsize__el__size"
            onClick = { handleShowInput }
          >

            { partSize }

          </div>

        : <div className="flex flex-col items-center justify-between">

            <CancelIcon onClick = { handleResetSize } />

            <input

              type = "text"
              ref = { thisInput }
              value = { partSize }
              className = "schema__heightsize__size"
              onChange = { ( e ) => setPartSize( e.target.value ) }
              onBlur = { handleSaveSize }
              onKeyUp = { ( e ) => {

                e.key === "Enter" 

                  ? handleSaveSize()
                  : e.key === "Escape" 

                    && handleResetSize()

              }}

            />

            <ApplyIcon onClick = { handleSaveSize } />

          </div>

      }

    </div>

  );

}

export const SortableWindows = SortableContainer(( props ) => {

  const { 

    windows_schema, 
    windows_height = 0, 
    select, 
    selected, 
    rotate,

    renderPartSize = () => {},
    setWindowsHeight = () => {},
    setWindowsAreaSizes = () => {},

  } = props;
  
  return (

    <div className="schema schema_draggable flex items-center">

      <ul>

        { windows_schema?.length > 0 && 

          <WindowsHeight size = { windows_height } set = { setWindowsHeight } />

        }

        { windows_schema?.length > 0 && windows_schema.map(( window, index ) => (

          <SortableWindow

            key = { `item-${index}` }
            index = { index }
            sortIndex = { index }
            window = { window }
            select = { select }
            selected = { selected }
            rotate = { rotate }

          />

        ))}

      </ul>

      <div className="schema__partsize">

        { windows_schema?.length > 0 && renderPartSize().map(( part, key ) => (

          <PartOfWindows

            key   = { key }
            index = { key }
            space = { part.space }
            size  = { part.size }
            set   = { setWindowsAreaSizes }

          />

        ))}

      </div>

    </div>

  )

});