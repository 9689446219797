import React, { useRef, useEffect, useMemo } from 'react';
import { useGLTF } from "@react-three/drei";
import useGlobal from '../store';

const Environment = ( props ) => {

  const { 

    id = "", 
    model = "", 
    position = "", 
    rotationY = false, 
    refDOM = false, 
    onClick = false, 
    syncTextures = () => {}

  } = props;

  const [ globalState, globalActions ] = useGlobal();
  const { constructor } = globalActions;
  
  const ref = useRef();
  //// let { scene = false } = useGLTF( model );
  const GLTF = useGLTF( model );
  const scene = useMemo(() => GLTF.scene.clone(), [ GLTF?.scene ]);

  useEffect(() => {

    if ( scene && model !== "" ) {

      refDOM.current = ({ target = false, texture, uniqName }) => {

        scene.traverse(( o ) => {
    
          if ( o.isMesh ) {

            o.castShadow = true;
            o.receiveShadow = true;

            if ( uniqName === id ) {

              o.material = texture;

              if ( target ) { o.nameID = target; }
                          
            }

          }
      
        });
    
      }
      
      syncTextures( id ); 
      // console.log(`######  Environment syncTextures id=${ id }`);    
  
    }
    
  }, [ model ]);

  return (

    !scene ? null : 
    
    <primitive

      object = { scene || null }
      ref = { ref }
      dispose = { null }      
      castShadow 
      receiveShadow
      position = { position }
      rotation-y = { rotationY || 0 }
      onClick={( e ) => { 

        if ( scene ) {
          
          constructor.setModelRef( id, e.object.name );
          onClick && onClick();
          e.stopPropagation();
        
        } else {

          console.error(`Попытка повесить onClick на несуществующий/скрытый Environment успешно прервана`);

        }
        
      }}

    />

  );

}

export default Environment;