import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import cookie from 'react-cookies';

import Constructor from './constructor';
import { BalkonSelect, BalkonCustomer, BalkonWindows } from './balkon.form';
import Sign from './sign';
import Main from './main';
import useGlobal from '../../store';
import Employees from './employees';
import EmployeeForm from './employees/employee.form';
import WindowsPartners from './windows.partners';
import WindowsPartnerForm from './windows.partners/windows.partners.form';
import WindowsSchemeShared from './windows.scheme';

const AppRouter = () => {

  useEffect(() => {
  
    const dpi = window.devicePixelRatio;
  
    const ZOOM_VARIANTS = [ '00', '50', '75' ];
    const ZOOM_VALUES   = [ '1',  '35', '35' ];
  
    const zoomPoint = dpi.toFixed( 2 ).toString().split('.')[ 0 ];
    const zoomLevel = dpi.toFixed( 2 ).toString().split('.')[ 1 ];
  
    let dpiZoom = 1;
  
  if ( parseInt( zoomPoint ) >= 2 || parseInt( zoomLevel ) === 25 ) {
  
      dpiZoom = '15'; //25
  
    } else if ( ZOOM_VARIANTS.indexOf( zoomLevel.toString() ) !== -1 ) {
  
      dpiZoom = ZOOM_VALUES[ ZOOM_VARIANTS.indexOf( zoomLevel.toString() ) ];
  
    } else if ( dpi > 1.6 && dpi < 1.8 ) {
  
      dpiZoom = '35';
  
    }
  
    document.documentElement.style.setProperty('--dpi-zoom', dpiZoom );
  
  }, []);

  return (

    <BrowserRouter>

      <Routes>

        <Route exact path='/signin' element = { <Sign /> } />
        <Route exact path='/' element = { <Protected><Main /></Protected> } />

        <Route exact path='/team' element = { <Protected><Employees /></Protected> } />
        <Route exact path='/team/:id' element = { <Protected><EmployeeForm /></Protected> } />

        <Route exact path='/windows_partners' element = { <Protected><WindowsPartners /></Protected> } />
        <Route exact path='/windows_partners/:id' element = { <Protected><WindowsPartnerForm /></Protected> } />
        
        <Route path='/constructor' element = { <Protected><Constructor /></Protected> } />
        <Route exact path='/new/1' element = { <Protected><BalkonCustomer /></Protected> } />
        <Route exact path='/new/2' element = { <Protected><BalkonSelect /></Protected> } />
        <Route exact path='/new/3' element = { <Protected><BalkonWindows /></Protected> } />

        <Route exact path='/windows/:id' element = { <WindowsSchemeShared /> } />

      </Routes>

    </BrowserRouter>

  );

}

export default AppRouter;

const Protected = ({ children }) => {

	const [ globalState, globalActions ] = useGlobal();
	const token = cookie.load('token');

	useEffect(() => { 

    // console.log(`Protected token = `, token);
		
		token 
			? globalActions.sign.Check( token )
			: globalActions.sign.Out()
	
	}, []);

	return children;

}