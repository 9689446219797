import * as THREE from 'three';
import { texturesPath } from '../../settings/constants';

async function setModelRef( store, name, part ) {

  console.log(`setModelRef(${ name }, ${ part }) material part:`, store.state.balkon_materials?.[part]);
  console.log(`setModelRef(${ name }, ${ part }) material texture:`, store.state.balkon_materials?.[part]?.texture);

  let _part = part;

  if ( part.indexOf(`windowSill`) !== -1 ) { _part = `windowSill`; }
  if ( part.indexOf(`window`)     !== -1 ) { _part = `window`; }

  let newState = {

    selectedModelRef: name,
    selectedModelPart: _part,
    selectedPartMaterialIcon: ""

  }

  if ( store.state.balkon_materials?.[ _part ]?.texture ) {
    
    newState.selectedPartMaterialIcon = store.state.balkon_materials?.[ _part ]?.texture;

  }

  store.setState( newState );

}

async function setTexture( store, obj, apply, target = false, environment = false  ) {

  const { selectedModelRef, selectedModelPart, balkon_materials, stretchCeiling } = store.state;

  let _obj = obj;
  let _target = target || selectedModelPart;

  if ( !_target ) {

    console.error(`setTexture error: target is not defined (target=${target}, selectedModelPart=${selectedModelPart}), _target:`, _target )
    return;

  }

  // console.log(`setTexture obj`, obj);

  if ( selectedModelRef === "balkon" && selectedModelPart === "ceiling" && target === "ceiling" ) { // Если потолок

    if ( stretchCeiling > 0 ) { // Если натяжной, присваиваем текстуру натяжного потолка

      _obj.texture = `${ texturesPath }/white.jpg`; 

    } else {

      const finded = balkon_materials?.ceiling?.preview;
      if ( finded ) { _obj.texture = finded; }

    }
    

  }

  // console.log(`setTexture target ${target} obj:`, obj);

  let txt = new THREE.TextureLoader().load( obj.texture );

  obj?.size?.[ 2 ] && txt.repeat.set( obj.size[ 0 ], obj.size[ 1 ], obj.size[ 2 ] );
  if ( obj?.rotation ) { txt.rotation = Math.PI/2; }
  txt.wrapS = THREE.RepeatWrapping;
  txt.wrapT = THREE.RepeatWrapping;

  let new_mtl = new THREE.MeshPhongMaterial({

    map: txt,
    shininess: obj.shininess ? obj.shininess : 10

  });

  let mom = {};

  let _selectedModelRef = selectedModelRef;

  // console.log(`!!!!!!!!!!! setTexture _target.indexOf('cupboard') !== -1 = ${_target.indexOf('cupboard') !== -1 ? "true" : "false"}, selModelRef: `, selectedModelRef);
  

  if (( _target.indexOf('cupboard') !== -1 ) && selectedModelRef === "balkon" ) {

    _selectedModelRef = _obj.uniqName;
    // console.log(`!!!!!!!!!!! setTexture _obj: `, _obj);

  }  

  //! mom[ withoutNumbers( _target ) ] = _obj;
  
  let newState = {}  

  if ( _target.indexOf('cupboard') === -1 && _target.indexOf('lar') === -1 && _target !== 'sill' && !environment ) { // Это не шкаф и не подоконник (это обшивка балкона)

    mom = balkon_materials;

    if ( typeof mom === "string" ) { mom = {} }

    //! if ( _target.indexOf(`windowSill`) !== -1 ) { _target = `windowSill`; }
    //! if ( _target.indexOf(`window`) !== -1 ) { _target = `window`; }

    if ( !mom?.[ _target ] || typeof mom?.[ _target ] !== "object" ) {
      // console.log(`!!!!!!!!!! called mom target ${ _target }`, mom);
      mom[ _target ] = {}
    }

    mom[ _target ] = _obj;

    newState = {

      balkon_materials: mom,
      selectedPartMaterialIcon: obj.preview
  
    }

    // console.log('----------------------------------------------');
    // console.log('balkon_materials (new)', mom);
    // console.log('----------------------------------------------');


  } else if ( _target.indexOf('sill') !== -1 ) { // Это подоконник
    
    // console.log(`setTexture (обнаружен подоконник) environment: sill_materials банк материалов:`, balkon_materials );
    // console.log(`setTexture (обнаружен подоконник) obj:`, obj );
    
    newState['selectedPartMaterialIcon'] = obj.preview;
    newState['sill_texture_image'] = obj.preview;
    newState['sill_texture_name'] = obj.name;
    newState['balkon_materials'] = {

      ...balkon_materials,      
      sill: obj

    }

    
  } else if ( _target.indexOf('lar') !== -1 ) { // Это ларь
    
    // console.log(`setTexture (обнаружен ларь) environment: ${ environment || selectedModelRef }_materials банк материалов:`,  );

    if ( selectedModelRef.indexOf('lar') !== -1 && !_obj?.uniqName ) {

      _obj = {

        ..._obj,
        uniqName: environment || selectedModelRef

      }
    
    }

    newState.selectedPartMaterialIcon = obj.preview;
    newState[`${ environment || selectedModelRef }_materials`] = _obj;
    newState[`${ environment || selectedModelRef }_texture_image`] = obj.preview;
    newState[`${ environment || selectedModelRef }_texture_name`] = obj.name;

    // console.log(`Ларь newState`, newState );
    
  } else { // Это шкаф

    // console.log(`setTexture (обнаружен шкаф) environment: ${ environment || selectedModelRef }_materials банк материалов:`,  );

    if ( selectedModelRef.indexOf('cupboard') !== -1 && !_obj?.uniqName ) {

      _obj = {

        ..._obj,
        uniqName: environment || selectedModelRef

      }
    
    }

    newState.selectedPartMaterialIcon = obj.preview;
    newState[`${ environment || selectedModelRef }_materials`] = _obj;
    newState[`${ environment || selectedModelRef }_texture_image`] = obj.preview;
    newState[`${ environment || selectedModelRef }_texture_name`] = obj.name;

  }

  !target && await store.setState( newState );

  apply && apply({ 
    
    target: environment || target || selectedModelPart, 
    texture: new_mtl,
    uniqName: environment || _selectedModelRef
  
  });

  store.actions.smeta.calculateSmeta();

}

async function syncTextures( store, apply, target = false) {

  const { balkon_materials } = store.state;

  const { setTexture } = store.actions.constructor;

  if ( target === false ) {

    // console.log(`Called syncTextures target=${target}`);

    for ( let part in balkon_materials ) {

      // console.log(`syncTextures[${ part }]`, balkon_materials[ part ] );

      setTexture(

        balkon_materials?.[ part ],
        apply,
        part

      )

    }

  } else { // Если это шкаф или прочее окружение, парсим материалы по target

    // console.log(``);
    // console.log(`syncTextures target`, target);
    // console.log(`syncTextures store.state["${ target }_materials"]`, store.state[`${ target }_materials`] );

    setTexture(
  
      store.state[`${ target }_materials`], //! Если будут модели где текстуру нужно накладывать на разные части модели отдельно, вернуть сюда [ part ] и переосмыслить алгоритм
      apply,
      false,
      target //?

    )

    // for ( let part in store.state[`${ target }_materials`] ) {

    //   console.log(`syncTextures[${ target }][${ part }]`, store.state[`${ target }_materials`][ part ] );
  
    //   setTexture(
  
    //     store.state[`${ target }_materials`][ part ], //! remove -> Если будут модели где текстуру нужно накладывать на разные части модели отдельно, вернуть сюда [ part ] и переосмыслить алгоритм
    //     apply,
    //     part
  
    //   )
  
    // }

  }

}

export {

  setModelRef,
  setTexture,
  syncTextures

}