import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useGlobal from '../../../store';
import Container from '../../components/container';

import { ReactComponent as PlusIcon } from "../../components/smeta/icons/plus.svg";

const WindowsPartners = () => {

  const navigate = useNavigate();

  const [ globalState, globalActions ] = useGlobal();
  const { windows_partners } = globalState;
  const { balkon } = globalActions;

  useEffect(() => { balkon.all(); }, []);
  
  return (

    <Container className = "employees smeta">

      <div className="smeta__head flex justify-between items-center">
        
        <div className="smeta__head__title font-medium">Мои поставщики окон</div>

        <div 
          className = {`smeta__head__btn flex items-center font-medium`}
          onClick = { () => navigate(`/windows_partners/add`) }
        >
          
          добавить

          <PlusIcon />

        </div>

      </div>

      <div className = "windows_partners__list smeta__menu">

        { windows_partners?.length > 0 && windows_partners.map(( partner, key ) => (

          <div
          
            key = { key }
            className = "smeta__menu__element" 
            onClick = { () => partner?.id && navigate(`/windows_partners/${ partner.id }`) }
            
          >

            { partner?.company_name }

          </div>

        ))}

      </div>

    </Container>

  );

}

export default WindowsPartners;