import React, { useState, useEffect } from "react";
import { insulation_types } from "../../../settings/constants";
import useGlobal from "../../../store";
import Button from "../button";
import Checkbox from "../checkbox";

const SmetaMoldTreatment = ({ close }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { moldTreatment } = globalState;
  const { environment } = globalActions;
  
  const [ border,  setBorder ]  = useState( moldTreatment.border );
  const [ edges,   setEdges ]   = useState( moldTreatment.edges );
  const [ ceiling, setCeiling ] = useState( moldTreatment.ceiling );
  const [ floor,   setFloor ]   = useState( moldTreatment.floor );

  const [ showSaveBtn, setShowSaveBtn ] = useState( false );

  function handleAddMoldTreatment() {

    const form = {

      added: true,
      border,
      edges,
      ceiling,
      floor,

    }

    environment.addEnvironmentToSmeta("moldTreatment", form );
    close();

  }

  useEffect(() => {

    if ( 

      border !== moldTreatment.border
      || edges !== moldTreatment.edges
      || ceiling !== moldTreatment.ceiling
      || floor !== moldTreatment.floor

    ) {

      setShowSaveBtn( true );

    } else {

      setShowSaveBtn( false );

    }

  }, [

    border,
    edges,
    ceiling,
    floor

  ]);

  return (

    <div className="smeta__isolation p-14" draggable="false">

      <p className="smeta__smalltext">Отметьте части балкона подлежащие обработке от плесени</p>

      <Checkbox

        text = "Бордюр"
        value = { border }
        set = { setBorder }

      />

      <Checkbox

        text = "Края"
        value = { edges }
        set = { setEdges }

      />

      <Checkbox

        text = "Потолок"
        value = { ceiling }
        set = { setCeiling }

      />

      <Checkbox

        text = "Пол"
        value = { floor }
        set = { setFloor }

      />

      { showSaveBtn &&

        <Button

          text = { !moldTreatment.added ? "Добавить" : "Сохранить" }
          action = { handleAddMoldTreatment }

        />

      }

    </div>

  );

}

export default SmetaMoldTreatment;