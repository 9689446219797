import { post } from 'axios';
import cookie from 'react-cookies';
import API from '../../settings/api';

const SHININESS = {
  WOOD: 1,
  STONE: 50,
  BRICKS: 1,
}

const size = 4;

const defaultSize = {
  x: size,
  y: size,
  z: size
}

const ALL_MATERIALS = [

  // Стены, потолок: --------------------------------------------------

  {
    type: 1, // таблица material_types.id категории материалов

    part: 1, // таблица balkon_parts.id часть балкона для которой подходят материалы этой группы; 
             // 1: стены потолок, 2: пол, 3: подоконники; 
             // стоимость одной и той же категории для разных частей балкона разная! 
             // (например ламинат для стен и потолка одна стоимость, для пола - другая)

    name: "Ламинат", // таблица material_types.name - название категории материалов

    price: 2700, // за м2; таблица pricelist по target = material_types.id

    preview: "images/textures/laminate-3.jpg", // таблица material_types.preview картинка-превью категории материалов

    materials: [ // таблица materials по type = material_types.id

      {
        id: 1,
        name: "Ламинат коричневый",
        texture: 'images/textures/laminate-2.jpg',
        size: [2,2,2],
        shininess: SHININESS.WOOD
      },

      {
        id: 2,
        name: "Ламинат холодный",
        texture: 'images/textures/laminate-3.jpg',
        size: [2,2,2],
        shininess: SHININESS.WOOD
      },

    ]

  },
  
  {
    type: 2,
    part: 1,
    name: "Дерево: паркет",
    price: 3500,
    preview: "images/textures/wood-2.jpg",
    materials: [

      {
        id: 3,
        name: "Доски тёмные узкие",
        texture: 'images/textures/wood-2.jpg',
        size: [1,1,1],
        shininess: SHININESS.WOOD
      },

      {
        id: 4,
        name: "Доски светлые",
        texture: 'images/textures/wood-4.jpg',
        size: [defaultSize.x,defaultSize.y,defaultSize.z],
        shininess: SHININESS.WOOD
      },

    ]

  },
  
  {
    type: 3,
    part: 1,
    name: "Дерево: вагонка (А-В)",
    price: 2100,
    preview: "images/textures/wood-1.jpg",
    materials: [

      {
        id: 5,
        name: "Древесина светлая",
        texture: 'images/textures/wood-1.jpg',
        size: [2,2,2],
        shininess: SHININESS.WOOD
      },

    ]

  },
  
  {
    type: 4,
    part: 1,
    name: "Дерево: МДФ-ПВХ",
    price: 1800,
    preview: "images/textures/wood-3.jpg",
    materials: [

      {
        id: 6,
        name: "Древесина тёмная",
        texture: 'images/textures/wood-3.jpg',
        size: [defaultSize.x,defaultSize.y,defaultSize.z],
        shininess: SHININESS.WOOD
      },

    ]

  },
  
  {
    type: 5,
    part: 1,
    name: "Декоративный кирпич",
    price: 4200,
    preview: "images/textures/bricks-2.jpg",
    materials: [ 

      {
        id: 7,
        name: "Декор. кирпич коричневый",
        texture: 'images/textures/bricks-1.jpg',
        size: [1,1,1],
        shininess: SHININESS.BRICKS
      },
      {
        id: 8,
        name: "Декор. кирпич белый",
        texture: 'images/textures/bricks-2.jpg',
        size: [1,1,1],
        shininess: SHININESS.BRICKS
      },

    ]

  },

  // Пол --------------------------------------------------------------

  {
    type: 6,
    part: 2,
    name: "Линолеум",
    price: 2100,
    preview: "images/textures/wood-3.jpg",
    materials: [
      //TODO
      {
        id: 9,
        name: "Древесина тёмная",
        texture: 'images/textures/wood-3.jpg',
        size: [defaultSize.x,defaultSize.y,defaultSize.z],
        shininess: SHININESS.WOOD
      },

    ]

  },

  {
    type: 7,
    part: 2,
    name: "Ламинат",
    price: 2100,
    preview: "images/textures/laminate-2.jpg",
    materials: [
      
      {
        id: 10,
        name: "Ламинат коричневый",
        texture: 'images/textures/laminate-2.jpg',
        size: [2,2,2],
        shininess: SHININESS.WOOD
      },

      {
        id: 11,
        name: "Ламинат холодный",
        texture: 'images/textures/laminate-3.jpg',
        size: [2,2,2],
        shininess: SHININESS.WOOD
      },

    ]

  },

  {
    type: 8,
    part: 2,
    name: "Плитка",
    price: 4340,
    preview: "images/textures/laminate-2.jpg",
    materials: [
      //TODO
      {
        id: 12,
        name: "Плитка коричневая",
        texture: 'images/textures/laminate-2.jpg',
        size: [2,2,2],
        shininess: SHININESS.WOOD
      },

    ]

  },

  {
    type: 9,
    part: 2,
    name: "Кварц-винил",
    price: 4280,
    preview: "images/textures/laminate-2.jpg",
    materials: [
      //TODO
      {
        id: 13,
        name: "Кварц-винил коричневый",
        texture: 'images/textures/laminate-2.jpg',
        size: [2,2,2],
        shininess: SHININESS.WOOD
      },

    ]

  },


  // [
    
  //   {
  //     type: "", // material_types.id
  //     part: "", // material_types.balkon_part
  //     name: "", // material_types.name
  //     price: "", // pricelist.price где pricelist.target = material_types.id и pricelist.type = 1
  //     preview: "", // material_types.preview картинка-превью категории материалов
  //     materials: [] // из materials все строки где materials.type = material_types.id

  //   },

  //   // ... остальные категории в таком же виде ... /

  // ]
  
  
  
  
  
];

async function get( store ) {

  try {

    // const data = {

    //   balkons: [

    //     {

    //       id: 1,
    //       name: "Балкон длинный (новостройка)",
    //       image: "/images/balkon_images/1.png",
    //       model: "models/balkon_v3.glb",
    //       position: [ 0, -1.7, -1.2 ]

    //     }

    //     // [

    //     //   {

    //     //     id: "", // balkon_types.id
    //     //     name: "", // balkon_types.name
    //     //     image: "", // balkon_types.image
    //     //     model: "", // balkon_types.model
    //     //     position: "", // balkon_types.position
    //     //     partNames: "", // balkon_types.partnames

    //     //   }

    //     //   // ... остальные типы балконов в таком же виде ... /

    //     // ]

    //   ],

    //   materials: ALL_MATERIALS

    // };

    const token = cookie.load(`token`);
    const materials = await post( API.materials.all, {}, { headers: { token } } );
    const environments = await post( API.environments.all, {}, { headers: { token } } );
    const presets = await post( API.projects.presets, {}, { headers: { token } } );

    store.setState({
      
      GLOBAL: {

        BALKONS: presets.data.projects,
        MATERIALS: materials.data.material_types,
        ENVIRONMENTS: environments.data.environments,
        ENVIRONMENTS_GROUPS: environments.data.environmentsGroups,

      }

    });

    return;

  } catch ( err ) {

    console.error( err );
    return;

  }

}

export {

  get

}