import React, { useState } from 'react';
import { ReactComponent as ProjectsIcon } from './icons/projects.svg';
import { ReactComponent as UsersIcon } from './icons/users.svg';
import { ReactComponent as WindowsIcon } from './icons/windows.svg';
import './header.scss';
import useGlobal from '../../../store';
import { useNavigate } from 'react-router-dom';

const Header = () => {

  const [ isMenuOpened, setIsMenuOpened ] = useState( false );
  
  return (

    <header className = "header flex justify-between items-center">

      <div className="header__logo">РадиВас</div>

      { window.location.pathname !== "/signin" && 
      
        <div

          className="header__burger" 
          onClick = { () => setIsMenuOpened( !isMenuOpened ) } 

        /> 
        
      }

      { isMenuOpened && <HeaderMenu /> }

    </header>

  );

}

const HeaderMenu = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { user_role } = globalState;
  
  const navigate = useNavigate();

  const MENU = [
    {
      icon: <ProjectsIcon />,
      path: "/",
      text: "Балконы и статистика"
    }
       
  ]

  if ( user_role !== 3 ) {

    MENU.push({

      icon: <UsersIcon />,
      path: "/team",
      text: "Мои сотрудники"

    });

  }

  MENU.push({

    icon: <WindowsIcon />,
    path: "/windows_partners",
    text: "Мои поставщики окон"
    
  });

  return (

    <div className = "header__menu">

      { MENU.map(({ icon, path, text }, key) => (

        <div 
          key = { key }
          className = "header__menu__row flex items-center"
          onClick = { () => navigate( path ) }
        >

          { icon }
          { text }

        </div>

      ))}      

    </div>

  );

}

export default Header;