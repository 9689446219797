import React, { useState, useEffect } from "react";
import useGlobal from "../../../store";
import Selectfield from "../selectfield";
import ToggleSlider from "../toggleslider";
import "./windows.settings.scss";

import { 

  windows_profile_types,
  windows_profile_color_types,
  windows_insulation_types

} from "../../../settings/constants";
import Checkbox from "../checkbox";
import Textfield from "../textfield";
import InputLowtide from "../input.lowtide";
import InputVisor from "../input.visor";

const WindowsSettings = ({ readOnly = false }) => {

  const [ globalState, globalActions ] = useGlobal();

  const { 
    
    windows_insulation,
    windows_profile,
    windows_count_cameras,
    windows_glazing,
    windows_profile_color,
    windows_mosquitos_count, 
    windows_visor, 
    windows_lowtide, 
    windows_nachelnik,
    windows_comment 
  
  } = globalState;

  const { changeStates, changeObjectState } = globalActions;

  return (

    <div className="windows_settings">

      <Selectfield

        title = "Тип окон:"
        readOnly = { readOnly }
        list = { windows_insulation_types }    
        selected = { windows_insulation_types.find( el => el.id === windows_insulation ) }
        select = { ({ id }) => { 

          if ( !readOnly ) {
            
            changeStates({ windows_insulation: id });
            setTimeout(() => { globalActions.smeta.calculateSmeta(); }, 150)
          
          }

        }}    

      />

      <Selectfield

        title = "Оконный профиль:"
        readOnly = { readOnly }
        selected = { windows_profile_types.find( el => el.id === windows_profile ) }
        select = { ({ id }) => { !readOnly && changeStates({ windows_profile: id }) }}
        list = { windows_profile_types }        

      />

      { windows_profile ? 
      
        <React.Fragment>

          <ToggleSlider

            title = "Количество камер:"
            selected = { +windows_count_cameras }
            select = { ( value ) => { !readOnly && changeStates({ windows_count_cameras: value }) }}
            list = {[ 3, 5 ]}
            style = {{ maxWidth: "50%" }}

          />

          <ToggleSlider

            title = "Стеклопакет:"
            selected = { +windows_glazing }
            select = { ( value ) => { !readOnly && changeStates({ windows_glazing: value }) }}
            list = {[ 1, 2, 3 ]}
            style = {{ maxWidth: "80%" }}

          />


          <ToggleSlider

            title = "Цвет профиля:"
            selected = { windows_profile_color?.type }
            select = { ( value ) => { !readOnly && changeObjectState( "windows_profile_color", "type", value ) }}
            list = { windows_profile_color_types }

          />

          { windows_profile_color?.type === 1 && 

            <React.Fragment>

              <div className="checkboxes flex inputs justify-between">

                <Checkbox

                  text = "Внутренняя"
                  value = { windows_profile_color?.inner }
                  set = { ( value ) => { !readOnly && changeObjectState( "windows_profile_color", "inner", value ) }}
                
                />

                <Checkbox

                  text = "Наружняя"
                  value = { windows_profile_color?.outer }
                  set = { ( value ) => { !readOnly && changeObjectState( "windows_profile_color", "outer", value ) }}
                
                />

              </div>
              
              {( !readOnly || ( windows_profile_color?.colorName && windows_profile_color?.colorName !== "" ) ) && 
              
                <Textfield

                  title = "Цвет ламинации:"
                  readOnly = { readOnly }
                  value = { windows_profile_color?.colorName || "" }
                  set = {  ( value ) => { changeObjectState( "windows_profile_color", "colorName", value ) } }
                  placeholder = "Напишите название цвета"

                />
                
              }

            </React.Fragment>

          }

        </React.Fragment>

        : ""
        
      }
          
      <Textfield

        type = "number"
        readOnly = { readOnly }
        title = "Количество москитных сеток:"
        value = { windows_mosquitos_count }
        set = { ( value ) => { changeStates({ windows_mosquitos_count: value }) }}
        placeholder = "0"

      />

      <div className="balkon_settings__title text-center">Козырёк:</div>

      <InputVisor
      
        amountFirst  = { windows_visor.s1 }
        amountSecond = { windows_visor.s2 }
        amountThird  = { windows_visor.s3 }
        amountFourth = { windows_visor.s4 }
        amountFactor = { windows_visor.factor }

        setAmountFirst  = { ( value ) => { !readOnly && changeObjectState( "windows_visor", "s1",      value ) }}
        setAmountSecond = { ( value ) => { !readOnly && changeObjectState( "windows_visor", "s2",      value ) }}
        setAmountThird  = { ( value ) => { !readOnly && changeObjectState( "windows_visor", "s3",      value ) }}
        setAmountFourth = { ( value ) => { !readOnly && changeObjectState( "windows_visor", "s4",      value ) }}
        setAmountFactor = { ( value ) => { !readOnly && changeObjectState( "windows_visor", "factor",  value ) }}
        setSummary      = { ( value ) => { !readOnly && changeObjectState( "windows_visor", "summary", value ) }}

      />

      <div className="balkon_settings__title text-center">Начельник (металлический):</div>

      <div className = "flex inputs items-end">
        
        <Textfield

          readOnly = { readOnly }          
          number
          title = "Длина"
          value = { windows_nachelnik.length }
          set = { ( value ) => { changeObjectState( "windows_nachelnik", "length", value ) }}

        />
      
        <Textfield

          readOnly = { readOnly }
          number
          title = "Ширина"
          value = { windows_nachelnik.width }
          set = { ( value ) => { changeObjectState( "windows_nachelnik", "width", value ) }}

        />
      
        <Textfield

          readOnly = { readOnly }
          number
          title = {`Кол-во`}
          value = { windows_nachelnik.amount }
          set = { ( value ) => { changeObjectState( "windows_nachelnik", "amount", value ) }}

        />
            
      </div>

      <div className="balkon_settings__title text-center">Отлив:</div>

      <InputLowtide
              
        amountFirst  = { windows_lowtide.s1 }
        amountSecond = { windows_lowtide.s2 }
        amountThird  = { windows_lowtide.s3 }
        amountFactor = { windows_lowtide.factor }

        setAmountFirst  = { ( value ) => { !readOnly && changeObjectState( "windows_lowtide", "s1",      value ) }}
        setAmountSecond = { ( value ) => { !readOnly && changeObjectState( "windows_lowtide", "s2",      value ) }}
        setAmountThird  = { ( value ) => { !readOnly && changeObjectState( "windows_lowtide", "s3",      value ) }}
        setAmountFactor = { ( value ) => { !readOnly && changeObjectState( "windows_lowtide", "factor",  value ) }}
        setSummary      = { ( value ) => { !readOnly && changeObjectState( "windows_lowtide", "summary", value ) }}
      
      />
          
      <Textfield

        multiline
        readOnly = { readOnly }
        title = "Комментарий:"
        value = { windows_comment }
        set = { ( value ) => { changeStates({ windows_comment: value }) }}
        placeholder = ""

      />

    </div>

  );

}

export default WindowsSettings;