// import React from 'react';
// import cssIf from '../../../scripts/helpers/class.add.if';
// import "./toggleslider.scss";

// export const Selector_row = ({ values, selected, set }) => {

//   console.log('Row_selector', values.length, values);

//   return (

//     <div className={`toggleslider els${values.length} active_${ selected }`}>

//       <div className="list">

//         { values.length > 1 && values.map(( value, key ) => ( 

//           <div onClick={ () => set( key ) } className={ `${ cssIf( +selected === key, 'selected' ) } value` }>

//             { value }
                  
//           </div> 

//         ))}

//       </div>

//       <div className="toggleslider__bg_selected" />

//     </div>

//   );

// }

import React, { useState, useEffect, useRef } from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import "./toggleslider.scss";

const ToggleSlider = ( props ) => {

  const {

    selected = 0, 
    select, 
    list = [], 
    title = "",
    style = {}

  } = props;

  const randomID = Math.random( 1, 99 ).toFixed( 0 );

  const elementWidth = 100 / list.length;
  const [ anchorOffset, setAnchorOffset ] = useState( 0 );

  useEffect(() => { setAnchorOffset( ( 100 / list.length ) * selected ); }, [ selected ]);
  
  return (

    <div className="toggleslider" style = { style }>

      { title?.length > 0 && <div className="toggleslider__title">{ title }</div> }

      <div className="toggleslider__container relative">

        <div className="toggleslider__list absolute flex">

          { list.length > 0 && list.map(( el, key ) => (

            <div
              onClick = { () => { select( key ) } }
              style = {{ width: `${ elementWidth }%` }}
              className={`toggleslider__list__element toggleslider__list__element_${ randomID }_${ key } ${ cssIf( selected === key, `toggleslider__list__element--selected` ) } flex justify-center items-center`} key = { key }
            >
              { el }
            </div>

          ))}

        </div>

        <div

          className="toggleslider__anchor absolute"
          style = {{

            left: `${ anchorOffset }%`,
            width: `${ elementWidth }%`

          }}

        />
        
      </div>
     
    </div>

  );

}

export default ToggleSlider;