
import cssIf from '../../../scripts/helpers/class.add.if';
import './steps.scss';

const Steps = ({ title, list, step }) => (

  <div className="steps flex justify-between items-center">

    { title && <div className="steps__title">{ title }</div> }

    <div className="steps__map flex">
      
      { list.length > 0 && list.map(( el, key ) => (

        <div className = {`steps__element flex justify-center items-center ${ cssIf( ( key + 1 ) <= step, 'steps__element--active' )}`}>
                    
          { key + 1 }

        </div>

      ))}
      
    </div>
    
  </div>

);

export default Steps;