import React, { useState } from 'react';
import Container from '../../components/container';
import Steps from '../../components/steps';
import BalkonSelect from './balkon.select';
import BalkonCustomer from './balkon.customer';
import BalkonWindows from './balkon.windows';
import './balkon.form.scss';

export const BalkonForm = ({ children, step }) => {

  return (

    <Container className = {`balkonform balkonform--${ step } flex-col flex-1`}>

      <Steps

        title = "Новый балкон"
        list = {[ 1, 2, 3 ]}
        step = { step }

      />

      <hr />

      { children }

    </Container>

  );

}


export {
  BalkonSelect,
  BalkonCustomer,
  BalkonWindows
}