import React, { useState, useEffect } from "react";
import useGlobal from "../../../store";
import Button from "../button";
import Selectfield from "../selectfield";
import ToggleSlider from "../toggleslider";

const SmetaTabletop = ({ close }) => {

  const SIZES = [

    // Деревянная
    [
      { id: "800*1500", name: "800*1500" },
      { id: "600*1000", name: "600*1000" },
      { id: "500*1200", name: "500*1200" },
      { id: "600*1500", name: "600*1500" },
      { id: "300*2000", name: "300*2000" }
    ],

    // ЛДСП
    [{ id: "600*1500", name: "600*1500" }]

  ];
  
  const [ globalState, globalActions ] = useGlobal();
  const { tableTop } = globalState;
  const { environment } = globalActions;

  const [ type, setType ] = useState( tableTop.added ? tableTop.type : 0 );
  const [ size, setSize ] = useState( !tableTop.added ? SIZES[ type ][ 0 ] : SIZES[ type ].find( s => s.id === tableTop.size ) );


  function handleAddTabletop() {
    
    environment.addTabletop({ type, size: size.id });
    close();

  }

  useEffect(() => { setSize( SIZES[ type ][ 0 ] ); }, [ type ]);

  useEffect(() => { 

    const _size = SIZES[ type ].find( s => s.id === tableTop.size );
    setSize( _size );

  }, [ tableTop.type ]);
  
  return (

    <div className="smeta__isolation p-14" draggable="false">

      <ToggleSlider

        title = "Материал столешницы:"
        list = {[ "Дерево", "ЛДСП" ]}
        selected = { type }
        select = { setType }

      />

      <Selectfield

        title = "Размер:"
        selected = { size }
        select = { setSize }
        list = { SIZES[ type ] }

      />

      <Button

        text = { !tableTop.added ? "Добавить" : "Сохранить" }
        action = { handleAddTabletop }

      />

    </div>

  );

}

export default SmetaTabletop;