import React, { useState, useEffect } from "react";
import { clother_dryer_types } from "../../../settings/constants";
import useGlobal from "../../../store";
import Button from "../button";
import Selectfield from "../selectfield";
import ToggleSlider from "../toggleslider";

const SmetaClothesDryer = ({ close }) => {
  
  const [ globalState, globalActions ] = useGlobal();
  const { GLOBAL, clothesDryer } = globalState;
  const { environment } = globalActions;

  const [ SIZES, setSIZES ] = useState([]);

  useEffect(() => {

    const clothesDryer = GLOBAL.ENVIRONMENTS.find( el => el.path === 'clothesDryer');
    // console.log(`typeof GLOBAL.ENVIRONMENTS.clothesDryer.price ${typeof GLOBAL.ENVIRONMENTS.clothesDryer}`, GLOBAL.ENVIRONMENTS);

    if ( !clothesDryer?.price || typeof clothesDryer.price !== "object" ) { return; }
    
    const sizesArr = [];
    for ( let s in clothesDryer.price ) { sizesArr.push( s ); }
    setSIZES( sizesArr );

  }, [ GLOBAL.ENVIRONMENTS ])

  const [ type, setType ] = useState( clothesDryer.added ? clothesDryer.type : 0 );
  const [ size, setSize ] = useState( !clothesDryer.added ? SIZES[ 0 ] : SIZES.find( s => s.id === clothesDryer.size ) );


  function handleAddClothesDryer() {

    // console.log(`handleAddClothesDryer`,{ type, size: size })
    
    environment.addClothesDryer({ type, size: size });
    close();

  }

  useEffect(() => { 

    let _size;

    if ( clothesDryer.size ) {

      _size = SIZES[SIZES.indexOf( clothesDryer.size )];

    }

    setSize( _size );

  }, [ clothesDryer.size, SIZES.length ]);
  
  return (

    <div className="smeta__isolation p-14" draggable="false">

      <ToggleSlider

        title = "Вид сушилки:"
        list = { clother_dryer_types }
        selected = { type }
        select = { setType }

      />

      <br/>

      <Selectfield

        title = "Размер:"
        selected = { size }
        select = { setSize }
        list = { SIZES }

      />

      <Button

        text = { !clothesDryer.added ? "Добавить" : "Сохранить" }
        action = { handleAddClothesDryer }

      />

    </div>

  );

}

export default SmetaClothesDryer;