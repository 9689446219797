import numberSpaces from '../../../scripts/helpers/numbers.with.spaces';
import './balkon.row.scss';

const BalkonRow = ({ status, date, price = false, client = false, address, image = "", action = false }) => (

  <div className = "balkon_row" onClick = { () => action && action() }>

    <div className = "balkon_row__photo" style={{ backgroundImage: `url( ${image} )`}} />

    <div className = "balkon_row__content">

      <div className = "balkon_row__date">{ date }{ client ? ` - ${ client }` : "" }</div>
      {( price && price !== "" ) && <div className = "balkon_row__value">{ numberSpaces( +parseFloat( price ).toFixed( 2 ) ) }₽</div> }
      <div className = "balkon_row__adress">{ address }</div>

    </div>

    <div className={`status status--${ status === 0 ? "wait" : "deal" }`}>

      <div className = "meaning">{ status === 0 ? "ожидание" : "договор" }</div>
      <div className = "circle"><div className = "dot" /></div>

    </div>

  </div>

);

export default BalkonRow;