import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import "./picker.target.scss";

const TargetPicker = ({ targets, selected, set, opened }) => {
    
  return (

    <div className = {`picker_target ${ cssIf( opened, 'picker_target--opened' ) }`}>

      { targets.map(( el, key ) => (

        <div

          key = { key }
          title = { el.tip }
          onClick = {() => { set( el.target ); console.log(`set(${el.target}); selected = ${selected}`); }}
          style = {{ backgroundImage: `url(${ el.icon })`}}
          className = {`picker_target__option ${ selected === el.target ? "picker_target__option--is-active" : ""}`}

        >

          <div className="picker_target__option__name">{ el.tip }</div>

        </div>

      ))}

    </div>

  );

}

export default TargetPicker;