import { BALKON } from "./constants";

const lightBalkonVitrage = {

  ambient: { intensity: 0.20 },
  
  target: {

    x: -2.40,
    y: -0.15,
    z: -0.20

  },

  directional: {

    x: -87.73,
    y: 23.17,
    z: 150.00,
    intensity: 0.66

  },

  spot: {
    
    x: 1.60,
    y: 6.86,
    z: 11.01,
    intensity: 0.35,
    angle: 0.8,
    decay: 1

  }

}

const lightBalkon6M = {

  ambient: { intensity: 0.23 },
  
  target: {

    x: -0.05,
    y: -1.35,
    z: -1.15

  },

  directional: {

    x: 46.19,
    y: 6.33,
    z: -22.45,
    intensity: 0.48

  },

  spot: {
    
    x: 27.10,
    y: 15.91,
    z: 15.26,
    intensity: 0.30,
    angle: 0.8,
    decay: 1
    
  }

}

const lightBalkon3M = {

  ambient: { intensity: 0.23 },
  
  target: {

    x: 4.69,
    y: 7.06,
    z: -1.15

  },

  directional: {

    x: 2.67,
    y: 7.73,
    z: -19.65,
    intensity: 0.52

  },

  spot: {
    
    x: -10.80,
    y: 34.86,
    z: -13.79,
    intensity: 0.33,
    angle: 0.8,
    decay: 1
    
  }

}

const lightBalkonDovetail = {

  ambient: { intensity: 0.23 },
  
  target: {

    x: -0.92,
    y: 7.06,
    z: -6.76

  },

  directional: {

    x: 11.54,
    y: 6.93,
    z: -19.65,
    intensity: 0.52

  },

  spot: {
    
    x: 50.20,
    y: -30.54,
    z: -24.99,
    intensity: 0.19,
    angle: 0.8,
    decay: 1
    
  }

}

const lightSettings = {

  [ BALKON.DOVETAIL ]: lightBalkonDovetail,
  [ BALKON.VITRAGE ]: lightBalkonVitrage,
  [ BALKON.VITRAGE_SPLIT ]: lightBalkonVitrage,
  [ BALKON.THREEMETR ]: lightBalkon3M,
  [ BALKON.HRUSHEVKA ]: lightBalkon3M,
  [ BALKON.SIXMETR ]: lightBalkon6M,
  [ BALKON.SIXMETR_STANDART ]: lightBalkon6M,

}

export default lightSettings;