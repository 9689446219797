import { CONSTRUCTOR, HOST } from "./host";

const PROJECTS = `${ HOST }/projects`;
const MATERIALS = `${ HOST }/materials`;
const ENVIRONMENTS = `${ HOST }/environments`;
const EMPLOYEES = `${ HOST }/companies/employees`;
const WINDOWS = `${ HOST }/windows`;
const WINDOWS_PARTNERS = `${ HOST }/windows/partners`;

const API = {

  CONSTRUCTOR_URL: CONSTRUCTOR,

  sign: {

    up: `${ HOST }/sign/up`,
    in: `${ HOST }/sign/in`,
    check: `${ HOST }/sign/check`,

  },

  projects: {

    all: `${ PROJECTS }/all`,
    get: `${ PROJECTS }/get`,
    new: `${ PROJECTS }/new`,
    save: `${ PROJECTS }/save`,
    archive: `${ PROJECTS }/archive`,
    restore: `${ PROJECTS }/restore`,
    remove: `${ PROJECTS }/remove`,
    presets: `${ PROJECTS }/presets`,

  },

  materials: {

    all: `${ MATERIALS }/all`

  },

  environments: {

    all: `${ ENVIRONMENTS }/all`

  },

  employees: {

    all: `${ EMPLOYEES }/all`,
    get: `${ EMPLOYEES }/get`,
    add: `${ EMPLOYEES }/add`,
    edit: `${ EMPLOYEES }/edit`,
    remove: `${ EMPLOYEES }/remove`,

  },

  windows: {

    get: `${ WINDOWS }/get`,

  },

  windowsPartners: {

    all: `${ WINDOWS_PARTNERS }/all`,
    get: `${ WINDOWS_PARTNERS }/get`,
    add: `${ WINDOWS_PARTNERS }/add`,
    edit: `${ WINDOWS_PARTNERS }/edit`,
    remove: `${ WINDOWS_PARTNERS }/remove`,

  },

}

export default API;