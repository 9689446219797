import { texturesPath } from "../../settings/constants";

async function addEnvironmentToSmeta( store, environmentState, environmentSettings = 1 ) {

  try {

    store.setState({ 
      
      [ environmentState ]: environmentSettings,
      smetaPage: "smeta"
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.addEnvironmentToSmeta`, environmentState );
    return false;

  }

}

async function removeEnvironmentFromSmeta( store, environmentState, environmentZeroSettings = 0, askConfirmation = true ) {

  try {

    if ( askConfirmation ) { if ( !window.confirm(`Удалить?`) ) { return; } }

    store.setState({ 
      
      [ environmentState ]: environmentZeroSettings,
      smetaPage: "smeta"
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.removeEnvironmentFromSmeta`, environmentState );
    return false;

  }

}

async function addCupboard( store, object ) {

  try {

    const { balkon_type } = store.state;

    const { 

      side, 
      variant, 
      doors, 
      position, 
      rotation,
      height,
      width,
      depth
    
    } = object;

    // console.log(`model_path:`, `models/cupboards/cupboard_${ balkon_type }_${ doors }_${ variant }.glb`);

    let newState = { 
      
      selectedModelRef: `cupboard_${ side }_${ variant }`,
      selectedModelPart: `cupboard`,

      [`cupboard_${ side }_${ variant }_added`]: true,
      [`cupboard_${ side }_${ variant }_type`]: variant,
      [`cupboard_${ side }_${ variant }_doors`]: doors,
      [`cupboard_${ side }_${ variant }_position`]: position,
      [`cupboard_${ side }_${ variant }_rotation`]: rotation,
      [`cupboard_${ side }_${ variant }_size`]: {

        width,
        height,
        depth
        
      }
    
    }

    if ( !store.state?.[`cupboard_${ side }_${ variant }_materials`]?.id ) {

      newState[`cupboard_${ side }_${ variant }_materials`] = {
        
        name: "Выберите материал",
        shininess: 1,
        size: [ 2, 2, 2 ],
        preview: `${ texturesPath }/nothing.jpg`,
        texture: `${ texturesPath }/nothing.jpg`

      };

      newState[`cupboard_${ side }_${ variant }_texture_image`] = `${ texturesPath }/nothing.jpg`;
      newState[`cupboard_${ side }_${ variant }_texture_name`] = "Выберите материал";

    }

    store.setState( newState );

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.add`, err );
    return false;

  }

}

async function openCupboardSettings( store, side_variant ) {

  try {

    store.setState({ 
      
      smetaIsOpened: true,
      smetaBackIsClose: true,
      smetaPage: "cupboard",
      smetaPageMode: "edit",
      smetaSubpage: side_variant
    
    });

  } catch ( err ) {

    console.error(`openCupboardSettings`, err );
    return false;

  }

}

async function removeCupboard( store, cupboard ) {

  if ( window.confirm("Вы точно хотите убрать шкаф?") ) {

    // console.log(`removeCupboard ${cupboard}`);

    store.setState({

      [`${ cupboard }_added`]: false,
      [`${ cupboard }_type`]: "full",
      [`${ cupboard }_doors`]: 0,
      [`${ cupboard }_position`]: "",
      [`${ cupboard }_rotation`]: "",
      [`${ cupboard }_texture_image`]: "",
      [`${ cupboard }_texture_name`]: "",
      [`${ cupboard }_materials`]: {},
      [`${ cupboard }_size`]: {

        length: "",
        width: "",
        depth: ""

      },

    })

    store.actions.smeta.calculateSmeta();

  }

}

async function addLar( store, object ) {

  try {

    // console.log(`addLar`, object);

    const { 

      side,
      position, 
      rotation,
      height,
      width,
      depth
    
    } = object;

    let newState = { 
      
      selectedModelRef: `lar_${ side }`,
      selectedModelPart: `lar`,

      [`lar_${ side }_added`]: true,
      [`lar_${ side }_position`]: position,
      [`lar_${ side }_rotation`]: rotation,
      [`lar_${ side }_size`]: {

        width,
        height,
        depth
        
      }
    
    }

    if ( !store.state?.[`lar_${ side }_materials`]?.id ) {

      newState[`lar_${ side }_materials`] = {
        
        name: "Выберите материал",
        shininess: 1,
        size: [ 2, 2, 2 ],
        preview: `${ texturesPath }/nothing.jpg`,
        texture: `${ texturesPath }/nothing.jpg`

      };

      newState[`lar_${ side }_texture_image`] = `${ texturesPath }/nothing.jpg`;
      newState[`lar_${ side }_texture_name`] = "Выберите материал";

    }

    store.setState( newState );

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.add`, err );
    return false;

  }

}

async function openLarSettings( store, side ) {

  try {

    store.setState({ 
      
      smetaIsOpened: true,
      smetaBackIsClose: true,
      smetaPage: "lar",
      smetaPageMode: "edit",
      smetaSubpage: side
    
    });

  } catch ( err ) {

    console.error(`openCupboardSettings`, err );
    return false;

  }

}

async function removeLar( store, lar ) {

  if ( window.confirm("Вы точно хотите убрать ларь?") ) {

    // console.log(`removeLar ${lar}`);

    store.setState({

      [`${ lar }_added`]: false,
      [`${ lar }_position`]: "",
      [`${ lar }_doors`]: 0,
      [`${ lar }_rotation`]: "",
      [`${ lar }_texture_image`]: "",
      [`${ lar }_texture_name`]: "",
      [`${ lar }_materials`]: {},
      [`${ lar }_size`]: {

        length: "",
        width: "",
        depth: ""

      },

    })

    store.actions.smeta.calculateSmeta();

  }

}

async function addSocket( store ) {

  try {

    const { sockets } = store.state;

    //? TODO if sockets === 0 and распределительная коробка = false add распределительная коробка

    store.setState({ 
      
      sockets: sockets + 1,
      smetaPage: "smeta"
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.addSocket`, err );
    return false;

  }

}

async function removeSocket( store, { all = false } ) {

  try {

    const { sockets } = store.state;

    store.setState({ 
      
      sockets: all ? 0 : sockets - 1,
      smetaPage: "smeta"
    
    });

    //? TODO if sockets === 0 and распределительная коробка = true remove распределительная коробка

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.addSocket`, err );
    return false;

  }

}

async function addLamp( store ) {

  try {

    const { lamps } = store.state;

    store.setState({ 
      
      lamps: lamps + 1,
      lampSwitch: 1,
      smetaPage: "smeta"
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.addLight`, err );
    return false;

  }

}

async function removeLamp( store, { all = false } ) {

  try {

    const { lamps } = store.state;

    store.setState({ 
      
      lamps: all ? 0 : lamps - 1,      
      lampSwitch: ( all || lamps - 1 === 0 ) ? 0 : 1,
      smetaPage: "smeta"
    
    });

    //? TODO if lamps === 0 and распределительная коробка = true remove распределительная коробка

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.addSocket`, err );
    return false;

  }

}

async function addInsulation( store, object ) {

  try {

    const { 

      type,
      border,
      edges,
      ceiling,
      floor,
      electricFloor
    
    } = object;

    store.setState({ 
      
      insulation: {

        added: true,
        type,
        border,
        edges,
        ceiling,
        floor,
        electricFloor

      },
      smetaPage: "smeta"
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`insulation.add`, err );
    return false;

  }

}

async function removeInsulation( store ) {

  try {

    if ( window.confirm("Вы точно хотите убрать утепление?") ) {

      store.setState({ 
        
        insulation: {

          added: false, // добавлено ли
          type: 0, // тип утепления (бюджетный/термос)
          border: false, // бордюр
          edges: false, // края
          ceiling: false, // потолок
          floor: false, // пол
          electricFloor: false // электро-тёплый пол

        },
        smetaPage: "smeta"
      
      });

      store.actions.smeta.calculateSmeta();

    }

  } catch ( err ) {

    console.error(`insulation.remove`, err );
    return false;

  }

}

async function addRemovalWithWelding( store, object ) {

  try {

    const { 

      forward,
      left,
      right
    
    } = object;

    let added = true;

    if ( !forward && !left && !right ) {

      added = false;

    }

    store.setState({ 
      
      removalWithWelding: {

        added: added,
        forward,
        left,
        right

      },
      smetaPage: "smeta"
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`removalWithWelding.add`, err );
    return false;

  }

}

async function addTabletop( store, { type, size } ) {

  try {

    store.setState({ 
      
      smetaPage: "smeta",
      tableTop: {
        added: true,
        type: type,
        size: size
      }
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.addTabletop`, err );
    return false;

  }

}

async function removeTabletop( store ) {

  try {

    if ( !window.confirm("Вы точно хотите убрать столешницу?") ) { return; }

    store.setState({ 
      
      smetaPage: "smeta",
      tableTop: {
        added: false,
        type: 0,
        size: {}
      }
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.removeTabletop`, err );
    return false;

  }

}

async function addClothesDryer( store, { type, size } ) {

  try {

    store.setState({ 
      
      smetaPage: "smeta",
      clothesDryer: {
        added: true,
        type: type,
        size: size
      }
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.addClothesDryer`, err );
    return false;

  }

}

async function removeClothesDryer( store ) {

  try {

    if ( !window.confirm("Вы точно хотите убрать сушилку для белья?") ) { return; }

    store.setState({ 
      
      smetaPage: "smeta",
      clothesDryer: {
        added: false,
        type: 0,
        size: ""
      }
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.removeClothesDryer`, err );
    return false;

  }

}

async function addStretchCeiling( store ) {

  try {

    let balkon_materials = store.state.balkon_materials;

    if ( balkon_materials?.ceiling?.texture ) {

      balkon_materials.ceiling.texture = `${ texturesPath }/white.jpg`;

    }    

    store.setState({ 
      
      smetaPage: "smeta",
      stretchCeiling: 1,
      balkon_materials,
      selectedModelRef: "balkon",
      selectedModelPart: "ceiling"
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.addClothesDryer`, err );
    return false;

  }

}

async function removeStretchCeiling( store ) {

  try {

    if ( !window.confirm("Вы точно хотите убрать натяжной потолок?") ) { return; }

    let balkon_materials = store.state.balkon_materials;

    if ( balkon_materials?.ceiling?.texture && balkon_materials?.ceiling?.preview ) {

      balkon_materials.ceiling.texture = balkon_materials.ceiling.preview;
      
    }

    store.setState({ 
      
      smetaPage: "smeta",
      stretchCeiling: 0,
      balkon_materials,
      selectedModelRef: "balkon",
      selectedModelPart: "ceiling"
    
    });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {

    console.error(`environment.removeClothesDryer`, err );
    return false;

  }

}

export {

  addEnvironmentToSmeta,
  removeEnvironmentFromSmeta,
  
  addLamp,
  removeLamp,

  addSocket,
  removeSocket,

  addCupboard,
  openCupboardSettings,
  removeCupboard,

  addLar,
  openLarSettings,
  removeLar,

  addInsulation,
  removeInsulation,

  addRemovalWithWelding,  

  addTabletop,
  removeTabletop,

  addClothesDryer,
  removeClothesDryer,

  addStretchCeiling,
  removeStretchCeiling,
  
}