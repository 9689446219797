import * as global from './global';
import * as sign from './sign';
import * as constructor from './constructor';
import * as environment from './environment';
import * as balkon from './balkon';
import * as smeta from './smeta';
import * as employees from './employees';
import * as windows from './windows';
import * as windowsPartners from './windows.partners';
// import * as popup from './popups';

async function changeStates( store, states, reload = false ) {

  store.setState( states );
  reload && reload();

}

async function changeObjectState( store, object, state, value, reload = false ) {

  store.setState({

    [ object ]: {

      ...store.state[ object ],
      [state]: value

    }

  });

  reload && reload();

}

export {

  changeStates,
  changeObjectState,
  global,
  sign,
  balkon,
  constructor,
  environment,
  smeta,
  employees,
  windows,
  windowsPartners,
  
}