import { post } from 'axios';
import API from '../../settings/api'
import cookie from 'react-cookies';

export async function Up( store, mail, pass, repeat, showError, redirect ) {

  try {

    const { status, data } = await post( API.sign.up, { email: mail, password: pass, repeat: repeat } );

    console.log(`signUn data:`, data);

    if ( status === 200 ) {

      if ( data.success ) {

        cookie.save('token', data.token, { path: '/' });
        redirect();
        
      } else {

        throw( data.text );

      }

    } else {

      throw('Нет соединения с сервером');

    }

  } catch ( err ) {

    console.error( err );
    showError( err );

  }
  
}

export async function In( store, mail, pass, redirect, showError ) {

  try {

    const { status, data } = await post( API.sign.in, { email: mail, password: pass } );

    console.log(`signIn data:`, data);

    if ( status === 200 ) {

      if ( data.success ) {

        cookie.save('token', data.token, { path: '/' });
        redirect();
        
      } else {

        throw( data.text );

      }

    } else {

      throw('Нет соединения с сервером');

    }

  } catch ( err ) {

    console.error( err );
    showError( err );

  }
  
}

export async function Check( store, token, redirect = false ) {

  try {

    const { status, data } = await post( API.sign.check, { token: token } );

    console.log(`check data: ${status}`, data);

    if ( status === 200 && data.success ) {

      console.log(`data success data id`);
      store.setState({ 
        
        user_id: data.user.id,
        user_name: data.user.name,
        user_role: data.user.role,
        user_company: data.user.company
      
      });

      redirect && redirect();
      return true;       
      
    } else {

      throw('');

    }

  } catch ( err ) {

    if ( !redirect ) {

      alert(`В доступе отказано!`);
      console.error(`Неверный токен`);
      store.actions.sign.Out();

    }
    
    return false;    

  }
  
}

export async function Out() {

  try {

    cookie.remove(`token`);
    window.location = "/signin";

  } catch(err) {

  }

}