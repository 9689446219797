import React, { useState, useEffect, useRef } from 'react';
import './input-visor.scss';


const InputVisor = ( props ) => {

  const {

    amountFirst = "",
    amountSecond = "",
    amountThird = "",
    amountFourth = "",
    amountFactor = 1,

    setAmountFirst,
    setAmountSecond,
    setAmountThird,
    setAmountFourth,
    setAmountFactor,

    setSummary = false,

  } = props;

  const input = useRef( null );

  const [ showInputFirst, setShowInputFirst ] = useState( true );
  const [ showInputSecond, setShowInputSecond ] = useState( false );
  const [ showInputThird, setShowInputThird ] = useState( false );
  const [ showInputFourth, setShowInputFourth ] = useState( false );
  const [ showInputFactor, setShowInputFactor ] = useState( false );
  const [ amountSummary, setAmountSummary ] = useState("");

  function saveAmountFirst() {

    // console.log(`${ amountFirst }`);
    hideInputFirst();

  }

  function saveAmountSecond() {

    // console.log(`${ amountSecond }`);
    hideInputSecond();

  }

  function saveAmountThird() {

    // console.log(`${ amountThird }`);
    hideInputThird();

  }

  function saveAmountFourth() {

    // console.log(`${ amountFourth }`);
    hideInputFourth();

  }

  function saveAmountFactor() {

    // console.log(`${ amountFactor }`);
    hideInputFactor();

  }

  useEffect(() => {
    
    AmountSummaryCalc();
    
  }, [ amountFirst, amountSecond, amountThird, amountFourth ]);
  
  function AmountSummaryCalc() {

    const result = parseInt(amountFirst) + parseInt(amountSecond) + parseInt(amountThird) + parseInt(amountFourth);
    setAmountSummary( result );
    setSummary && setSummary( result );

  }

  function hideInputFirst() { setShowInputFirst( false ); }
  function hideInputSecond() { setShowInputSecond( false ); }
  function hideInputThird() { setShowInputThird( false ); }
  function hideInputFourth() { setShowInputFourth( false ); }
  function hideInputFactor() { setShowInputFactor( false ); }

  return (

    <div className="visor flex center">

      <div className="visor-container">

        <p className="visor-subtitle">чертёж:</p>

        <div className="visor-block relative">

          <div className="visor-block__amount relative">

            <div className="visor-block__amount__first absolute flex row">

              { showInputFirst === true 
            
                ? <input
                  
                    ref = { input }
                    type="text" 
                    className="visor-block__amount__input pointer"
                    value={ amountFirst }
                    onBlur = { saveAmountFirst }
                    onChange={(e) => { setAmountFirst( e.target.value ) }}
                    onKeyUp={(e) => { 

                      if ( e.key === "Enter" ) {
                        
                        saveAmountFirst();

                      } else if ( e.key === "Escape" ) { 

                        hideInputFirst();

                      }

                    }}
                    
                  />
  
              
                : <div  
                
                    className="visor-block__amount__input pointer"
                    onClick={() => { 
                      
                      setShowInputFirst(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );
                    
                    }}
                      
                  >
                      
                    <p className="visor-block__amount__number pointer">{ amountFirst }</p>

                  </div>

              }

              <p 
                className="visor-block__amount__size"
                onClick={() => { 

                  if ( !showInputFirst ) {
                  
                    setShowInputFirst(true);
                    setTimeout( () => {

                      input.current.focus()

                    }, 100 );
                  
                  }
                
                }}
              >
                мм
              </p>

            </div>

            <div className="visor-block__amount__second absolute flex row">

              { showInputSecond === true 
              
                ? <input
                  
                    ref = { input }
                    type="text" 
                    className="visor-block__amount__input pointer"
                    value={ amountSecond }
                    onBlur = { saveAmountSecond }
                    onChange={(e) => { setAmountSecond( e.target.value ) }}
                    onKeyUp={(e) => { 

                      if ( e.key === "Enter" ) {
                        
                        saveAmountSecond();

                      } else if ( e.key === "Escape" ) { 

                        hideInputSecond();

                      }

                    }}
                    
                  />
  
            
                : <div  
                
                    className="visor-block__amount__input pointer"
                    onClick={() => { 
                      
                      setShowInputSecond(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );
                    
                    }}
                    
                  >
                    
                    <p className="visor-block__amount__number pointer">{ amountSecond }</p>

                  </div>

              }

              <p 
                className="visor-block__amount__size"
                onClick={() => { 

                  if ( !showInputSecond ) {
                  
                    setShowInputSecond(true);
                    setTimeout( () => {

                      input.current.focus()

                    }, 100 );
                  
                  }
                
                }}
              >
                мм
              </p>
            
            </div>

            <div className="visor-block__amount__third absolute flex row">

              { showInputThird === true 
              
                ? <input
                  
                    ref = { input }
                    type="text" 
                    className="visor-block__amount__input pointer"
                    value={ amountThird }
                    onBlur = { saveAmountThird }
                    onChange={(e) => { setAmountThird( e.target.value ) }}
                    onKeyUp={(e) => { 

                      if ( e.key === "Enter" ) {
                        
                        saveAmountThird();

                      } else if ( e.key === "Escape" ) { 

                        hideInputThird();

                      }

                    }}
                    
                  />
            
                : <div  
                
                    className="visor-block__amount__input pointer"
                    onClick={() => { 
                      
                      setShowInputThird(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );
                    
                    }}
                      
                    >
                      
                      <p className="visor-block__amount__number pointer">{ amountThird }</p>

                  </div>

              }

              <p 
                className="visor-block__amount__size"
                onClick={() => { 

                  if ( !showInputThird ) {
                  
                    setShowInputThird(true);
                    setTimeout( () => {

                      input.current.focus()

                    }, 100 );
                  
                  }
                
                }}
              >
                мм
              </p>
            
            </div>

            <div className="visor-block__amount__fourth absolute flex row">

              { showInputFourth === true 
              
                ? <input
                  
                    ref = { input }
                    type="text"
                    className="visor-block__amount__input pointer"
                    value={ amountFourth }
                    onBlur = { saveAmountFourth }
                    onChange={(e) => { setAmountFourth( e.target.value ) }}
                    onKeyUp={(e) => { 

                      if ( e.key === "Enter" ) {
                        
                        saveAmountFourth();

                      } else if ( e.key === "Escape" ) { 

                        hideInputFourth();

                      }

                    }}
                    
                  />

            
                : <div  
                  
                    className="visor-block__amount__input pointer"
                    onClick={() => { 
                      
                      setShowInputFourth(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );
                    
                    }}
                      
                    >
                      
                      <p className="visor-block__amount__number pointer">{ amountFourth }</p>

                    </div>

              }

              <p 
                className="visor-block__amount__size"
                onClick={() => { 

                  if ( !showInputFourth ) {
                  
                    setShowInputFourth(true);
                    setTimeout( () => {

                      input.current.focus()

                    }, 100 );
                  
                  }
                
                }}
              >
                мм
              </p>
            
            </div>

          </div>

          <div className="visor-block__total flex row">

            <p className="visor-block__total__length">Длина:</p>
            <p className="visor-block__total__length__number">{ amountSummary || 0 }</p>

            <p 
              className="visor-block__total__length__typesize"
              onClick={() => { 

                if ( !showInputFactor ) {
                
                  setShowInputFactor(true);
                  setTimeout( () => {

                    input.current.focus()

                  }, 100 );
                
                }
              
              }}
            >
              мм x
            </p>

            <div className="visor-block__total__length__factor">

              { showInputFactor === true 
                  
                ? <input
                  
                    ref = { input }
                    type="text" 
                    className="visor-block__amount__input__factor"
                    value={ amountFactor }
                    onBlur = { saveAmountFactor }
                    onChange={(e) => { setAmountFactor( e.target.value ) }}
                    onKeyUp={(e) => { 

                      if ( e.key === "Enter" ) {
                        
                        saveAmountFactor();

                      } else if ( e.key === "Escape" ) { 

                        hideInputFactor();

                      }

                    }}
                    
                  />
            
                : <p 
                    className="visor-block__total__text__factor"
                    onClick={() => { 
                    
                      setShowInputFactor(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );
                    
                    }}
                    
                  >
                    { amountFactor }
                    
                  </p>

              }

            </div>

          </div>
        
        </div>

      </div>

    </div>

  );

}

export default InputVisor;