import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BalkonForm } from '.';
import useGlobal from '../../../store';
import Button from '../../components/button';
import EmptySpace from '../../components/emptyspace';
import Textfield from '../../components/textfield';
import ToggleSlider from '../../components/toggleslider';

const BalkonCustomer = () => {

  const navigate = useNavigate();

  const [ globalState, globalActions ] = useGlobal();
  const { 

    client_name, 
    client_address, 
    client_phone,
    client_entrance,
    client_floor,
    client_elevator

  } = globalState;

  const { changeStates } = globalActions;  

  return (

    <BalkonForm step = { 1 }>
      
      <Textfield

        title = "ФИО заказчика:"
        value = { client_name }
        set = { value => changeStates({ client_name: value }) }

      />

      <Textfield

        title = "Адрес:"
        value = { client_address }
        set = { value => changeStates({ client_address: value }) }

      />

      <div className = "flex inputs">
        
        <Textfield

          title = "Подъезд:"
          value = { client_entrance }
          set = { value => changeStates({ client_entrance: value }) }

        />

        <Textfield

          title = "Этаж:"
          value = { client_floor }
          set = { value => changeStates({ client_floor: value }) }

        />

        <ToggleSlider

          title = "Лифт"
          selected = { client_elevator }
          select = { value => changeStates({ client_elevator: value }) }
          list = {["нет", "есть"]}
          style = {{ opacity: +client_floor > 1 ? 1 : 0 }}

        />

      </div>

      <Textfield

        title = "Телефон:"
        value = { client_phone }
        set = { value => changeStates({ client_phone: value }) }

      />

      <EmptySpace />

      <hr className="small" />

      <Button

        text = "Далее"
        action = { () => navigate('/new/2') }

      />
    
    </BalkonForm>

  );

}

export default BalkonCustomer;