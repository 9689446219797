import React, { useState } from "react";
import Button from "../button";
import Textfield from "../textfield";

//* Форма настроек элементов сметы, в которых фигурирует только кол-во погонных метров

const SmetaFormMP = ({ add, isAdded, amount, close }) => { 
  
  const [ size, setSize ] = useState( amount );

  function handleAdd() {
    
    add( size );
    close();

  }
  
  return (

    <div className="smeta__isolation p-14" draggable="false">

      <div 
        style = {{
          maxWidth: "100px",
          margin: "0 auto 23px"
        }}
      >
        <Textfield

          title = "Кол-во (м.п.):"
          value = { size }
          set = { setSize }

        />
      </div>

      <Button

        text = { !isAdded ? "Добавить" : "Сохранить" }
        action = { handleAdd }
        disabled = { +size < 1 }

      />

    </div>

  );

}

export default SmetaFormMP;