import React from 'react';
import './window.partner.row.scss';

import { ReactComponent as Mail } from './icons/mail.svg';
import { ReactComponent as Whatsapp } from './icons/whatsapp.svg';
import { ReactComponent as Telegram } from './icons/telegram.svg';
import stringIsNotEmpty from '../../../scripts/helpers/string.is.not.empty';


const WindowPartnerRow = ({ company_name="", owner_name = "", email = "", whatsapp = "", telegram = "", schemeUrl = "" }) => {

  const mailHead = `Оцените стоимость изготовления окон`;
  const message = `Здравствуйте, оцените пожалуйста стоимость и срок изготовления данных окон: ${ schemeUrl }`;

  const handleEmailClick    = () => window.open(`mailto:${email}?subject=${ mailHead }&body=${ message }`);
  const handleWhatsAppClick = () => window.open(`https://api.whatsapp.com/send/?phone=${ whatsapp.replace( "+", "" ) }&text=${ message }&type=phone_number&app_absent=0`);
  const handleTelegramClick = () => window.open(`https://telegram.im/${ telegram }`);

  return (

    <div className="window-partner-row">

      <p className="roboto window-partner-row__company">{ company_name }</p>
      <p className="roboto window-partner-row__owner">{ owner_name }</p>

      <div className="flex row window-partner-row__icons">

        { stringIsNotEmpty( email )    && <div onClick = { handleEmailClick }    className="window-partner-row__icons__icon flex"><Mail />    </div> }
        { stringIsNotEmpty( whatsapp ) && <div onClick = { handleWhatsAppClick } className="window-partner-row__icons__icon flex"><Whatsapp /></div> }
        { stringIsNotEmpty( telegram ) && <div onClick = { handleTelegramClick } className="window-partner-row__icons__icon flex"><Telegram /></div> }

      </div>

    </div>

  );

}

export default WindowPartnerRow;