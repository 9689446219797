import React, { useState, useEffect } from "react";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";
import { BALKON, texturesPath } from "../../../settings/constants";
import useGlobal from "../../../store";
import Button from "../button";
import Checkbox from "../checkbox";
import Element from "../element";
import Loading from "../loading";
import Selectfield from "../selectfield";
import Textfield from "../textfield";
import ToggleSlider from "../toggleslider";

const SmetaCupboard = ({ mode, openSide = "left_full", setTexture, close }) => {

  const [ Dummy, reloadDummy ] = useReloadDummy();
  
  const [ globalState, globalActions ] = useGlobal();
  const { balkon_type } = globalState;
  const { environment, changeStates } = globalActions;
  
  function availableWallsInSelector() {

    const arr = [];

    if ( mode === "add" ) {

      if ( !( globalState[`cupboard_left_top_added`] ) || !( ( globalState[`cupboard_left_bottom_added`] ) || ( globalState[`lar_left_added`] ) ) ) { //TODO во второе условие добавить левый ларь

        if ( !( globalState[`cupboard_left_full_added`] ) && !( globalState[`cupboard_left_tumb_added`] ) ) {

          arr.push({ id: 'left', name: "От входа левая" });

        }      
    
      }
    
      if (
    
        ( !( globalState[`cupboard_right_top_added`] ) || !( ( globalState[`cupboard_right_bottom_added`] ) || ( globalState[`lar_right_added`] ) ) ) //TODO во второе условие добавить правый ларь
        
      ) {
    
        if ( !( globalState[`cupboard_right_full_added`] ) && !( globalState[`cupboard_right_tumb_added`] ) ) {
          
          arr.push({ id: 'right', name: "От входа правая" });

        }
    
      }
    
    } else if ( mode === "edit" ) {

      openSide.split('_')[ 0 ] === "left" && arr.push({ id: 'left', name: "От входа левая" });
      openSide.split('_')[ 0 ] === "right" && arr.push({ id: 'right', name: "От входа правая" });

    } else {

      console.error( `SmetaCupboard availableWallsInSelector unknow mode:`, mode );

    }
    // console.log(`availableWallsInSelector arr`, arr);

    return arr;

  } 

  const WALLS = [
    { id: 'left', name: "От входа левая" },
    { id: 'right', name: "От входа правая" }
  ];
  
  const [ side, setSide ] = useState( false );
  
  function availableVariantsInSelector() {

    const arr = [];

    if ( mode === "add" ) {

      const availableWalls = availableWallsInSelector();
      let _side_id = side?.id || availableWalls?.[ 0 ]?.id;

      if ( !_side_id ) { console.error(`SmetaCupboard: availableVariantsInSelector _side_id (${_side_id}) undefined, side:`, side ); return; }

      console.log(`Cupboard availableVariantsInSelector _side_id = ${ _side_id }, availableWalls = `, availableWalls);

      if ( !globalState[`cupboard_${ _side_id }_full_added`] && !globalState[`cupboard_${ _side_id }_tumb_added`] ) {

        if ( !( globalState[`cupboard_${ _side_id }_top_added`]  ) && !( globalState[`cupboard_${ _side_id }_bottom_added`] ) && !( globalState[`lar_${ _side_id }_added`] ) ) {
          
          arr.push({ id: 'full', name: "Во всю стену" });  
          
          +balkon_type !== 5 && arr.push({ id: 'tumb', name: "Шкаф-тумба" });        

        }

        !globalState[`cupboard_${ _side_id }_top_added`] && arr.push({ id: 'top', name: "Навесной шкаф" });

        ( !globalState[`cupboard_${ _side_id }_bottom_added`] && !globalState[`lar_${ _side_id }_added`] ) && arr.push({ id: 'bottom', name: "Шкаф-комод" });

      }

    } else if ( mode === "edit" ) {
      
      openSide.split('_')[ 1 ] === "full"   && arr.push({ id: 'full',   name: "Во всю стену" });
      openSide.split('_')[ 1 ] === "tumb"   && arr.push({ id: 'tumb',   name: "Шкаф-тумба" });
      openSide.split('_')[ 1 ] === "top"    && arr.push({ id: 'top',    name: "Навесной шкаф" });
      openSide.split('_')[ 1 ] === "bottom" && arr.push({ id: 'bottom', name: "Шкаф-комод" });

    } else {

      console.error( `SmetaCupboard availableVariantsInSelector unknow mode:`, mode );

    }

    return arr;

  }
  
  const VARIANTS = [

    { id: 'full',   name: "Во всю стену" },
    { id: 'tumb',   name: "Шкаф-тумба" },
    { id: 'bottom', name: "Шкаф-комод" },
    { id: 'top',    name: "Навесной шкаф" }

  ];

  const [ wallsList, setWallsList ] = useState( availableWallsInSelector() );
  const [ variantsList, setVariantsList ] = useState( availableVariantsInSelector() ); //TODO Ларь 

  const [ variant, setVariant ] = useState( null );
  // const [ variant, setVariant ] = useState( variantsList.find( el => el.id === openSide.split('_')[ 1 ] ) || VARIANTS[ 0 ] );
 
  const POSITION = {

    [ BALKON.SIXMETR ]: { // настройки для balkon_type = 1 ( 6-метровый )

      left: {

        full: [ 0.11, -1.7, -1.56 ],
        tumb: [ 0.11, -1.7, -1.56 ],
        bottom: [ 0.11, -1.7, -1.56 ],
        top: [ 0.11, -1.7, -1.56 ],

      },
      
      right: {

        full: [ 0, -1.7, -1.2 ],
        tumb: [ 0, -1.7, -1.2 ],
        bottom: [ 0, -1.7, -1.2 ],
        top: [ 0, -1.7, -1.2 ]

      }
    
    },
   
    [ BALKON.THREEMETR ]: { // настройки для balkon_type = 2 (3-метровый)

      left: {

        full:   [ -0.9, -1.7, 0.14 ],
        tumb:   [ -0.9, -1.7, 0.14 ],
        bottom: [ -0.9, -1.7, 0.14 ],
        top:    [ -0.9, -1.7, 0.14 ],

      },
      
      right: {

        full:   [ -0.72, -1.7, 0.26 ],
        tumb:   [ -0.72, -1.7, 0.26 ],
        bottom: [ -0.72, -1.7, 0.26 ],
        top:    [ -0.72, -1.7, 0.26 ]

      }
    
    },

    [ BALKON.VITRAGE ]: { // настройки для balkon_type = 3 (витраж)

      left: {

        full:   [ -1.53, -1.7, 0.19 ],
        tumb:   [ -1.53, -1.7, 0.19 ],
        bottom: [ -1.53, -1.7, 0.19 ],
        top:    [ -1.53, -1.7, 0.19 ],

      },
      
      right: {

        full:   [ -1.11, -1.7, 0.1 ],
        tumb:   [ -1.11, -1.7, 0.1 ],
        bottom: [ -1.11, -1.7, 0.1 ],
        top:    [ -1.11, -1.7, 0.1 ]

      }
    
    },

    [ BALKON.VITRAGE_SPLIT ]: { // настройки для balkon_type = 4 (полувитраж)

      left: {

        full:   [ -1.53, -1.7, 0.19 ],
        tumb:   [ -1.53, -1.7, 0.19 ],
        bottom: [ -1.53, -1.7, 0.19 ],
        top:    [ -1.53, -1.7, 0.19 ],

      },
      
      right: {

        full:   [ -1.11, -1.7, 0.1 ],
        tumb:   [ -1.11, -1.7, 0.1 ],
        bottom: [ -1.11, -1.7, 0.1 ],
        top:    [ -1.11, -1.7, 0.1 ]

      }
    
    },

    [ BALKON.DOVETAIL ]: { // настройки для balkon_type = 5 (ласточка)

      left: {

        full:   [ -0.76, -1.4, -0.14 ],
        tumb:   [ -0.76, -1.4, -0.14 ],
        bottom: [ -0.76, -1.4, -0.14 ],
        top:    [ -0.76, -1.4, -0.14 ],

      },
      
      right: {

        full:   [ -0.45, -1.4, 0.2 ],
        tumb:   [ -0.45, -1.4, 0.2 ],
        bottom: [ -0.45, -1.4, 0.2 ],
        top:    [ -0.45, -1.4, 0.2 ]

      }
    
    },

    [ BALKON.SIXMETR_STANDART ]: { // настройки для balkon_type = 6 ( 6-метровый стандарт )

      left: {

        full: [ 0.11, -1.7, -1.56 ],
        tumb: [ 0.11, -1.7, -1.56 ],
        bottom: [ 0.11, -1.7, -1.56 ],
        top: [ 0.11, -1.7, -1.56 ],

      },
      
      right: {

        full: [ 0, -1.7, -1.2 ],
        tumb: [ 0, -1.7, -1.2 ],
        bottom: [ 0, -1.7, -1.2 ],
        top: [ 0, -1.7, -1.2 ]

      }
    
    },

    [ BALKON.HRUSHEVKA ]: { // настройки для balkon_type = 7 (хрущёвка)

      left: {

        full:   [ -0.9, -1.7, 0.14 ],
        tumb:   [ -0.9, -1.7, 0.14 ],
        bottom: [ -0.9, -1.7, 0.14 ],
        top:    [ -0.9, -1.7, 0.14 ],

      },
      
      right: {

        full:   [ -0.72, -1.7, 0.26 ],
        tumb:   [ -0.72, -1.7, 0.26 ],
        bottom: [ -0.72, -1.7, 0.26 ],
        top:    [ -0.72, -1.7, 0.26 ]

      }
    
    },

  }

  const ROTATION = {

    [ BALKON.SIXMETR ]: {

      left: 3.2,
      right: 0

    },    
    
    [ BALKON.THREEMETR ]: {

      left: 0,
      right: 3.2

    },
    
    [ BALKON.VITRAGE ]: {

      left: 3.2,
      right: 0

    },
    
    [ BALKON.VITRAGE_SPLIT ]: {

      left: 3.2,
      right: 0

    },
    
    [ BALKON.DOVETAIL ]: {

      left: 0,
      right: 3.2

    },

    [ BALKON.SIXMETR_STANDART ]: {

      left: 3.2,
      right: 0

    },    
    
    [ BALKON.HRUSHEVKA ]: {

      left: 0,
      right: 3.2

    },

  }

  const [ height, setHeight ] = useState("");
  const [ width, setWidth ] = useState("");
  const [ depth, setDepth ] = useState("");

  useEffect(() => {

    //// console.log(`!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!`);
    //// console.log(`SmetaCupboard mode=${ mode } openSide=${ openSide } side.id ${side?.id} and variant.id ${ variant?.id }`);
    //// console.log(`!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!`);

    const availableVariants = availableVariantsInSelector();
    const availableWalls = availableWallsInSelector();

    setWallsList( availableWalls );
    setVariantsList( availableVariants );
    //// openSide.split('_')[ 1 ] !== variant && setVariant( VARIANTS.find( el => el.id === openSide.split('_')[ 1 ] || VARIANTS[ 0 ] ) );
    
    if ( mode === "edit" && globalState[`cupboard_${ openSide.split('_')[ 0 ] }_${ openSide.split('_')[ 1 ] }_added`] ) {

      setSide( WALLS.find( el => el.id === openSide.split('_')[ 0 ] ) );
      setVariant( VARIANTS.find( el => el.id === globalState[`cupboard_${ openSide.split('_')[ 0 ] }_${ openSide.split('_')[ 1 ] }_type`] ) );
      setWidth( globalState[`cupboard_${ openSide.split('_')[ 0 ] }_${ openSide.split('_')[ 1 ] }_size`]?.width );
      setHeight( globalState[`cupboard_${ openSide.split('_')[ 0 ] }_${ openSide.split('_')[ 1 ] }_size`]?.height );
      setDepth( globalState[`cupboard_${ openSide.split('_')[ 0 ] }_${ openSide.split('_')[ 1 ] }_size`]?.depth );

    } else {

      //// console.log(`-----------------------------------------------`);
      //// console.log(`useEffect SmetaCupboard variantsList`,variantsList);
      //// console.log(`useEffect SmetaCupboard wallsList`,wallsList);
      //// console.log(`-----------------------------------------------`);


      console.log(`Cupboard useEffect openSide = ${ openSide } availableWalls = `, availableWalls );
      console.log(`Cupboard useEffect openSide = ${ openSide } availableVariants = `, availableVariants );

      if ( !availableVariants?.[ 0 ] ) { console.error(`Нет доступных вариантов шкафа для добавления`); close(); }

      setSide( availableWalls[ 0 ] );
      setVariant( availableVariants[ 0 ] );
      // setVariant( VARIANTS[ 0 ] );
      setHeight("");
      setWidth("");
      setDepth("");

    }

    reloadDummy();

  }, [ openSide ]);

  useEffect(() => {

    if ( mode === "add" ) {

      const availableVariants = availableVariantsInSelector();

      setVariantsList( availableVariants );
      setVariant( availableVariants[ 0 ] );
      setHeight("");
      setWidth("");
      setDepth("");

    }

  }, [ side ]);

  const isFormValidated = side !== "" && variant !== "" && height !== "" && width !== "" && depth !== "";

  function handleAddCupboard() {
    ////console.log("globalState[`cupboard_${ side.id }_${ variant.id }_doors`]", globalState[`cupboard_${ side.id }_${ variant.id }_doors`])
    const form = {

      side: side.id,
      variant: variant.id,
      height,
      width,
      depth,
      doors: globalState[`cupboard_${ side.id }_${ variant.id }_doors`],
      position: POSITION[ +balkon_type ][ side.id ][ variant.id ],
      rotation: ROTATION[ +balkon_type ][ side.id ]

    }
    //// console.log(`handleAddCupboard`, { ...form, balkon_type } );
    environment.addCupboard( form );
    close();

  }
  
  return (

    !side ? <Loading /> :

    <div className="smeta__isolation p-14" draggable="false">

      <Dummy />

      <Selectfield

        title = "Стена:"
        selected = { side }
        select = { ( bool ) => { mode !== "edit" && setSide( bool ) } }
        list = { wallsList }
        disabled = { mode === "edit" }
      
      />

      <Selectfield

        title = "Модель шкафа:"
        selected = { variant }
        select = { setVariant }
        list = { variantsList }
        disabled = { mode === "edit" }
      
      />

      <div className="flex cupboard_size">

        <Textfield

          number
          title = "Высота"
          value = { height }
          set = { setHeight }

        />

        <Textfield

          number
          title = "Ширина"
          value = { width }
          set = { setWidth }

        />

        <Textfield

          number
          title = "Глубина"
          value = { depth }
          set = { setDepth }

        />

      </div>

      { globalState[`cupboard_${ side.id }_${ variant.id }_added`] &&
      
        <React.Fragment>
      
          <ToggleSlider

            title = "Материал дверей:"
            list = {[ "ЛДСП", "Жалюзи" ]}
            selected = {
              ( 
                globalState[`cupboard_${ side.id }_${ variant.id }_doors`] !== false 
                && globalState[`cupboard_${ side.id }_${ variant.id }_doors`] !== undefined 
                && globalState[`cupboard_${ side.id }_${ variant.id }_doors`] !== "" 
              ) 
                ? globalState[`cupboard_${ side.id }_${ variant.id }_doors`] 
                : 0 
            }
            select = { ( bool ) => {

              console.log(`Cupboard ToggleSlider sets cupboard_${ side.id }_${ variant.id }_doors to `, bool);
              
              changeStates({ 
                
                [`cupboard_${ side.id }_${ variant.id }_doors`]: bool,
                [`cupboard_${ side.id }_${ variant.id }_materials`]: {},
                [`cupboard_${ side.id }_${ variant.id }_texture_name`]: "Выберите материал",
                [`cupboard_${ side.id }_${ variant.id }_texture_image`]: `${ texturesPath }/nothing.jpg`
              
              });

              setTexture({ texture: `${ texturesPath }/nothing.jpg`, size: "2,2,2", name: "Выберите материал" });
            
            }}

          />

          <div className="toggleslider"><div className="toggleslider__title">{ globalState[`cupboard_${ side.id }_${ variant.id }_doors`] === 0 ? "Цвет " : "Покраска " }дверей:</div></div>

          <Element

            image = { globalState[`cupboard_${ side.id }_${ variant.id }_texture_image`] === "" ? "" : globalState[`cupboard_${ side.id }_${ variant.id }_texture_image`] }
            text = { 
              globalState[`cupboard_${ side.id }_${ variant.id }_texture_name`] === ""
                ? ( globalState[`cupboard_${ side.id }_${ variant.id }_doors`] === 0 ? "Выберите материал" : "Без окрашивания" ) 
                : globalState[`cupboard_${ side.id }_${ variant.id }_texture_name`] 
            }
            action = { () => {
              
              changeStates({ 
                
                matarialMenuIsOpened: true,
                smetaIsOpened: false,
                smetaBackIsClose: false,
                smetaPage: "smeta"
              
              });
            
            }}

          />

        </React.Fragment>

      }

      <Button

        text = { !globalState[`cupboard_${ side.id }_${ variant.id }_added`] ? "Добавить" : "Сохранить" }
        action = { handleAddCupboard }
        disabled = { !isFormValidated }

      />
      

    </div>

  );

}

export default SmetaCupboard;