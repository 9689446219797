import React, { useState, useEffect } from "react";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";
import { texturesPath } from "../../../settings/constants";
import useGlobal from "../../../store";
import Button from "../button";
import Checkbox from "../checkbox";
import Element from "../element";
import Loading from "../loading";
import Selectfield from "../selectfield";
import Textfield from "../textfield";
import ToggleSlider from "../toggleslider";

const SmetaLar = ({ mode, openSide = "left", setTexture, close }) => {

  const [ Dummy, reloadDummy ] = useReloadDummy();
  
  const [ globalState, globalActions ] = useGlobal();
  const { balkon_type } = globalState;
  const { environment, changeStates } = globalActions;
  
  function availableWallsInSelector() {

    const arr = [];

    if ( mode === "add" ) {

      if ( !( globalState[`lar_left_added`] ) && !( globalState[`cupboard_left_bottom_added`] ) ) {

        if ( !globalState[`cupboard_left_full_added`] ) {

          arr.push({ id: 'left', name: "От входа левая" });

        }      
    
      }
    
      if (
    
        !( globalState[`lar_right_added`] ) || !( globalState[`cupboard_right_bottom_added`] )
        
      ) {
    
        if ( !globalState[`cupboard_right_full_added`] ) {
          
          arr.push({ id: 'right', name: "От входа правая" });

        }
    
      }
    
    } else if ( mode === "edit" ) {

      openSide === "left" && arr.push({ id: 'left', name: "От входа левая" });
      openSide === "right" && arr.push({ id: 'right', name: "От входа правая" });

    } else {

      console.error( `SmetaLar availableWallsInSelector unknow mode:`, mode );

    }
    // console.log(`availableWallsInSelector arr`, arr);

    return arr;

  } 

  const WALLS = [
    { id: 'left', name: "От входа левая" },
    { id: 'right', name: "От входа правая" }
  ];
  
  const [ side, setSide ] = useState( false );
  

  const [ wallsList, setWallsList ] = useState( availableWallsInSelector() );
 
  const POSITION = {

    1: { // настройки для balkon_type = 1 ( 6-метровый )

      left: [ 0, -1.7, -1.22 ],      
      right: [ -0.05, -1.7, -1.85 ],
    
    },
   
    2: { // настройки для balkon_type = 2 (3-метровый)

      left: [ 1.0, -1.7, 0.11 ],
      right: [ -2.4, -1.7, 0.14 ],
    
    },

    3: { // настройки для balkon_type = 3 (витраж)

      left: [ -2.2, -1.7, -0.06 ],      
      right: [ -0.28, -1.7, -0.04 ],
    
    },

    4: { // настройки для balkon_type = 4 (полувитраж)

      left: [ -2.2, -1.7, -0.06 ],      
      right: [ -0.28, -1.7, -0.04 ],
    
    },

    5: { // настройки для balkon_type = 5 (ласточка)

      left: [ -0.9, -1.4, -0.02 ],
      right: [ -0.36, -1.4, 0.01 ],
    
    },

    6: { // настройки для balkon_type = 6 ( 6-метровый стандарт )

      left: [ 0, -1.7, -1.22 ],      
      right: [ -0.05, -1.7, -1.85 ],
    
    },

    7: { // настройки для balkon_type = 7 (хрущёвка)

      left: [ 1.0, -1.7, 0.11 ],
      right: [ -2.4, -1.7, 0.14 ],
    
    },

  }

  const ROTATION = {

    1: {

      left: 0,
      right: 3.16

    },    
    
    2: {

      left: 1.58,
      right: -1.58

    },
    
    3: {

      left: -1.58,
      right: 1.58

    },
    
    4: {

      left: -1.58,
      right: 1.58

    },
    
    5: {

      left: 0,
      right: 3.16

    },

    6: {

      left: 0,
      right: 3.16

    },    
    
    7: {

      left: 1.58,
      right: -1.58

    },

  }

  const [ height, setHeight ] = useState("");
  const [ width, setWidth ] = useState("");
  const [ depth, setDepth ] = useState("");

  useEffect(() => {

    //// console.log(`!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!`);
    //// console.log(`SmetaCupboard mode=${ mode } openSide=${ openSide } side.id ${side?.id} and variant.id ${ variant?.id }`);
    //// console.log(`!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!`);

    const availableWalls = availableWallsInSelector();

    setWallsList( availableWalls );
    //// openSide.split('_')[ 1 ] !== variant && setVariant( VARIANTS.find( el => el.id === openSide.split('_')[ 1 ] || VARIANTS[ 0 ] ) );
    
    if ( mode === "edit" && globalState[`lar_${ openSide }_added`] ) {

      setSide( WALLS.find( el => el.id === openSide ) );
      setWidth( globalState[`lar_${ openSide }_size`]?.width );
      setHeight( globalState[`lar_${ openSide }_size`]?.height );
      setDepth( globalState[`lar_${ openSide }_size`]?.depth );

    } else {

      //// console.log(`-----------------------------------------------`);
      //// console.log(`useEffect SmetaCupboard variantsList`,variantsList);
      //// console.log(`useEffect SmetaCupboard wallsList`,wallsList);
      //// console.log(`-----------------------------------------------`);

      setSide( availableWalls[ 0 ] );
      setHeight("");
      setWidth("");
      setDepth("");

    }

    reloadDummy();

  }, [ openSide ]);

  useEffect(() => {

    if ( mode === "add" ) {

      setHeight("");
      setWidth("");
      setDepth("");

    }

  }, [ side ]);

  const isFormValidated = side !== "" && height !== "" && width !== "" && depth !== "";

  function handleAddLar() {
    
    const form = {

      side: side.id,
      height,
      width,
      depth,
      position: POSITION[ +balkon_type ][ side.id ],
      rotation: ROTATION[ +balkon_type ][ side.id ]

    }
    //// console.log(`handleAddLar`, { ...form, balkon_type } );
    environment.addLar( form );
    close();

  }
  
  return (

    !side ? <Loading /> :

    <div className="smeta__isolation p-14" draggable="false">

      <Dummy />

      <Selectfield

        title = "Стена:"
        selected = { side }
        select = { ( obj ) => { mode !== "edit" && setSide( obj ) } }
        list = { wallsList }
        disabled = { mode === "edit" }
      
      />

      <div className="flex cupboard_size">

        <Textfield

          number
          title = "Высота"
          value = { height }
          set = { setHeight }

        />

        <Textfield

          number
          title = "Ширина"
          value = { width }
          set = { setWidth }

        />

        <Textfield

          number
          title = "Глубина"
          value = { depth }
          set = { setDepth }

        />

      </div>

      { globalState[`lar_${ side.id }_added`] &&
      
        <React.Fragment>
      
          <ToggleSlider

            title = "Материал ларя:"
            list = {[ "ЛДСП", "Дерево" ]}
            selected = {
              ( 
                globalState[`lar_${ side.id }_doors`] !== false 
                && globalState[`lar_${ side.id }_doors`] !== undefined 
                && globalState[`lar_${ side.id }_doors`] !== "" 
              ) 
                ? globalState[`lar_${ side.id }_doors`] 
                : 0 
            }
            select = { ( bool ) => {

              console.log(`Lar ToggleSlider sets lar_${ side.id }_doors to `, bool);
              
              changeStates({ 
                
                [`lar_${ side.id }_doors`]: bool,
                [`lar_${ side.id }_materials`]: {},
                [`lar_${ side.id }_texture_name`]: "Выберите материал",
                [`lar_${ side.id }_texture_image`]: `${ texturesPath }/nothing.jpg`
              
              });

              setTexture({ texture: `${ texturesPath }/nothing.jpg`, size: "2,2,2", name: "Выберите материал" });
            
            }}

          />

          <div className="toggleslider"><div className="toggleslider__title">{ globalState[`lar_${ side.id }_doors`] === 0 ? "Цвет" : "Покраска" }:</div></div>
          
          {/* <div className="toggleslider"><div className="toggleslider__title">Цвет:</div></div> */}

          <Element

            image = { globalState[`lar_${ side.id }_texture_image`] === "" ? "" : globalState[`lar_${ side.id }_texture_image`] }
            text = { globalState[`lar_${ side.id }_texture_name`] === "" ? "Выберите материал" : globalState[`lar_${ side.id }_texture_name`] }
            action = { () => {
              
              changeStates({
                
                matarialMenuIsOpened: true,
                smetaIsOpened: false,
                smetaBackIsClose: false,
                smetaPage: "smeta"
              
              });
            
            }}

          />

        </React.Fragment>

      }

      <Button

        text = { !globalState[`lar_${ side.id }_added`] ? "Добавить" : "Сохранить" }
        action = { handleAddLar }
        disabled = { !isFormValidated }

      />

      {/* //TODO if globalState[`cupboard_${ side }_added`] button-remove */}
      

    </div>

  );

}

export default SmetaLar;