import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from './copy.svg';
import stringIsNotEmpty from '../../../scripts/helpers/string.is.not.empty';
import Textfield from '../textfield';
import "./textfield.clipboard.scss";

const TextfieldCopyToClipboard = ({ title = "", value, successText = "ссылка скопирована!" }) => {

  const [ isUrlCopied, setIsUrlCopied ] = useState( false );

  function showUrlIsCopiedText() {

    setIsUrlCopied( true );

    setTimeout(() => {

      setIsUrlCopied( false );

    }, 2000 );

  }

  return (

    <div className="textfield relative">

      { stringIsNotEmpty( title ) && <div className = "textfield__title">{ title }</div> }

      <div className="textfield__copytoclipboard relative">

        <Textfield

          value = { value }
          set = { () => {} }

        />

        <CopyToClipboard text = { value } onCopy = { showUrlIsCopiedText }>

          <div className = "textfield__copytoclipboard__btn absolute flex justify-center items-center">
            <CopyIcon />
          </div>

        </CopyToClipboard>

      </div>

      { isUrlCopied && <div className="textfield__copytoclipboard__copied_text">{ successText }</div> }

    </div>

  )

}

export default TextfieldCopyToClipboard;