import { MATERIAL_TYPES } from "../../constants";

const DEFAULT_MATERIAL_SIZE = {

  INITIAL:                           2,
  DEBUG_TEST:                        5,
  [ MATERIAL_TYPES.LAMINAT        ]: 1.2,
  [ MATERIAL_TYPES.QUARC_VINIL    ]: 1.2,
  [ MATERIAL_TYPES.LAMINAT_FLOOR  ]: 0.8,
  [ MATERIAL_TYPES.WOOD_PARKET    ]: 2.5,
  [ MATERIAL_TYPES.WOOD_VAGONKA   ]: 4,
  [ MATERIAL_TYPES.WOOD_MDF       ]: 1.1,
  [ MATERIAL_TYPES.STONE_BRICKS   ]: 1.1,
  [ MATERIAL_TYPES.LINOLEUM       ]: 1,
  [ MATERIAL_TYPES.TILE           ]: 2,
  [ MATERIAL_TYPES.LDSP           ]: 2,
  
};

export default DEFAULT_MATERIAL_SIZE;