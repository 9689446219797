import React, { useState, useEffect, useRef, Fragment } from "react";
import useReloadDummy from "../../../scripts/hooks/use.reload.dummy";
import useGlobal from "../../../store";
import Button from "../button";
import Checkbox from "../checkbox";
import Textfield from "../textfield";
import ToggleSlider from "../toggleslider";

const SmetaGlazing = ({ close }) => {

  const [ Dummy, reloadDummy ] = useReloadDummy();
  const [ globalState, globalActions ] = useGlobal();
  const { glazing_job } = globalState;
  const { changeStates, smeta } = globalActions;
  const { calculateSmeta } = smeta;

  const [ glazing, setGlazing ] = useState( glazing_job.glazing );
  const [ room3stvor, setRoom3stvor ]   = useState( glazing_job.room3stvor );
  const [ room12stvor, setRoom12stvor ] = useState( glazing_job.room12stvor );
  const [ balkexitWindow, setBalkexitWindow ] = useState( glazing_job.balkexitWindow );
  const [ balkexitChebur, setBalkexitChebur ] = useState( glazing_job.balkexitChebur );
  const [ balkon12sideRama, setBalkon12sideRama ] = useState( glazing_job.balkon12sideRama );
  const [ glazingTwoContur, setGlazingTwoContur ] = useState( glazing_job.glazingTwoContur );
  const [ splitcircleGlazing, setSplitcircleGlazing ] = useState( glazing_job.splitcircleGlazing );
  const [ vitrageFloorCeiling, setVitrageFloorCeiling ] = useState( glazing_job.vitrageFloorCeiling );
  const [ vitrageFloorCeilingSplitCircle, setVitrageFloorCeilingSplitCircle ] = useState( glazing_job.vitrageFloorCeilingSplitCircle );

  const [ otkosRoomWindow12stvor, setOtkosRoomWindow12stvor ] = useState( glazing_job.otkosRoomWindow12stvor );
  const [ otkosRoomWindow3stvor,  setOtkosRoomWindow3stvor  ] = useState( glazing_job.otkosRoomWindow3stvor );
  const [ otkosBalkonExitWindow,  setOtkosBalkonExitWindow  ] = useState( glazing_job.otkosBalkonExitWindow );
  const [ otkosBalkonExitChebur,  setOtkosBalkonExitChebur  ] = useState( glazing_job.otkosBalkonExitChebur );

  function handleAddGlazing() {

    const form = {

      glazing,
      room12stvor,
      room3stvor,
      balkexitWindow,
      balkexitChebur,
      glazingTwoContur,
      balkon12sideRama,
      splitcircleGlazing,
      vitrageFloorCeiling,
      otkosRoomWindow3stvor,
      otkosBalkonExitWindow,
      otkosBalkonExitChebur,
      otkosRoomWindow12stvor,
      vitrageFloorCeilingSplitCircle

    }

    changeStates( { glazing_job: form }, calculateSmeta );

  }

  async function toggleCheckBox( set, value, trueVal = "", falseVal = false ) {

    const newValue = ( !value && value !== 0 ) ? falseVal : trueVal;
    set( newValue );
    
  }

  const isNotSelected = s => (( !s || s === 0 ) && s !== "" );

  const isFormChanged = (

    ( glazing !== glazing_job.glazing )
    ||
    ( room12stvor !== glazing_job.room12stvor )
    ||
    ( room3stvor !== glazing_job.room3stvor )
    ||
    ( balkexitWindow !== glazing_job.balkexitWindow )
    ||
    ( balkexitChebur !== glazing_job.balkexitChebur )
    ||
    ( glazingTwoContur !== glazing_job.glazingTwoContur )
    ||
    ( balkon12sideRama !== glazing_job.balkon12sideRama )
    ||
    ( splitcircleGlazing !== glazing_job.splitcircleGlazing )
    ||
    ( vitrageFloorCeiling !== glazing_job.vitrageFloorCeiling )
    ||
    ( otkosRoomWindow3stvor !== glazing_job.otkosRoomWindow3stvor )
    ||
    ( otkosBalkonExitWindow !== glazing_job.otkosBalkonExitWindow )
    ||
    ( otkosBalkonExitChebur !== glazing_job.otkosBalkonExitChebur )
    ||
    ( otkosRoomWindow12stvor !== glazing_job.otkosRoomWindow12stvor )
    ||
    ( vitrageFloorCeilingSplitCircle !== glazing_job.vitrageFloorCeilingSplitCircle )

  );

  return (

    <div className="smeta__scroll smeta__isolation smeta__glazing_job p-14" draggable="false">

      <Dummy />

      <p className="smeta__head__title"><b>Остекление:</b></p>
      <br/><hr />
      
      { isFormChanged &&

        <Fragment>

          <Button

            text = "Сохранить"
            action = { handleAddGlazing }

          />

          <hr/>

        </Fragment>

        }

      {/* <CheckBoxWithTextArea

        title = "1-2х створчатое комнатное окно"
        value = { room12stvor }
        set = { setRoom12stvor }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      /> */}

      <CheckBoxWithTextArea

        title = "3х створчатое комнатное окно"
        value = { room3stvor }
        set = { setRoom3stvor }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      />

      {/* <CheckBoxWithTextArea

        title = "Балконный выход с окном"
        value = { balkexitWindow }
        set = { setBalkexitWindow }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      />

      <CheckBoxWithTextArea

        title = "Балконный выход чебурашка"
        value = { balkexitChebur }
        set = { setBalkexitChebur }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      /> */}

      <CheckBoxWithTextArea

        title = "Полукруглое остекление балкона и лоджии"
        value = { splitcircleGlazing }
        set = { setSplitcircleGlazing }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      />

      <CheckBoxWithTextArea

        title = "Остекление балкона и лоджии"
        value = { glazing }
        set = { setGlazing }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      />

      <CheckBoxWithTextArea

        title = "Балкон с 1-ой/2-мя боковыми рамами"
        value = { balkon12sideRama }
        set = { setBalkon12sideRama }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      />

      <CheckBoxWithTextArea

        title = "Витражное остекление балкона и лоджии от пола до потолка"
        value = { vitrageFloorCeiling }
        set = { setVitrageFloorCeiling }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      />

      <CheckBoxWithTextArea

        title = "Витражное остекление балкона и лоджии от пола до потолка полукруглое"
        value = { vitrageFloorCeilingSplitCircle }
        set = { setVitrageFloorCeilingSplitCircle }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      />

      <CheckBoxWithTextArea

        title = "Остекление балкона и лоджии вторым контуром"
        value = { glazingTwoContur }
        set = { setGlazingTwoContur }        
        toggleCheckBox = { toggleCheckBox }
        isNotSelected = { isNotSelected }

      />
      
      { isFormChanged &&

        <Fragment>
        
          <Button

            text = "Сохранить"
            action = { handleAddGlazing }

          />
        
          <hr/>

        </Fragment>

      }

      <br/>

      <p className="smeta__head__title "><b>Откосы:</b></p>

      <br/><hr/>

      <CheckBoxWithInputSlider

        title = "Откос: 1-2х створчатое комнатное окно"
        value = { otkosRoomWindow12stvor }
        set = { setOtkosRoomWindow12stvor }        
        toggleCheckBox = { toggleCheckBox }

      />

      <CheckBoxWithInputSlider

        title = "Откос: 3х створчатое комнатное окно"
        value = { otkosRoomWindow3stvor }
        set = { setOtkosRoomWindow3stvor }        
        toggleCheckBox = { toggleCheckBox }

      />

      <CheckBoxWithInputSlider

        title = "Откос: балконный выход с окном"
        value = { otkosBalkonExitWindow }
        set = { setOtkosBalkonExitWindow }        
        toggleCheckBox = { toggleCheckBox }

      />

      <CheckBoxWithInputSlider

        title = "Откос: балконный выход чебурашка"
        value = { otkosBalkonExitChebur }
        set = { setOtkosBalkonExitChebur }        
        toggleCheckBox = { toggleCheckBox }

      />

      <br />

      { isFormChanged &&

        <Button

          text = "Сохранить"
          action = { handleAddGlazing }

        />

      }

    </div>

  );

}

const CheckBoxWithTextArea = ({ title = "", value, set, toggleCheckBox, isNotSelected }) => {
  
  const thisInput = useRef( null );

  useEffect(() => {

    !isNotSelected( value ) && setTimeout(() => {

      thisInput.current.focus();

    }, 150 );

  }, [ isNotSelected( value ) ]);
  
  return (

    <Fragment>

      <Checkbox

        text = { title }
        value = { value }
        set = { ( bool ) => toggleCheckBox( set, bool ) }

      />

      { isNotSelected( value ) ? null :

        <Textfield

          number
          title = "м2"
          value = { value }
          set = { set }
          refDOM = { thisInput }

        />

      }

      <hr />

    </Fragment>    

  );

}

const CheckBoxWithInputSlider = ({ title = "", value, set, toggleCheckBox }) => (

  <Fragment>

    <Checkbox

      text = { title }
      value = { ( !value && value !== 0 ) ? false : true }

      set = { () => toggleCheckBox( 
        set,
        ( !value && value !== 0 ) ? true : false, 
        0, 
        false 
      )}

    />

    { ( !value && value !== 0 ) ? null :

      <Fragment>

        <ToggleSlider

          title = "Тип:"
          list = {[ "кирпичный", "панельный" ]}
          selected = { value }
          select = { set }

        />

        <br/>

      </Fragment>    

    }

    <hr />

  </Fragment>    

);

export default SmetaGlazing;