import React, { useState, useEffect, Fragment } from "react";
import cssIf from "../../../scripts/helpers/class.add.if";
import useGlobal from "../../../store";
import Textfield from "../textfield";

const SmetaMenu = ({ menu, set }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { GLOBAL } = globalState;
  const { ENVIRONMENTS_GROUPS } = GLOBAL;

  const [ search, setSearch ] = useState("");

  useEffect(() => {

    console.log(`SmetaMenu UEF ENVIRONMENTS_GROUPS ${ typeof ENVIRONMENTS_GROUPS }`, ENVIRONMENTS_GROUPS);

  }, [ ENVIRONMENTS_GROUPS ])

  const otherGroupID = 7;
  const otherGroup = ENVIRONMENTS_GROUPS?.length > 0 ? ENVIRONMENTS_GROUPS.find( group => group.id === otherGroupID ) : false;

  return (

    <div className="smeta__menu" draggable="false">

      <div className="smeta__menu__search relative">

        <Textfield

          title = "Поиск по меню сметы:"
          icon = "search"
          value = { search }
          set = { setSearch }
          placeholder = "Найти..."

        />

      </div>

      { search === "" 
      
        ? <SmetaMenuGroups

            ENVIRONMENTS_GROUPS = { ENVIRONMENTS_GROUPS }
            otherGroup = { otherGroup }
            otherGroupID = { otherGroupID }
            menu = { menu }
            set = { set }

          />

        : <SmetaMenuSearch

            topmenu = { ENVIRONMENTS_GROUPS }
            search = { search }
            menu = { menu }
            set = { set }

          />        
        
      }

    </div>

  );

}

const SmetaMenuGroups = ({ ENVIRONMENTS_GROUPS, otherGroup, otherGroupID, menu, set }) => (
  
  <Fragment>

    { ENVIRONMENTS_GROUPS?.length > 0 && ENVIRONMENTS_GROUPS.filter( el => el.id !== otherGroupID ).map(( group, key ) => ( group?.hidden === 1 ? null :

      <SmetaMenuGroup

        key = { key }
        name = { group.name }
        instantly = { group?.instantly }
        content = { menu.length > 0 ? menu.filter( row => (+row.group === +group.id && !row?.hidden )) : [] }
        set = { set }

      />

      ))}

      { otherGroup &&

      <SmetaMenuGroup

        name = { otherGroup.name }
        content = { menu.length > 0 ? menu.filter( row => (+row.group === +otherGroupID && !row?.hidden )) : [] }
        set = { set }

      />

    }

  </Fragment>

)

const SmetaMenuSearch = ({ topmenu, menu, search, set }) => (

  <Fragment>

    { topmenu.filter( row => ( row.name.toLowerCase().indexOf( search.toLowerCase() ) !== -1 && row?.instantly )).map(( row, key ) => (

      <SmetaMenuElement

        key = { key }
        name = { row.name }
        action = { () => set( row.instantly ) }

      />

    ))}

    { menu.filter( row => row.name.toLowerCase().indexOf( search.toLowerCase() ) !== -1 && +row.hidden !== 1 ).map(( row, key ) => (

      <SmetaMenuElement

        key = { key }
        name = { row.name }
        action = { () => set( row.path ) }

      />

    ))}

  </Fragment>

);

const SmetaMenuGroup = ({ name, content = [], set, instantly = false }) => {

  const [ isOpen, setIsOpen ] = useState( false );

  const ToggleGroup = () => setIsOpen( !isOpen );
  const OpenSettingsPage = path => { set( path ); setIsOpen( false ); }
  
  function handleClickGroupHead() {
    
    instantly
      ? OpenSettingsPage( instantly )
      : ToggleGroup();
  
  }

  return ( ( ( !content || content.length < 1 ) && !instantly ) ? <Fragment /> :

    <div className={`smeta__menu__group ${ cssIf( isOpen, `smeta__menu__group--opened` ) }`}>

      <div 
        className="smeta__menu__element smeta__menu__group__head flex items-center" 
        onClick = { handleClickGroupHead }
      >

        <div>{ name }</div>

      </div>

      { isOpen && 
      
        <div className="smeta__menu__group__inner">

          { content.length > 0 && content.map(( row, key ) => (

            <SmetaMenuElement

              key = { key }
              name = { row.name }
              action = { () => OpenSettingsPage( row.path ) }

            />

          ))}        

        </div>
        
      }
      
    </div>
    
  )

}

const SmetaMenuElement = ({ name, action }) => (

  <div
                    
    className = "smeta__menu__element" 
    onClick = { action }

  >

    { name }

  </div>

)

export default SmetaMenu;