import React, { useState } from "react";
import useGlobal from "../../../store";
import Button from "../button";
import ToggleSlider from "../toggleslider";

const SmetaThresholdDismantling = ({ close }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { thresholdDismantling } = globalState;
  const { environment } = globalActions;
  
  const [ type, setType ] = useState( thresholdDismantling.type );

  function handleAddThresholdDismantling() {
    
    environment.addEnvironmentToSmeta("thresholdDismantling", { added: true, type } );
    close();

  }
  
  return (

    <div className="smeta__isolation p-14" draggable="false">

      <ToggleSlider

        title = "Тип дома:"
        list = {[ "кирпичный", "панельный" ]}
        selected = { type }
        select = { setType }

      />

      <Button

        text = { !thresholdDismantling.added ? "Добавить" : "Сохранить" }
        action = { handleAddThresholdDismantling }

      />

    </div>

  );

}

export default SmetaThresholdDismantling;