import React, { useState, useEffect } from "react";
import useGlobal from "../../../store";
import Button from "../button";
import Checkbox from "../checkbox";

const SmetaInsulationDismantling = ({ close }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { insulationDismantling } = globalState;
  const { environment } = globalActions;
  
  const [ border,     setBorder ]     = useState( insulationDismantling.border );
  const [ edges,      setEdges ]      = useState( insulationDismantling.edges );
  const [ homeWall,   setHomeWall ]   = useState( insulationDismantling.homeWall );
  // const [ ceiling, setCeiling ] = useState( insulationDismantling.ceiling );
  // const [ floor,   setFloor ]   = useState( insulationDismantling.floor );

  const [ showSaveBtn, setShowSaveBtn ] = useState( false );

  function handleAddInsulationDismantling() {

    const form = {

      added: true,
      border,
      edges,
      homeWall,
      // ceiling,
      // floor,

    }

    environment.addEnvironmentToSmeta("insulationDismantling", form );
    close();

  }

  useEffect(() => {

    if ( 

      border        !== insulationDismantling.border
      || edges      !== insulationDismantling.edges
      || homeWall   !== insulationDismantling.homeWall
      // || ceiling !== insulationDismantling.ceiling
      // || floor   !== insulationDismantling.floor

    ) {

      setShowSaveBtn( true );

    } else {

      setShowSaveBtn( false );

    }

  }, [

    border,
    edges,
    homeWall,
    // ceiling,
    // floor

  ]);

  return (

    <div className="smeta__isolation p-14" draggable="false">

      <p className="smeta__smalltext">Отметьте части балкона подлежащие демонтажу утеплителя</p>

      <Checkbox

        text = "Бордюр"
        value = { border }
        set = { setBorder }

      />

      <Checkbox

        text = "Края"
        value = { edges }
        set = { setEdges }

      />

      <Checkbox

        text = "Домашняя стена"
        value = { homeWall }
        set = { setHomeWall }

      />

      { showSaveBtn &&

        <Button

          text = { !insulationDismantling.added ? "Добавить" : "Сохранить" }
          action = { handleAddInsulationDismantling }

        />

      }

    </div>

  );

}

export default SmetaInsulationDismantling;