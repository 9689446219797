import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useGlobal from '../../../store';
import Container from '../../components/container';

import { ReactComponent as PlusIcon } from "../../components/smeta/icons/plus.svg";

const Employees = () => {

  const navigate = useNavigate();

  const [ globalState, globalActions ] = useGlobal();
  const { employees } = globalState;
  const { balkon } = globalActions;

  useEffect(() => { balkon.all(); }, []);
  
  return (

    <Container className = "employees smeta">

      <div className="smeta__head flex justify-between items-center">
        
        <div className="smeta__head__title font-medium">Мои сотрудники</div>

        <div 
          className = {`smeta__head__btn flex items-center font-medium`}
          onClick = { () => navigate(`/team/add`) }
        >
          
          добавить

          <PlusIcon />

        </div>

      </div>

      <div className = "employees__list smeta__menu">

        { employees?.length > 1 && employees.map(( employee, key ) => key !== 0 && (

          <div
          
            key = { key }
            className = "smeta__menu__element" 
            onClick = { () => employee?.id && navigate(`/team/${ employee.id }`) }
            
          >

            { employee?.name }

          </div>

        ))}

      </div>

    </Container>

  );

}

export default Employees;