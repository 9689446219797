import React from "react";
import cssIf from "../../../scripts/helpers/class.add.if";
import { ReactComponent as Checked } from './icon.svg';
import "./checkbox.scss";

const Checkbox = ({ text = "", value = false, set }) => (

  <div
    onClick = { () => set(( value || value === "" ) ? false : true ) }
    className={`checkbox ${ cssIf(( value || value === "" ) && value !== 0, `checkbox--checked` )} flex items-center`}
  >

    <div className="checkbox__anchor flex justify-center items-center"><Checked /></div>
    { text !== "" && <div className="checkbox__text">{ text }</div> }

  </div>

);

export default Checkbox;