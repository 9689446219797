import React, { useState, useRef } from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import "./selectfield.scss";

const Selectfield = ( props ) => {

  const {

    selected = false, 
    select, 
    list = [], 
    isLoading = false, 
    title = "", 
    placeholder = "Нажмите, чтобы выбрать", 
    object = "", 
    disabled = false, 
    readOnly = false, 
    style = {}, 
    tip = "" 

  } = props;

  const LIST_MAX_HEIGHT = 170;

  const listContainer = useRef();
  const [ isOpened, setIsOpened ] = useState( false );

  function Toggle() {

    if ( readOnly ) { return; }

    if ( !isOpened ) { listContainer.current.style.overflow = "hidden"; }
    
    setIsOpened( !isOpened );

    let listContainerHeight = listContainer.current.scrollHeight + 20;

    if ( listContainerHeight > LIST_MAX_HEIGHT ) { 
      
      listContainerHeight = LIST_MAX_HEIGHT;
      listContainer.current.style.overflow = "auto";
    
    }
    
    listContainer.current.style.maxHeight = listContainer.current.style.maxHeight ? null : listContainerHeight + "px";

  }

  return (

    <div className = {`selectfield ${ cssIf( isOpened, "selectfield--opened" ) }`} style = { style }>

      { title !== "" && <div className = "selectfield__title">{ title }</div> }

      <div 
        className = {`selectfield__field ${ cssIf( !isLoading && !selected, "selectfield__field--placeholder" )} ${ cssIf( disabled, 'disabled' ) } flex items-center`} 
        onClick = { () => !disabled && Toggle() }
        title = { tip?.length > 0 ? tip : undefined }
      >
        
        { isLoading ? "Загрузка..." : selected ? selected?.name ? selected?.name : selected : placeholder }

        {/* {( !isLoading && selected && object?.length > 0 ) &&  */}
        {( !isLoading && selected?.comment && selected?.comment?.length > 0 ) && 

          <div className="selectfield__field__object">

            - { selected.comment }

          </div> 
          
        }

        <div className="selectfield__field__arrow" />
        
      </div>

      <div className = "selectfield__list" ref = { listContainer }>

        { list.length > 0 && list.map(( item, key ) => (

          <div
            key = { key }
            className = "selectfield__list__item"
            onClick = { ( e ) => {

              select( item ); 
              Toggle(); 
              e.stopPropagation();
              
            }}
          >

            { item?.name ? item.name : item }

          </div>

        ))}

      </div>

    </div>

  );

}

export default Selectfield;