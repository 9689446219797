import React, { useState, useEffect } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { ReactComponent as ApplyIcon } from '../../../images/icons/apply.svg';
import { ReactComponent as CancelIcon } from '../../../images/icons/cancel.svg';
import { ReactComponent as TrashIcon } from '../../../images/icons/trash.svg';
import cssIf from '../../../scripts/helpers/class.add.if';
import useGlobal from '../../../store';
import { renderPartSize } from '../../../store/actions/windows';
import Textfield from '../textfield';
import { SortableWindows, Window } from '../window';
import './windows.constructor.scss';

const WindowsConstructor = ({ readOnly = false }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { windows_schema, windows_height, windows_area_sizes } = globalState;
  const { changeStates, smeta } = globalActions;

  const setSchema = ( arr ) => !readOnly && changeStates({ windows_schema: arr });

  const [ selectedWindow, setSelectedWindow ] = useState("");
  const [ baseWindowHeight, setBaseWindowHeight ] = useState("");

  function handleAddWindow( t ) {

    if( readOnly ) { return; }
    // console.log(`windows_schema ${ typeof windows_schema }`, windows_schema );

    const newWindow = {

      id: windows_schema.length + 1,
      type: t,
      rotated: false

    }

    setSchema([

      ...windows_schema,
      newWindow

    ]);

  }

  function handleSelectWindow( id ) { !readOnly && setSelectedWindow( id ); }

  function handleRotateWindow( id ) {

    if( readOnly ) { return; }

    const temp = [];

    windows_schema.forEach( w => {

      let _w = w;
      if ( w.id === id ) { _w.mirrored = !_w.mirrored; }
      temp.push( _w );

    });

    setSchema( temp );

  }

  function handleMoveWindow({ oldIndex, newIndex }) {

    if( readOnly ) { return; }
    const updated = arrayMove( windows_schema, oldIndex, newIndex );
    setSchema( updated );

  }

  // function saveWindowSize( w, h ) {

  //   if( readOnly ) { return; }    
  //   if ( w.length === 0 || w === 0 ) { alert('Ширина не может быть пустой'); return; }
  //   if ( h.length === 0 || h === 0 ) { alert('Высота не может быть пустой'); return; }

  //   const temp = [];

  //   windows_schema.forEach( window => {

  //     let _window = window;

  //     if ( window.id === selectedWindow ) {

  //       _window.width = w;
  //       _window.height = h;

  //     }

  //     temp.push( _window );

  //   });

  //   baseWindowHeight === "" && setBaseWindowHeight( h );

  //   setSchema( temp );
  //   setSelectedWindow("");

  // }

  function handleRemoveWindow() {

    if( readOnly ) { return; }
    const temp = [];
    windows_schema.forEach( window => window.id !== selectedWindow && temp.push( window ) );
    setSchema( temp );
    setSelectedWindow("");
    smeta.calculateSmeta();

  }

  return (

    <div className = {`windows_constructor ${ cssIf( readOnly, `windows_constructor--readOnly` ) }`}>

      { !readOnly && 

        <React.Fragment>

          <div className = "balkonform__title text-left mb-4">Добавьте окна:</div>
          <div className = "tip text-left mb-4 font-semibold">чертёж</div>

        </React.Fragment>  
        
      }
      
      <SortableWindows

        axis = "x"
        lockAxis = "x"
        useDragHandle = { true }
        helperClass = "window_flying"
        onSortEnd = { handleMoveWindow }

        windows_schema = { windows_schema } 
        windows_height = { windows_height }
        selected = { selectedWindow }
        select = { handleSelectWindow }
        rotate = { handleRotateWindow }

        renderPartSize = { () => renderPartSize({ windows_schema, windows_area_sizes }) }
        setWindowsAreaSizes = { globalActions.windows.changePartSize }
        setWindowsHeight = { ( value ) => {
          
          changeStates({ windows_height: value });
          setTimeout(() => { globalActions.smeta.calculateSmeta(); }, 150)
        
        }}

        style = { readOnly ? { userSelect: 'none' } : {} }
      
      />

      {( selectedWindow && selectedWindow !== "" && !readOnly ) &&
      
        <WindowParams

          remove = { handleRemoveWindow }
          //// width = { windows_schema.find( w => w.id === selectedWindow )?.width || "" }
          //// height = { windows_schema.find( w => w.id === selectedWindow )?.height || baseWindowHeight }
          //// apply = { saveWindowSize }
          //// cancel = { () => setSelectedWindow( false ) }
        
        />
      
      }

      { !readOnly && 

        <React.Fragment>

          <div className = "tip text-left mb-4 font-semibold">Добавьте окна:</div>
    
          <div className="windows_schema flex items-center">
            
            <Window type = { 1 } action = { () => handleAddWindow( 1 ) } />
            <Window type = { 2 } action = { () => handleAddWindow( 2 ) } />
            <Window type = { 3 } action = { () => handleAddWindow( 3 ) } />
            <Window type = { 4 } action = { () => handleAddWindow( 4 ) } />

          </div>

          <div className="windows_schema windows_schema-2 flex items-center">

            <Window type = { 5 } action = { () => handleAddWindow( 5 ) } />
            <Window type = { 6 } action = { () => handleAddWindow( 6 ) } />
            <Window type = { 7 } action = { () => handleAddWindow( 7 ) } />
          
          </div>

        </React.Fragment>
      
      }
    
    </div>

  );

}

export default WindowsConstructor;

const WindowParams = ({ width = "", height = "", apply = () => {}, cancel = () => {}, remove = () => {} }) => {

  const [ w, setW ] = useState( width );
  const [ h, setH ] = useState( height );

  useEffect(() => {

    width !== w && setW( width );
    height !== h && setH( height );

  }, [ width, height ])
  
  return (

    <div className="window_params justify-center"> {/* justify-between items-center */}

      {/* Возможно когда-то пригодится: индивидуальные размеры окна
      
      <div className = "window_params window_params__main items-center">

        <CancelIcon onClick = { cancel } />

        <div className = "window_params__inputs items-center">

          <Textfield

            title = "Ширина:"
            value = { w }
            set = { setW }

          />

          <Textfield

            title = "Высота:"
            value = { h }
            set = { setH }

          />
          
        </div>

        <ApplyIcon onClick = { () => apply( w, h ) } />

      </div> */}

      <div onClick = { remove } className="remove_window_container flex justify-center items-center">

        <TrashIcon className = "remove_window" />
        Удалить окно

      </div>
      
    </div>

  );

}