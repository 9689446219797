import React, { useEffect } from "react";
import useGlobal from "../../../store";
import Selectfield from "../selectfield";
import ToggleSlider from "../toggleslider";

import { 

  windows_profile_types,
  windows_profile_color_types,
  windows_insulation_types

} from "../../../settings/constants";

import Checkbox from "../checkbox";
import Textfield from "../textfield";

const WindowsInnerSettings = ({ readOnly = false }) => {

  const [ globalState, globalActions ] = useGlobal();

  const { 
    
    windows_inner_insulation,
    windows_inner_profile,
    windows_inner_count_cameras,
    windows_inner_glazing,
    windows_inner_profile_color,
  
  } = globalState;

  const { changeStates, changeObjectState } = globalActions;

  return (

    <div className="windows_settings windows_inner_settings">

      <Selectfield

        title = "Тип втуренних окон:"
        readOnly = { readOnly }
        list = { windows_insulation_types }    
        selected = { windows_insulation_types.find( el => el.id === windows_inner_insulation ) }
        select = { ({ id }) => { 

          if ( !readOnly ) {
            
            changeStates({ windows_inner_insulation: id });
            setTimeout(() => { globalActions.smeta.calculateSmeta(); }, 150)
          
          }

        }}    

      />

      <Selectfield

        title = "Внутренний оконный профиль:"
        readOnly = { readOnly }
        selected = { windows_profile_types.find( el => el.id === windows_inner_profile ) }
        select = { ({ id }) => { !readOnly && changeStates({ windows_inner_profile: id }) }}
        list = { windows_profile_types }        

      />

      { windows_inner_profile ? 
      
        <React.Fragment>

          <ToggleSlider

            title = "Количество камер:"
            selected = { +windows_inner_count_cameras }
            select = { ( value ) => { !readOnly && changeStates({ windows_inner_count_cameras: value }) }}
            list = {[ 3, 5 ]}
            style = {{ maxWidth: "50%" }}

          />

          <ToggleSlider

            title = "Стеклопакет:"
            selected = { +windows_inner_glazing }
            select = { ( value ) => { !readOnly && changeStates({ windows_inner_glazing: value }) }}
            list = {[ 1, 2, 3 ]}
            style = {{ maxWidth: "80%" }}

          />


          <ToggleSlider

            title = "Цвет профиля:"
            selected = { windows_inner_profile_color?.type }
            select = { ( value ) => { !readOnly && changeObjectState( "windows_inner_profile_color", "type", value ) }}
            list = { windows_profile_color_types }

          />

          { windows_inner_profile_color?.type === 1 && 

            <React.Fragment>

              <div className="checkboxes flex inputs justify-between">

                <Checkbox

                  text = "Внутренняя"
                  value = { windows_inner_profile_color?.inner }
                  set = { ( value ) => { !readOnly && changeObjectState( "windows_inner_profile_color", "inner", value ) }}
                
                />

                <Checkbox

                  text = "Наружняя"
                  value = { windows_inner_profile_color?.outer }
                  set = { ( value ) => { !readOnly && changeObjectState( "windows_inner_profile_color", "outer", value ) }}
                
                />

              </div>
              
              {( !readOnly || ( windows_inner_profile_color?.colorName && windows_inner_profile_color?.colorName !== "" ) ) && 
              
                <Textfield

                  title = "Цвет ламинации:"
                  readOnly = { readOnly }
                  value = { windows_inner_profile_color?.colorName || "" }
                  set = {  ( value ) => { changeObjectState( "windows_inner_profile_color", "colorName", value ) } }
                  placeholder = "Напишите название цвета"

                />
                
              }

            </React.Fragment>

          }

        </React.Fragment>

        : ""
        
      }
          

    </div>

  );

}

export default WindowsInnerSettings;