import React, { useState } from "react";
import Button from "../button";
import Textfield from "../textfield";
import ToggleSlider from "../toggleslider";
import useGlobal from "../../../store";
import prettyFloat from "../../../scripts/helpers/pretty.float";

const SmetaDiscount = ({ close }) => { 
  
  const [ globalState, globalActions ] = useGlobal();
  const { discountMode, smeta_sum } = globalState;
  const { environment, changeStates } = globalActions;
  const { addEnvironmentToSmeta } = environment;

  const [ discount, setDiscount ] = useState( globalState.discount );
  const setDiscountMode = async mode => changeStates({ discountMode: mode, discount: 0 });

  function handleAdd() {
    
    addEnvironmentToSmeta( "discount", discount )
    close();

  }

  function handleRemove() {
    
    addEnvironmentToSmeta( "discount", 0 )
    close();

  }

  const isWrongValue = (

    ( discountMode === 0 && discount >= smeta_sum )
    ||
    ( discountMode === 1 && ( discount > 70 || discount < 0 ))

  )
  
  return (

    <div className="smeta__isolation p-14" draggable="false">
      
      <ToggleSlider

        title = "Скидка будет указываться:"
        selected = { discountMode }
        select = { setDiscountMode }
        list = {["в числах", "в процентах"]}

      />

      <br />

      <div className="flex items-center" style = {{ maxWidth: "35%", margin: 'auto' }}>

        <Textfield

          type = "number"
          title = "Скидка"
          value = { discount }
          set = { setDiscount }
          isWrongValue = { isWrongValue }
          inputStyle = {{ textAlign: 'right' }}

        />

        <div style = {{ fontSize: '26px', marginLeft: '8px', paddingTop: '4px' }}>

          { discountMode === 0 ? "₽" : "%" }

        </div>

      </div>

      {( discountMode === 1 && discount > 0 && !isWrongValue ) && 

        <p style = {{ textAlign: 'center' }}>
          Скидка составит: { prettyFloat( smeta_sum / 100 * discount ) } ₽
        </p> 

      }

      { isWrongValue && 

        <p 
          className="error" 
          style = {{ textAlign: 'center' }}
        >
          недопустимое значение
        </p>

      }

      <br />

      <Button

        text = "Сохранить"
        action = { handleAdd }
        disabled = { isWrongValue }

      />

      <hr />

      <Button

        text = "Удалить скидку"
        action = { handleRemove }
        color = "red"

      />

    </div>

  );

}

export default SmetaDiscount;