import { post } from 'axios';
import cookie from 'react-cookies';
import API from '../../settings/api';

export async function get( 
  
  store, 
  id, 
  setDataLoaded, 
  setCompany,
  setName,
  setAddress,
  setEmail,
  setPhone,
  setWhatsApp,
  setTelegram,
  setDataFromServer 
  
) {

  const token = cookie.load(`token`);

  try {

    const { status, data } = await post( API.windowsPartners.get, { id }, { headers: { token } } );
    if ( status !== 200 ) { throw(`Не удалось открыть проект с ID ${ id }`); }

    const {

      company_name,
      owner_name,
      owner_full_name,
      address,
      email,
      phone,
      whatsapp,
      telegram

    } = data.partner;
    
    setDataFromServer({
      
      company: company_name,
      name: owner_full_name,
      address: address,
      email: email,
      phone: phone,
      whatsApp: whatsapp,
      telegram: telegram

    });

    setCompany( company_name || "" );
    setName( owner_full_name || "" );
    setAddress( address || "" );
    setEmail( email || "" );
    setPhone( phone || "" );
    setWhatsApp( whatsapp || "" );
    setTelegram( telegram || "" );

    setDataLoaded( true );

  } catch ( err ) {

    console.error( err );
    return false;

  }
  
}

export async function add( store, obj = {}, close ) {

  try {

    const token = cookie.load(`token`);

    const { status, data } = await post( API.windowsPartners.add, obj, { headers: { token } } );

    // console.log(`companies.addWindowPartner data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    await store.actions.balkon.all();

    close();

  } catch ( err ) {

    console.error( err );

  }
  
}

export async function edit( store, obj = {}, close ) {

  try {

    const token = cookie.load(`token`);

    const { status, data } = await post( API.windowsPartners.edit, obj, { headers: { token } } );

    // console.log(`companies.editWindowsPartner data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    await store.actions.balkon.all();

    close();

  } catch ( err ) {

    console.error( err );

  }
  
}

export async function remove( store, id, close ) {

  try {

    if ( !window.confirm(`Вы точно хотите удалить поставщика окон?`) ) { return false; }

    const token = cookie.load(`token`);

    const { status, data } = await post( API.windowsPartners.remove, { id }, { headers: { token } } );

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    await store.actions.balkon.all();

    setTimeout( () => { close() }, 150 );

  } catch ( err ) {

    console.error( err );

  }
  
}