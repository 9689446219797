import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import "./element.scss";

const Element = ({ 

  text = "", 
  image = "", 
  action = () => {}, 
  disabled = false, 
  className = "" 

}) => (

  <div 
  
    onClick = { action }
    className = {`element flex items-center ${ className } ${ cssIf( disabled, 'disabled' ) }`}
    
  >

    <div 
      
      className = "element__image" 
      style = {{ backgroundImage: `url(${ image })` }}
      
    />

    <div className = "element__text">{ text }</div>

  </div>

);

export default Element;