import { BALKON } from "../constants";
import BALKON_DOVETAIL_SIZES from "./balkons/balkon.dovetail.material.sizes";
import BALKON_SIXMETR_SIZES from "./balkons/balkon.sixmetr.material.sizes";
import BALKON_THREEMETR_SIZES from "./balkons/balkon.threemetr.material.sizes";
import BALKON_VITRAGE_SIZES from "./balkons/balkon.vitrage.material.sizes";

const MATERIAL_SIZE = {

  [ BALKON.SIXMETR ]:           BALKON_SIXMETR_SIZES,
  [ BALKON.SIXMETR_STANDART ]:  BALKON_SIXMETR_SIZES,
  [ BALKON.VITRAGE ]:           BALKON_VITRAGE_SIZES,
  [ BALKON.VITRAGE_SPLIT ]:     BALKON_VITRAGE_SIZES,
  [ BALKON.DOVETAIL ]:          BALKON_DOVETAIL_SIZES,
  [ BALKON.THREEMETR ]:         BALKON_THREEMETR_SIZES,
  [ BALKON.HRUSHEVKA ]:         BALKON_THREEMETR_SIZES,

}



export default MATERIAL_SIZE;