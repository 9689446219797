import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cookie from 'react-cookies';
import Container from '../../components/container';
import Textfield from '../../components/textfield';
import Button from '../../components/button';

import './sign.scss';
import useGlobal from '../../../store';

const Sign = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { sign } = globalActions;

  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ error, setError ] = useState("");

  const navigate = useNavigate();

  useEffect( () => { 
    
    const token = cookie.load("token");
    token && sign.Check( token, () => { navigate('/'); });
  
  }, []);

  useEffect( () => { error !== "" && setError(""); }, [ email, password ]);

  const formIsValidated = ( 
    
    email.indexOf('@') !== -1 
    && email.indexOf('.') !== -1 
    && email?.split('.')?.[ email?.split('.')?.length - 1 ]?.length >= 2 
    && password.length > 2 

  );

  return (

    <Container className = "sign">

      <div className="sign__big_logo">

        3D РадиВас
        <span>Авторизация</span>  

      </div>

      <Textfield

        title = "E-mail:"
        value = { email }
        set = { setEmail }

      />

      <Textfield

        title = "Пароль:"
        type = "password"
        value = { password }
        set = { setPassword }

      />

      { error.length > 0 && <div className = "error text-center">{ error }</div> }

      <Button

        text = "Войти"
        action = { () => { sign.In( email, password, () => navigate('/' ), setError ) } }
        disabled = { !formIsValidated }

      />

      {/* TODO */}
      {/* <div className="sign__recovery_pass"><span>Не помню пароль</span></div> */}

    </Container>

  )

}

export default Sign;