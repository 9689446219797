import React, { useState } from 'react';
import WindowInnerSizeField from '../window.inner.size.field';
import { ReactComponent as Body } from './icons/body.svg';
import './inner.window.scss';

const InnerWindow = ({ x = "", y = "", save = () => {} }) => {

  const [ X, setX ] = useState( x );
  const [ Y, setY ] = useState( y );

  return (

    <div className = "inner-window relative">

      <Body />
      
      <WindowInnerSizeField
      
        value = { X }
        set = { setX }
        save = { () => save( "x", X ) }
        sortName = "X"
        
      />

      <WindowInnerSizeField
      
        value = { Y }
        set = { setY }
        save = { () => save( "y", Y ) }
        sortName = "Y"
      
      />

    </div>

  );

}

export default InnerWindow;