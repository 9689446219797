import React from 'react';
import cssIf from '../../../scripts/helpers/class.add.if';
import "./button.scss";

const Button = ( props ) => {

  const {
    
    text = "ОК", 
    action = () => {}, 
    style = {}, 
    disabled = false, 
    tip = "", 
    color = "green", 
    icon = false
  
  } = props;

  return (

    <button

      style = { style }
      className={`button button--${ color } ${ cssIf( disabled, "disabled" ) } flex justify-center items-center`}
      onClick = { () => { action() }}
      title = { tip }
      
    >

      { icon && <div className = "button__icon flex justify-center items-center">{ icon }</div> }

      <div className="button__text">{ text }</div>

    </button>

  );

}

export default Button;