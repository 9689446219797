import React, { useState, useEffect, useRef, Fragment } from "react";
import { ReactComponent as PlusIcon } from "./icons/plus.svg";
import { ReactComponent as Increase } from "./icons/increase.svg";
import { ReactComponent as Decrease } from "./icons/decrease.svg";
import { ReactComponent as CrossIcon } from "./icons/cross.svg";
import { ReactComponent as TriangleIcon } from "./icons/small-triangle.svg";
import cssIf from "../../../scripts/helpers/class.add.if";
import useGlobal from "../../../store";
import "./smeta.scss";
import numberSpaces from "../../../scripts/helpers/numbers.with.spaces";
import hideZeroFloat from "../../../scripts/helpers/hide.zero.float";
import SmetaMenu from "./menu";
import SmetaIsolation from "./isolation";
import SmetaCupboard from "./cupboard";
import SmetaLar from "./lar";
import SmetaTabletop from "./tabletop";
import SmetaClothesDryer from "./clothes.dryer";
import SmetaMoldTreatment from "./mold.treatment";
import SmetaFormMP from "./smeta.m.p.form";
import SmetaFoamCracks from "./foam.cracks";
import SmetaThresholdDismantling from "./threshold.dismantling";
import SmetaInsulationDismantling from "./insulation.dismantling";
import SmetaFastenersInstallation from "./fasteners";
import SmetaAeratedMasonry from "./aerated.masonry";
import SmetaRemovalWithWelding from "./removal.with.welding";
import { SMETA_PRICE_MODE } from "../../../settings/constants";
import SmetaGlazing from "./glazing";
import SmetaDiscount from "./discount";
import { ContractSettingsPage } from "../balkon.settings";

const Smeta = ({ setTexture, opened, close }) => {

  const [ globalState, globalActions ] = useGlobal();

  const { 

    GLOBAL,

    smetaPage, 
    smetaPageMode, 
    smetaSubpage,
    smetaBackIsClose,

    insulation,
    moldTreatment,
    rollableTable,
    tableTop,
    clothesDryer,
    ledStrip,
    curtain,
    pipeRailing,

    lowtideInstallation,
    windowsDismantling,
    slitSandwichClosure,
    garbageToContainer,
    garbageRecycling,

    lampForSale,
    childLock,
    departureOfBrigade,

    curtainOfCustomer,
    sillOfCustomer,
    stretchCeiling,
    buildingCeiling,
    floorLifting,
    tilesDismantling,
    roofInstallation,
    foamCracks,
    thresholdDismantling,
    insulationDismantling,
    removalWithWelding,
    fastenersInstallation,
    aeratedMasonry,
    corrugatedBoardOutside,

    dismantlingEasy,
    dismantlingHard

  } = globalState;

  const { changeStates, environment } = globalActions;
  const { addEnvironmentToSmeta } = environment;

  const [ menuRows, setMenuRows ] = useState([]);

  useEffect(() => {

    const menu = [];

    GLOBAL.ENVIRONMENTS.forEach(( element ) => {

      let row = element;

      if ( element.path === "cupboard" ) {

        row.hidden = (

          ( 
            globalState[`cupboard_left_full_added`]  
            || 
            globalState[`cupboard_left_tumb_added`] 
            || 
            ( 
              globalState[`cupboard_left_top_added`] 
              && 
              globalState[`cupboard_left_bottom_added`] 
            )
            ||
            ( 
              globalState[`cupboard_left_top_added`] 
              && 
              globalState[`lar_left_added`] 
            )
          )
          && 
          (            
            globalState[`cupboard_right_full_added`]  
            || 
            globalState[`cupboard_right_tumb_added`] 
            || 
            ( 
              globalState[`cupboard_right_top_added`] 
              && 
              globalState[`cupboard_right_bottom_added`] 
            )
            ||
            ( 
              globalState[`cupboard_right_top_added`] 
              && 
              globalState[`lar_right_added`] 
            )
          )

        ); 

      }

      if ( element.path === "lar" ) {

        row.hidden = (

          ( 
            globalState[`cupboard_left_full_added`] 
            || 
            globalState[`cupboard_left_tumb_added`] 
            || 
            globalState[`cupboard_left_bottom_added`]             
            ||
            globalState[`lar_left_added`]             
          )
          && 
          (            
            globalState[`cupboard_right_full_added`] 
            ||               
            globalState[`cupboard_right_tumb_added`] 
            ||
            globalState[`cupboard_right_bottom_added`]               
            ||              
            globalState[`lar_right_added`]               
            
          )

        ); 

      }
      
      if ( element.path === "curtain" ) { row.hidden = curtain.added; }
      if ( element.path === "ledStrip" ) { row.hidden = ledStrip.added; }
      if ( element.path === "tabletop" ) { row.hidden = tableTop.added; }
      if ( element.path === "childLock" ) { row.hidden = +childLock === 1; }
      if ( element.path === "isolation" ) { row.hidden = insulation.added; }
      if ( element.path === "foamCracks" ) { row.hidden = foamCracks.added; }
      if ( element.path === "pipeRailing" ) { row.hidden = pipeRailing.added; }
      if ( element.path === "lampForSale" ) { row.hidden = +lampForSale === 1; }
      if ( element.path === "clothesDryer" ) { row.hidden = clothesDryer.added; }
      if ( element.path === "floorLifting" ) { row.hidden = +floorLifting === 1; }
      if ( element.path === "moldTreatment" ) { row.hidden = moldTreatment.added; }
      if ( element.path === "rollableTable" ) { row.hidden = +rollableTable === 1; }
      if ( element.path === "sillOfCustomer" ) { row.hidden = sillOfCustomer.added; }
      if ( element.path === "aeratedMasonry" ) { row.hidden = aeratedMasonry.added; }
      if ( element.path === "stretchCeiling" ) { row.hidden = +stretchCeiling === 1; }
      if ( element.path === "dismantlingEasy" ) { row.hidden = +dismantlingEasy === 1; }
      if ( element.path === "dismantlingHard" ) { row.hidden = +dismantlingHard === 1; }
      if ( element.path === "buildingCeiling" ) { row.hidden = +buildingCeiling === 1; }
      if ( element.path === "tilesDismantling" ) { row.hidden = +tilesDismantling === 1; }
      if ( element.path === "roofInstallation" ) { row.hidden = +roofInstallation === 1; }
      if ( element.path === "garbageRecycling" ) { row.hidden = +garbageRecycling === 1; }
      if ( element.path === "curtainOfCustomer" ) { row.hidden = curtainOfCustomer.added; }
      if ( element.path === "garbageToContainer" ) { row.hidden = +garbageToContainer === 1; }
      if ( element.path === "windowsDismantling" ) { row.hidden = +windowsDismantling === 1; }
      if ( element.path === "departureOfBrigade" ) { row.hidden = +departureOfBrigade === 1; }
      if ( element.path === "lowtideInstallation" ) { row.hidden = +lowtideInstallation === 1; }
      if ( element.path === "slitSandwichClosure" ) { row.hidden = +slitSandwichClosure === 1; }
      if ( element.path === "thresholdDismantling" ) { row.hidden = thresholdDismantling.added; }
      if ( element.path === "fastenersInstallation" ) { row.hidden = fastenersInstallation.added; }
      if ( element.path === "insulationDismantling" ) { row.hidden = insulationDismantling.added; }
      if ( element.path === "corrugatedBoardOutside" ) { row.hidden = +corrugatedBoardOutside === 1; }
      if ( element.path === "removalWithWelding" ) { row.hidden = ( removalWithWelding.added && removalWithWelding.forward && removalWithWelding.left && removalWithWelding.right ); }

      menu.push( row );

    });

    setMenuRows( menu );

  }, [ 
    
    insulation.added,
    fastenersInstallation.added,
    removalWithWelding.added,
    moldTreatment.added,
    curtainOfCustomer.added,
    foamCracks.added,
    thresholdDismantling.added,
    insulationDismantling.added,
    sillOfCustomer.added,
    GLOBAL.ENVIRONMENTS.length, 
    rollableTable,
    buildingCeiling,
    floorLifting,
    tilesDismantling,
    roofInstallation,
    lowtideInstallation,
    windowsDismantling,
    slitSandwichClosure,
    garbageToContainer,
    garbageRecycling,
    lampForSale,
    childLock,
    stretchCeiling,
    departureOfBrigade,
    corrugatedBoardOutside,
    tableTop.added,     
    clothesDryer.added,     
    ledStrip.added,     
    curtain.added,     
    pipeRailing.added,     
    aeratedMasonry.added,
    dismantlingEasy,
    dismantlingHard,  

    globalState.cupboard_left_full_added,
    globalState.cupboard_left_tumb_added,
    globalState.cupboard_left_top_added,
    globalState.cupboard_left_bottom_added,
    globalState.lar_left_added,

    globalState.cupboard_right_full_added,
    globalState.cupboard_right_tumb_added,
    globalState.cupboard_right_top_added,
    globalState.cupboard_right_bottom_added,
    globalState.lar_right_added

  ]);

  const ACTIONS = {

    sockets: environment.addSocket,
    lamp: environment.addLamp,
    stretchCeiling: environment.addStretchCeiling,
    rollableTable: () => addEnvironmentToSmeta(`rollableTable`),
    lowtideInstallation: () => addEnvironmentToSmeta(`lowtideInstallation`),
    windowsDismantling: () => addEnvironmentToSmeta(`windowsDismantling`),
    slitSandwichClosure: () => addEnvironmentToSmeta(`slitSandwichClosure`),
    garbageToContainer: () => addEnvironmentToSmeta(`garbageToContainer`),
    garbageRecycling: () => addEnvironmentToSmeta(`garbageRecycling`),
    lampForSale: () => addEnvironmentToSmeta(`lampForSale`),
    childLock: () => addEnvironmentToSmeta(`childLock`),
    departureOfBrigade: () => addEnvironmentToSmeta(`departureOfBrigade`),
    buildingCeiling: () => addEnvironmentToSmeta(`buildingCeiling`),
    floorLifting: () => addEnvironmentToSmeta(`floorLifting`),
    tilesDismantling: () => addEnvironmentToSmeta(`tilesDismantling`),
    roofInstallation: () => addEnvironmentToSmeta(`roofInstallation`),
    corrugatedBoardOutside: () => addEnvironmentToSmeta(`corrugatedBoardOutside`),
    dismantlingEasy: () => addEnvironmentToSmeta(`dismantlingEasy`),
    dismantlingHard: () => addEnvironmentToSmeta(`dismantlingHard`),

  }

  const CONTENT = {

    smeta: <SmetaTable />,
    menu: (
    
      <SmetaMenu 
      
        menu = { menuRows } 
        set = { ( page ) => {
          
          ACTIONS?.[ page ] 
            ? ACTIONS[ page ]() 
            : changeStates({ smetaPage: page, smetaPageMode: "add" }) 
          
        }} 
        
      />
      
    ),
    isolation: <SmetaIsolation close = { close } />,
    removalWithWelding: <SmetaRemovalWithWelding close = { close } />,
    moldTreatment: <SmetaMoldTreatment close = { close } />,
    insulationDismantling: <SmetaInsulationDismantling close = { close } />,
    tabletop: <SmetaTabletop close = { close } />,
    clothesDryer: <SmetaClothesDryer close = { close } />,
    aeratedMasonry: <SmetaAeratedMasonry close = { close } />,
    cupboard: (
      
      <SmetaCupboard 
      
        mode = { smetaPageMode }
        setTexture = { setTexture }
        openSide = { smetaSubpage || "left_full" }
        close = { close } 
        
      />
      
    ),
    lar: (
      
      <SmetaLar 
      
        mode = { smetaPageMode }
        setTexture = { setTexture }
        openSide = { smetaSubpage || "left" }
        close = { close } 
        
      />
      
    ),
    ledStrip: (

      <SmetaFormMP 

        isAdded = { ledStrip.added }
        add = {( size ) => {
          addEnvironmentToSmeta("ledStrip", { added: true, size });
          addEnvironmentToSmeta("ledStripBox", globalState.ledStripBox === 0 ? 1 : globalState.ledStripBox );
        }}
        amount = { ledStrip.size }
        close = { close } 

      />

    ),
    curtain: (

      <SmetaFormMP 

        isAdded = { curtain.added }
        add = { size => addEnvironmentToSmeta("curtain", { added: true, size }) }
        amount = { curtain.size }
        close = { close } 

      />

    ),
    pipeRailing: (

      <SmetaFormMP 

        isAdded = { pipeRailing.added }
        add = { size => addEnvironmentToSmeta("pipeRailing", { added: true, size }) }
        amount = { pipeRailing.size }
        close = { close } 

      />

    ),
    curtainOfCustomer: (

      <SmetaFormMP 

        isAdded = { curtainOfCustomer.added }
        add = { size => addEnvironmentToSmeta("curtainOfCustomer", { added: true, size }) }
        amount = { curtainOfCustomer.size }
        close = { close } 

      />

    ),
    sillOfCustomer: (

      <SmetaFormMP 

        isAdded = { sillOfCustomer.added }
        add = { size => addEnvironmentToSmeta("sillOfCustomer", { added: true, size }) }
        amount = { sillOfCustomer.size }
        close = { close } 

      />

    ),
    foamCracks: <SmetaFoamCracks close = { close } />,
    thresholdDismantling: <SmetaThresholdDismantling close = { close } />,
    fastenersInstallation: <SmetaFastenersInstallation close = { close } />,
    glazingJob: <SmetaGlazing close = { close } />,
    discount: <SmetaDiscount close = { close } />,

  }

  return (

    <div className = {`picker_texture smeta ${ cssIf( opened, 'picker_texture--opened' ) }`} draggable = "false">

      <div className = "smeta__head flex justify-between items-center">
        
        <div className = "smeta__head__title font-medium">
          { smetaPage === "menu" ? "Добавить в смету" : menuRows.find( el => el.path === smetaPage )?.name || "Смета" }
        </div>

        <div 
          className = {`smeta__head__btn ${ cssIf( smetaPage !== "smeta", "smeta__head__btn--close" ) } flex items-center font-medium`}
          onClick = { () => !smetaBackIsClose ? changeStates({ smetaPage: smetaPage === "smeta" ? "menu" : "smeta" }) : close() }
          draggable="false"
        >
          
          { smetaPage === "smeta" ? "добавить" : "назад" }

          <PlusIcon />

        </div>

      </div>

      { CONTENT[ smetaPage ] }

    </div>

  );

}

const SmetaTable = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { smeta = [], smetaPriceMode } = globalState;
  const { environment } = globalActions;
  const { addEnvironmentToSmeta, removeEnvironmentFromSmeta } = environment;

  function handleRemove( id ) {

    //TODO refactory to switch case with default is removeEnvironmentFromSmeta

    if ( id.indexOf('cupboard') !== -1 ) { environment.removeCupboard( id ); }
    if ( id.indexOf('lar') !== -1 ) { environment.removeLar( id ); }
    if ( id.indexOf('sockets') !== -1 ) { environment.removeSocket({ all: true }); }
    if ( id.indexOf('lamp') !== -1 ) { environment.removeLamp({ all: true }); }
    if ( id.indexOf('rollableTable') !== -1 ) { removeEnvironmentFromSmeta(`rollableTable`); }
    if ( id.indexOf('stretchCeiling') !== -1 ) { environment.removeStretchCeiling(); }
    if ( id.indexOf('tabletop') !== -1 ) { environment.removeTabletop(); }
    if ( id.indexOf('clothesDryer') !== -1 ) { environment.removeClothesDryer(); }
    if ( id.indexOf('ledStrip') !== -1 ) { removeEnvironmentFromSmeta( `ledStrip`, { added: false, size: "" } ); removeEnvironmentFromSmeta( `ledStripBox`, 0, false ); }
    if ( id.indexOf('curtain') !== -1 ) { removeEnvironmentFromSmeta( `curtain`, { added: false, size: "" } ); }
    if ( id.indexOf('pipeRailing') !== -1 ) { removeEnvironmentFromSmeta( `pipeRailing`, { added: false, size: "" } ); }
    if ( id.indexOf('curtainOfCustomer') !== -1 ) { removeEnvironmentFromSmeta( `curtainOfCustomer`, { added: false, size: "" } ); }
    if ( id.indexOf('sillOfCustomer') !== -1 ) { removeEnvironmentFromSmeta( `sillOfCustomer`, { added: false, size: "" } ); }
    if ( id.indexOf('moldTreatment') !== -1 ) { removeEnvironmentFromSmeta( `moldTreatment`, { added: false, border: false, edges: false, ceiling: false, floor: false } ); }
    if ( id.indexOf('lowtideInstallation') !== -1 ) { removeEnvironmentFromSmeta(`lowtideInstallation`); }
    if ( id.indexOf('windowsDismantling') !== -1 ) { removeEnvironmentFromSmeta(`windowsDismantling`); }
    if ( id.indexOf('slitSandwichClosure') !== -1 ) { removeEnvironmentFromSmeta(`slitSandwichClosure`); }
    if ( id.indexOf('garbageToContainer') !== -1 ) { removeEnvironmentFromSmeta(`garbageToContainer`); }
    if ( id.indexOf('garbageRecycling') !== -1 ) { removeEnvironmentFromSmeta(`garbageRecycling`); }
    if ( id.indexOf('lampForSale') !== -1 ) { removeEnvironmentFromSmeta(`lampForSale`); }
    if ( id.indexOf('childLock') !== -1 ) { removeEnvironmentFromSmeta(`childLock`); }
    if ( id.indexOf('departureOfBrigade') !== -1 ) { removeEnvironmentFromSmeta(`departureOfBrigade`); }
    if ( id.indexOf('buildingCeiling') !== -1 ) { removeEnvironmentFromSmeta(`buildingCeiling`); }
    if ( id.indexOf('floorLifting') !== -1 ) { removeEnvironmentFromSmeta(`floorLifting`); }
    if ( id.indexOf('tilesDismantling') !== -1 ) { removeEnvironmentFromSmeta(`tilesDismantling`); }
    if ( id.indexOf('roofInstallation') !== -1 ) { removeEnvironmentFromSmeta(`roofInstallation`); }
    if ( id.indexOf('foamCracks') !== -1 ) { removeEnvironmentFromSmeta( `foamCracks`, { added: false, type: 0 } ); }
    if ( id.indexOf('thresholdDismantling') !== -1 ) { removeEnvironmentFromSmeta( `thresholdDismantling`, { added: false, type: 0 } ); }
    if ( id.indexOf('insulationDismantling') !== -1 ) { removeEnvironmentFromSmeta( `insulationDismantling`, { added: false, border: false, edges: false, ceiling: false, floor: false } ); }
    if ( id.indexOf('fastenersInstallation') !== -1 ) { removeEnvironmentFromSmeta( `fastenersInstallation`, { added: false, dryer: false, bike: false, snowBoard: false } ); }
    if ( id.indexOf('aeratedMasonry') !== -1 ) { removeEnvironmentFromSmeta( `aeratedMasonry`, { added: false, length: "", height: "" } ); }
    if ( id.indexOf('corrugatedBoardOutside') !== -1 ) { removeEnvironmentFromSmeta(`corrugatedBoardOutside`); }
    if ( id.indexOf('dismantlingEasy') !== -1 ) { removeEnvironmentFromSmeta(`dismantlingEasy`); }
    if ( id.indexOf('dismantlingHard') !== -1 ) { removeEnvironmentFromSmeta(`dismantlingHard`); }

  }

  function handleChangeAmount( id, mode ) {

    switch ( mode ) {

      case "increase":
        if ( id.indexOf('sockets') !== -1 ) { environment.addSocket(); }
        if ( id.indexOf('lamp') !== -1 ) { environment.addLamp(); }
        if ( id.indexOf('lampForSale') !== -1 ) { addEnvironmentToSmeta(`lampForSale`, +globalState.lampForSale + 1 ); }
        if ( id.indexOf('ledStripBox') !== -1 ) { addEnvironmentToSmeta(`ledStripBox`, +globalState.ledStripBox + 1 ); }
        if ( id.indexOf('childLock') !== -1 ) { addEnvironmentToSmeta(`childLock`, +globalState.childLock + 1 ); }
        if ( id.indexOf('departureOfBrigade') !== -1 ) { addEnvironmentToSmeta(`departureOfBrigade`, +globalState.departureOfBrigade + 1 ); }
        break;

      case "decrease":
        if ( id.indexOf('sockets') !== -1 ) { environment.removeSocket({ all: false }); }
        if ( id.indexOf('lamp') !== -1 ) { environment.removeLamp({ all: false }); }
        if ( id.indexOf('lampForSale') !== -1 ) { addEnvironmentToSmeta(`lampForSale`, +globalState.lampForSale - 1 ); }
        if ( id.indexOf('ledStripBox') !== -1 ) { if ( +globalState.ledStripBox - 1 === 0 ) { alert(`Светодиодная лента не может функционировать без этого блока. Чтобы удалить блок, удалите светодиодную ленту.`); return; } addEnvironmentToSmeta(`ledStripBox`, +globalState.ledStripBox - 1 ); }
        if ( id.indexOf('childLock') !== -1 ) { addEnvironmentToSmeta(`childLock`, +globalState.childLock - 1 ); }
        if ( id.indexOf('departureOfBrigade') !== -1 ) { addEnvironmentToSmeta(`departureOfBrigade`, +globalState.departureOfBrigade - 1 ); }
        break;
      
      default: break;

    }

  }

  const PRICE_MODE_TITLE = {

    [ SMETA_PRICE_MODE.SUM ]: 'цена',
    [ SMETA_PRICE_MODE.WORK ]: 'за работу',
    [ SMETA_PRICE_MODE.MATERIALS ]: 'за материалы'

  }

  return (

    <div className="smeta__table" draggable="false">

      <div className="smeta__table__head items-center font-medium">

        <div>Название</div>

        <div className="text-center">кол-во</div>

        <div className="text-right" onClick={ globalActions.smeta.toggleSmetaPriceMode }>

          { PRICE_MODE_TITLE[ smetaPriceMode ] }

        </div>

        <div/>

      </div>

      <div className="smeta__table__content" draggable="false">

        { smeta?.length > 0 && smeta.map(( row, key ) => {

          let price = numberSpaces( hideZeroFloat( +parseFloat( row.price ).toFixed( 2 ) ) );
          const size = +parseFloat( row.size ).toFixed( 3 );

          let pricePerJob = 0, priceMaterials = 0;

          if ( row?.pricePerJob || +row.pricePerJob === 0 ) { //? второе условие нужно т.к. может быть 0 а 0 в условии идентичен false

            pricePerJob = numberSpaces( hideZeroFloat( +parseFloat( row.pricePerJob ).toFixed( 2 ) ) );
            priceMaterials = numberSpaces( hideZeroFloat( +parseFloat( row.priceMaterials ).toFixed( 2 ) ) );

          }

          const PRICES = {

            [ SMETA_PRICE_MODE.SUM ]: price,
            [ SMETA_PRICE_MODE.WORK ]: pricePerJob,
            [ SMETA_PRICE_MODE.MATERIALS ]: priceMaterials

          }
          
          return (

            +PRICES[ smetaPriceMode ] === 0 ? null :
            
            !row?.info 
            
              ? <SmetaRow

                  id = { row.id }
                  key = { key }
                  name = { row.name }
                  size = { size }
                  price = { PRICES[ smetaPriceMode ] || 0 }
                  action = { row?.action }
                  sizeType = { row.sizeType }
                  canRemove = { row?.canRemove }
                  canIncrease = { row?.canIncrease }
                  handleRemove = { handleRemove }
                  handleChangeAmount = { handleChangeAmount }

                />
            
              : <SmetaMaterialRow

                  id = { row.id }
                  key = { key }
                  name = { row.name }
                  info = { row.info }
                  size = { size }
                  price = { PRICES[ smetaPriceMode ] || 0 }
                  action = { row?.action }
                  sizeType = { row.sizeType }
                  canRemove = { row?.canRemove }
                  canIncrease = { row?.canIncrease }
                  handleRemove = { handleRemove }
                  handleChangeAmount = { handleChangeAmount }

                />

          )
        
        })}

        <div className = "smeta__contract_btn">
  
          <ContractSettingsPage />
  
        </div>

      </div>

    </div>

  );

}

const SmetaRow = ( props ) => {
  
  const { 

    id, 
    name, 
    size,
    price,
    sizeType, 
    action = false, 
    canIncrease = false, 
    canRemove = false, 
    handleChangeAmount = false,
    handleRemove = false,

  } = props;

  return (

    <div 

      className="smeta__table__row items-center" 
      style = {{
        // display: !PRICES[ smetaPriceMode ] ? "none" : "grid" //TODO когда заполню все perjOB раскомментить
      }}
      onClick = { ( e ) => {

        if ( action ) { 
          
          action();
          e.stopPropagation();
        
        }
      
      }}

    >

      <div>{ name }</div>
      
      <div className="text-center flex justify-center items-center">

        { canIncrease && <Decrease onClick = { ( e ) => { handleChangeAmount( id, "decrease" ); e.stopPropagation(); }} style = {{ marginRight: '4px' }} /> }              
        { hideZeroFloat( size ) } { sizeType }
        { canIncrease && <Increase onClick = { ( e ) => { handleChangeAmount( id, "increase" ); e.stopPropagation(); }} style = {{ marginLeft: '4px' }} /> }
        
      </div>
      
      <div className="text-right">{ price || "0" } ₽</div>
      
      <div 

        className="flex items-center justify-center" 
        onClick = { ( e ) => { canRemove && handleRemove( id ); e.stopPropagation(); }}

      >

        { canRemove && <CrossIcon /> }

      </div>

    </div>

  )

}

const SmetaMaterialRow = ( props ) => {
  
  const { 

    id, 
    name, 
    size,
    price,
    info = [],
    sizeType, 
    action = false, 
    canIncrease = false, 
    canRemove = false, 
    handleChangeAmount = false,
    handleRemove = false,

  } = props;

  const accordeonContainer = useRef( null );
  const [ isOpened, setIsOpened ] = useState( false );
  const Toggle = () => setIsOpened( !isOpened );

  const NameWithAnchor = ( 
  
    <Fragment>

      <TriangleIcon className = "smeta__table__accordeon__icon" /> 
      { name } 

    </Fragment> 
    
  );

  return (

    <div 
      className = {`smeta__table__accordeon ${ cssIf( isOpened, `smeta__table__accordeon--opened` ) }`}
      onClick = { Toggle }
    >

      <SmetaRow

        id = { id }
        name = { NameWithAnchor }
        size = { size }
        price = { price }
        action = { action }
        sizeType = { sizeType }
        canRemove = { canRemove }
        canIncrease = { canIncrease }
        handleRemove = { handleRemove }
        handleChangeAmount = { handleChangeAmount }

      />

      <div
        ref = { accordeonContainer }
        className = "smeta__table__accordeon__content"
        style = {{
          maxHeight: !isOpened ? 0 : `${ accordeonContainer.current.scrollHeight }px`
        }}
      >

        <div className = "smeta__table__accordeon__content__container">

          { info.map(( material, key ) => (

            <SmetaMaterialInfo

              key   = { key }
              wall  = { material.wall }
              size  = { material.size }
              name  = { material.name }
              image = { material.image }

            />

          ))}

        </div>

      </div>

    </div>    

  );

}

const SmetaMaterialInfo = ({ wall, size, name, image }) => (

  <div className = "smeta__table__material_info flex relative">

    <div 

      className = "smeta__table__material_info__image"
      style = {{
        backgroundImage: `url(${ image })`
      }}
      
    />

    <div className = "smeta__table__material_info__content">

      <div className = "smeta__table__material_info__name">{ name }</div>
      <div className = "smeta__table__material_info__wall">{ wall }</div>

    </div>

    <div className = "smeta__table__material_info__size absolute">{ size }м2</div>

  </div>

)

export default Smeta;