import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useGlobal from '../../../store';
import BalkonRow from '../../components/balkon.row';
import Button from '../../components/button';
import Container from '../../components/container';
import Loading from '../../components/loading';
import Selectfield from '../../components/selectfield';
import ToggleSlider from '../../components/toggleslider';
import './main.page.scss';

const Main = () => {

  const navigate = useNavigate();
  const openConstructor = () => { navigate('/constructor'); };

  const [ globalState, globalActions ] = useGlobal();
  const { 

    user_id, 
    user_role, 
    balkons, 
    balkons_filter, 
    balkons_filter_archieve,
    employees, 
    balkonsIsLoaded 

  } = globalState;

  const { balkon, global, changeStates } = globalActions;

  const [ filterByEmployee, setFilterByEmployee ] = useState( employees[ 0 ] );

  // const timer = useRef();

  useEffect(() => { global.get(); }, []);
  
  useEffect(() => { 
    
    balkon.all();
  
  }, [ balkons_filter, balkons_filter_archieve, filterByEmployee ]);
  
  return (

    <Container className="projects">

      <br/>

      {/* <h2>Моя статистика:</h2>

      <hr /> */}

      <Button

        text = "+ Новый балкон"
        action = { () => {
          
          balkon.resetProjectStates();
          navigate('/new/1');
        
        }}

      />

      <hr />

      { +user_role < 3 &&
      
        <React.Fragment>

          <ToggleSlider

            title = "Отображать проекты:"
            list = {[ "все", "только мои", "сотрудников" ]}
            selected = { balkons_filter }
            select = { ( v ) => { 
              
              let newState = { balkons_filter: v }
              if ( balkons_filter === 2 ) { newState.balkons_filter_employee = 0; }
              changeStates( newState ) 
            
            }}

          />

          { balkons_filter === 2 &&
          
            <Selectfield

              title = "Cотрудник:"
              selected = { filterByEmployee }
              select = { ( obj ) => {

                setFilterByEmployee( obj );
                changeStates({ balkons_filter_employee: obj.id })

              }}
              list = { employees }
              style = {{ marginTop: '20px' }}
            
            />
          
          }

        </React.Fragment>

      }

      <ToggleSlider

        list = {[ "актуальные", "архивные" ]}
        selected = { balkons_filter_archieve }
        select = { ( v ) => { changeStates({ balkons_filter_archieve: v }) }}

      />

      {/* <h2>Недавно сконструированы:</h2> */}

      { balkonsIsLoaded 
      
        ? balkons.length > 0
        
          ? balkons.map(( el, key ) => (

            <BalkonRow

              key = { key }
              id = { el.id }
              date = { el.date }
              price = { el.price }
              address = { el.address }
              image = { el.preview }
              client = { +user_role < 3 ? ( +el.user.id === +user_id ? "я замерял" : el.user.shortName ) : false }
              status = { el.status }
              windows_schema = { el.windows_schema }
              settings = { el.balkon }
              smeta = { el.smeta }
              action = { () => { balkon.open( el.id, openConstructor ) }}

            />

          ))
        
          : balkons_filter_archieve 
          
            ? "В архиве нет проектов" 
            : "Нет сохранённых балконов"
        
        : <Loading />
        
      }      

    </Container>

  );

}

export default Main;