import React, { useState, useEffect } from "react";
import { insulation_types } from "../../../settings/constants";
import useGlobal from "../../../store";
import Button from "../button";
import Checkbox from "../checkbox";
import ToggleSlider from "../toggleslider";

const SmetaIsolation = ({ close }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { insulation } = globalState;
  const { environment } = globalActions;
  
  const [ type,    setType ]    = useState( insulation.type );
  const [ border,  setBorder ]  = useState( insulation.border );
  const [ edges,   setEdges ]   = useState( insulation.edges );
  const [ ceiling, setCeiling ] = useState( insulation.ceiling );
  const [ floor,   setFloor ]   = useState( insulation.floor );
  const [ electricFloor, setElectricFloor ] = useState( insulation.electricFloor );

  const [ showSaveBtn, setShowSaveBtn ]     = useState( false );

  function handleAddIsolation() {

    const form = {

      type,
      border,
      edges,
      ceiling,
      floor,
      electricFloor

    }

    environment.addInsulation( form );

  }

  useEffect(() => {

    console.log(`тип утепления: `, type );

    if ( 

      type !== insulation.type
      || border !== insulation.border
      || edges !== insulation.edges
      || ceiling !== insulation.ceiling
      || floor !== insulation.floor
      || electricFloor !== insulation.electricFloor
    ) {

      setShowSaveBtn( true );

    } else {

      setShowSaveBtn( false );

    }

  }, [

    type,
    border,
    edges,
    ceiling,
    floor,
    electricFloor

  ]);

  return (

    <div className="smeta__isolation p-14" draggable="false">

      <p className="smeta__smalltext">Отметьте части балкона подлежащие утеплению</p>

      <Checkbox

        text = "Бордюр"
        value = { border }
        set = { setBorder }

      />

      <Checkbox

        text = "Края"
        value = { edges }
        set = { setEdges }

      />

      <Checkbox

        text = "Потолок"
        value = { ceiling }
        set = { setCeiling }

      />

      <Checkbox

        text = "Пол"
        value = { floor }
        set = { setFloor }

      />

      { floor && 
      
        <Checkbox //Доступно к выбору тольтко если активирован флажок "пол" (предыдущий)

          text = "Электрический тёплый пол"
          value = { electricFloor }
          set = { setElectricFloor }

        />
        
      }

      <br />

      <ToggleSlider

        title = "Тип утепления:"
        selected = { type }
        select = { setType }
        list = { insulation_types }

      />

      <br />

      { showSaveBtn &&

        <Button

          //// text = { !globalState[`cupboard_${ side.id }_added`] ? "Добавить" : "Сохранить" }
          text = "Применить"
          action = { handleAddIsolation }

        />

      }

      { insulation.added &&

        <React.Fragment>

          <hr />

          <Button

            text = "Удалить утепление"
            action = { environment.removeInsulation }
            color = "red"

          />

        </React.Fragment>

      }

    </div>

  );

}

export default SmetaIsolation;