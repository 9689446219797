import { post } from 'axios';
import API from '../../settings/api';
//// import cookie from 'react-cookies';

export async function get( store, id ) {

  try {

    const { status, data } = await post( API.windows.get, { id } );
    if ( status !== 200 ) { throw(`Не удалось открыть схему с ID ${ id }`); }

    store.setState({

      windows_id: data.windows.id,
      windows_schema: data.windows.shema,
      windows_schema_inner: data.windows.shema_inner,
      windows_contract: data.windows.contract,
      windows_insulation: data.windows.insulation,
      windows_profile: data.windows.profile,
      windows_count_cameras: data.windows.count_cameras,
      windows_glazing: data.windows.glazing,
      windows_profile_color: data.windows.profile_color,
      windows_mosquitos_count: data.windows.mosquitos_count,
      windows_visor: data.windows.visor,
      windows_nachelnik: data.windows.nachelnik,
      windows_lowtide: data.windows.lowtide,
      windows_comment: data.windows.comment

    });

    //reloadDummy?

  } catch ( err ) {

    console.error( err );
    return false;

  }
  
}

export function renderPartSize({ windows_schema, windows_area_sizes }) {

  const splitterType = 4;
  const windowImageSize = 82;

  if ( windows_schema.length === 0 ) {

    return [ { space: '100%' } ];

  }

  let j = 0;
  let result = [];

  for ( let i = 0; i < windows_schema.length; i++ ) {

    if ( windows_schema[ i ].type === splitterType ) {

      j += 1;

    } else {

      if ( !result[ j ] ) { result[ j ] = { space: 0, size: 0 } }

      result[ j ].space += windowImageSize

      if ( windows_area_sizes?.[ j ] ) {

        result[ j ].size = windows_area_sizes[ j ];

      }

    }

  }

  // console.log(`renderPartSize result = `, result );

  return result;

}

export async function changePartSize( store, index, size ) {

  try {

    let arr = store.state.windows_area_sizes;
    //// debug: 
    ////console.log(`changePartSize( ${ index}, ${size} ) arr before = ${ JSON.stringify( arr )} (original=)`,arr);
    arr[ index ] = size;
    //// debug: 
    ////console.log(`changePartSize( ${ index}, ${size} ) arr after = ${ JSON.stringify( arr )} (original=)`,arr);

    store.setState({ windows_area_sizes: arr });

    store.actions.smeta.calculateSmeta();

  } catch ( err ) {



  }

}