import React, { useState, useEffect } from "react";
import { sill_types, sill_widths } from "../../../settings/constants";
import useGlobal from "../../../store";
import Button from "../button";
import Element from "../element";
import Selectfield from "../selectfield";
import Textfield from "../textfield";
import "./sill.settings.scss";

const SillSettingsForm = ({ disableColorSelector = false }) => { 

  const [ globalState, globalActions ] = useGlobal();
  const {

    sill_type,
    sill_amount,
    sill_size,
    sill_texture_image,
    sill_texture_name

  } = globalState;

  const { changeStates, smeta } = globalActions;

  const [ showSaveSillSizeBtn, setShowSaveSillSizeBtn ] = useState( false );

  const [ sillType, setSillType ] = useState( sill_types.find( el => el.id === sill_type ) );
  const [ length, setLength ] = useState( sill_size.length || 0 );
  const [ width, setWidth ] = useState( sill_size.width || 0 );
  const [ amount, setAmount ] = useState( sill_amount );

  useEffect(() => {

    if ( 

      length !== sill_size.length
      || width !== sill_size.width
      || amount !== sill_amount
      || sillType?.id !== sill_types.find( el => el.id === sill_type ).id

    ) {

      setShowSaveSillSizeBtn( true );

    } else {

      setShowSaveSillSizeBtn( false );

    }

  }, [

    sillType,
    length,
    width,
    amount

  ]);

  useEffect( () => { 
    
    ( sillType?.id && sill_widths?.[ sillType?.id ].indexOf( width ) === -1 )
      && setWidth( sill_widths?.[ sillType.id ][ 0 ] );
  
  }, [ sillType ] );

  function handleSaveSillSize() {

    changeStates({

      sill_type: sillType.id,
      sill_size: { length, width },
      sill_amount: amount

    });

    smeta.calculateSmeta();

    setShowSaveSillSizeBtn( false );

  }

  return (

    <div className = "sill_settings" draggable="false">

      <Selectfield

        title = "Тип подоконника:"
        selected = { sill_types.find( el => el.id === sillType?.id ) }
        select = { setSillType }
        list = { sill_types }        

      />

      { sillType?.id &&
        
        <React.Fragment>

          <div className="selectfield"><div className="selectfield__title">Размеры и количество:</div></div>

          <div className = "flex inputs">
          
            <Textfield

              number
              title = "Длина"
              value = { length }
              set = { setLength }

            />

            <Selectfield

              number
              title = "Ширина:"
              selected = { width }
              select = { setWidth }
              list = { sill_widths?.[ sillType?.id ] || [ 0 ] }        

            />
          
            <Textfield

              number
              title = {`Кол-во`}
              value = { amount }
              set = { setAmount }

            />
          
          </div>

        </React.Fragment>
        
      }

      { showSaveSillSizeBtn &&

        <Button

          text = "Применить"
          action = { handleSaveSillSize }
          style = {{ marginBottom: "20px" }}

        />

      }

      {( !disableColorSelector && sillType?.id === "premium" ) &&
      
        <React.Fragment>

          <div className="selectfield"><div className="selectfield__title">Цвет подоконника:</div></div>

          <Element

            image = { sill_texture_image === "" ? "" : sill_texture_image }
            text = { 
              
              sill_texture_name === ""
                ? "Выберите материал"
                : sill_texture_name
            }
            action = { () => {
              
              changeStates({ 
                
                selectedModelRef: "balkon",
                selectedModelPart: "sill",
                matarialMenuIsOpened: true,
                smetaIsOpened: false,
                smetaBackIsClose: false,
                smetaPage: "smeta",
              
              });
            
            }}

          />

        </React.Fragment>

      }

    </div>

  );

}

export default SillSettingsForm;