import React, { Fragment, useState, useEffect } from 'react';
import hideZeroFloat from '../../../scripts/helpers/hide.zero.float';
import numberSpaces from "../../../scripts/helpers/numbers.with.spaces";
import useGlobal from "../../../store";
import { getBalkonPartSize } from '../../../store/actions/smeta';
import PriceInfoModal from '../price.info';
import "./price.bar.scss";

const PriceBar = () => {

  const [ globalState, globalActions ] = useGlobal();
  const { balkonsIsLoaded, smeta_sum, balkon_type, balkon_size } = globalState;

  const [ wallsSize, setWallsSize ] = useState(0);
  const [ floorAndCeilingSize, setFloorAndCeilingSize ] = useState(0);

  const [ isPriceInfoOpen, setPriceInfoOpen ] = useState( false );

  const priceModal = {
    show: () => setPriceInfoOpen( true ),
    hide: () => setPriceInfoOpen( false ),
    toggle: () => setPriceInfoOpen( !isPriceInfoOpen ),
  }

  function calculateSize() {

    const floorSize = getBalkonPartSize( "floor", balkon_type, balkon_size );
    const ceilingSize = getBalkonPartSize( "ceiling", balkon_type, balkon_size );

    const longWall = getBalkonPartSize( "longWall", balkon_type, balkon_size );
    const homeLongWall = getBalkonPartSize( "homeLongWall", balkon_type, balkon_size );
    const shortWall = getBalkonPartSize( "shortWall", balkon_type, balkon_size );
    const smallShortWall = getBalkonPartSize( "smallShortWall", balkon_type, balkon_size );

    const walls = +longWall + +homeLongWall + +shortWall + +smallShortWall;
    const floorAndCeiling = +floorSize + +ceilingSize;

    setWallsSize( hideZeroFloat( parseFloat( walls ).toFixed( 2 ) ) );
    setFloorAndCeilingSize( hideZeroFloat( parseFloat( floorAndCeiling ).toFixed( 2 ) ) );

  }
  
  useEffect(() => { balkon_size && calculateSize(); }, [ balkonsIsLoaded ]);

  return (

    <Fragment>

      <div className="pricebar flex justify-center items-center">

        <div className="pricebar__wrapper flex justify-between items-center">

          <div className="pricebar__size">

            <div className="pricebar__size__row flex items-end">

              <div className="pricebar__size__text">стены:</div>
              <div className="pricebar__size__value">{ wallsSize }</div>
              <div className="pricebar__size__m2">м<span>2</span></div>

            </div>

            <div className="pricebar__size__row flex items-end">

              <div className="pricebar__size__text">пол/потолок:</div>
              <div className="pricebar__size__value">{ floorAndCeilingSize }</div>
              <div className="pricebar__size__m2">м<span>2</span></div>

            </div>

          </div>

          <div className="pricebar__price text-right" onClick = { priceModal.toggle }>

            { hideZeroFloat( numberSpaces( parseFloat( smeta_sum ).toFixed( 2 ) ) ) }
            <span>₽</span>

          </div>

        </div>

      </div>
      
      <PriceInfoModal opened = { isPriceInfoOpen } close = { priceModal.hide } />
      
    </Fragment>

  );

}

export default PriceBar;