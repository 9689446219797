import cssIf from "../../../scripts/helpers/class.add.if";
import "./balkon.type.scss";

const BalkonItem = ({ name, image = "unset", action, selected = false }) => (

  <div className = {`balkonform__item ${ cssIf( selected, `balkonform__item--selected` ) }`} onClick = { action }>

    <div
      className = "balkonform__item__image"
      style = {{ backgroundImage: `url(${ image })` }}
    />

    <div className="balkonform__item__name">{ name }</div>
  
  </div>

);

export default BalkonItem;