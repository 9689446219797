import React, { useState, useEffect } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { ReactComponent as ApplyIcon } from '../../../images/icons/apply.svg';
import { ReactComponent as CancelIcon } from '../../../images/icons/cancel.svg';
import { ReactComponent as TrashIcon } from '../../../images/icons/trash.svg';
import cssIf from '../../../scripts/helpers/class.add.if';
import useGlobal from '../../../store';
import { renderPartSize } from '../../../store/actions/windows';
import Textfield from '../textfield';
import { SortableWindows, Window } from '../window';
// import BalconBlockOne from './inner.windows/balcon.block.one';
// import BalconBlockTwo from './inner.windows/balcon.block.two';
// import InnerWindow from './inner.windows/inner.window';
import "./windows.inner.constructor.scss";


import { ReactComponent as BalkonBlockOneIcon } from '../window.inner/balcon.block.one/icons/preview.svg';
import { ReactComponent as BalkonBlockTwoIcon } from '../window.inner/balcon.block.two/icons/preview.svg';
import { ReactComponent as InnerWindowIcon    } from '../window.inner/inner.window/icons/preview.svg';
import { SortableInnerWindows } from '../window.inner';

const WindowsInnerConstructor = ({ readOnly = false }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { windows_schema_inner } = globalState;
  const { changeStates, smeta } = globalActions;

  const setSchema = ( arr ) => !readOnly && changeStates({ windows_schema_inner: arr });

  const [ selectedWindow, setSelectedWindow ] = useState("");
  const [ baseWindowHeight, setBaseWindowHeight ] = useState("");

  function handleAddInnerWindow( t ) {

    if( readOnly ) { return; }
    
    const initialInnerWindowSizes = {

      1: { A: "", B: "", C: "", D: "" },
      2: { A: "", B: "", C: "", D: "" },
      3: { X: "", Y: "" },

    }

    const newWindow = {

      id: windows_schema_inner.length + 1,
      type: t,
      rotated: false,
      ...initialInnerWindowSizes[ t ]

    }

    setSchema([

      ...windows_schema_inner,
      newWindow

    ]);

  }

  function handleSelectWindow( id ) { !readOnly && setSelectedWindow( id ); }

  function handleRotateWindow( id ) {

    if( readOnly ) { return; }

    const temp = [];

    windows_schema_inner.forEach( w => {

      let _w = w;
      if ( w.id === id ) { _w.mirrored = !_w.mirrored; }
      temp.push( _w );

    });

    setSchema( temp );

  }

  function handleMoveWindow({ oldIndex, newIndex }) {

    if( readOnly ) { return; }
    const updated = arrayMove( windows_schema_inner, oldIndex, newIndex );
    setSchema( updated );

  }

  function saveWindowSize( side, value ) {

    if( readOnly ) { return; }

    const temp = [];

    windows_schema_inner.forEach( window => {

      let _window = window;

      if ( window.id === selectedWindow ) {

        _window[ side ] = value;

      }

      temp.push( _window );

    });

    setSchema( temp );
    setSelectedWindow("");
    smeta.calculateSmeta();

  }

  function handleRemoveWindow() {

    if( readOnly ) { return; }
    const temp = [];
    windows_schema_inner.forEach( window => window.id !== selectedWindow && temp.push( window ) );
    setSchema( temp );
    setSelectedWindow("");
    smeta.calculateSmeta();

  }

  return (

    <div className = {`windows_constructor ${ cssIf( readOnly, `windows_constructor--readOnly` ) }`}>

      { !readOnly && 

        <React.Fragment>

          <div className = "balkonform__title text-left mb-4">Добавьте внутренние окна:</div>
          <div className = "tip text-left mb-4 font-semibold">чертёж</div>

        </React.Fragment>  
        
      }
      
      <SortableInnerWindows

        axis = "x"
        lockAxis = "x"
        useDragHandle = { true }
        helperClass = "window_flying"
        onSortEnd = { handleMoveWindow }

        windows_schema_inner = { windows_schema_inner } 
        selected = { selectedWindow }
        select = { handleSelectWindow }
        rotate = { handleRotateWindow }

        saveWindowSize = { saveWindowSize }

        style = { readOnly ? { userSelect: 'none' } : {} }
      
      />

      {( selectedWindow && selectedWindow !== "" && !readOnly ) &&
      
        <WindowInnerParams remove = { handleRemoveWindow } />
      
      }

      { !readOnly && 

        <React.Fragment>

          <div className = "tip text-left mb-4 font-semibold">Добавьте внутренние окна:</div>
    
          <div className = "windows_schema_inner windows_inner_schema flex items-center">
            
            <BalkonBlockOneIcon onClick = { () => handleAddInnerWindow( 1 ) } className = "windows_inner_schema-1" />
            <BalkonBlockTwoIcon onClick = { () => handleAddInnerWindow( 2 ) } className = "windows_inner_schema-2" />
            <InnerWindowIcon    onClick = { () => handleAddInnerWindow( 3 ) } className = "windows_inner_schema-3" />

          </div>

        </React.Fragment>
      
      }

    
    </div>

  );

}

export default WindowsInnerConstructor;

const WindowInnerParams = ({ width = "", height = "", apply = () => {}, cancel = () => {}, remove = () => {} }) => {

  return (

    <div className="window_params justify-center"> {/* justify-between items-center */}
  
      <div onClick = { remove } className="remove_window_container flex justify-center items-center">

        <TrashIcon className = "remove_window" />
        Удалить

      </div>
      
    </div>

  );

}