import React, { useState, useEffect } from "react";
import useGlobal from "../../../store";
import Button from "../button";
import Checkbox from "../checkbox";

const SmetaFastenersInstallation = ({ close }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { fastenersInstallation } = globalState;
  const { environment } = globalActions;
  
  const [ dryer, setDryer ] = useState( fastenersInstallation.dryer );
  const [ bike, setBike ]   = useState( fastenersInstallation.bike );
  const [ snowBoard, setSnowBoard ] = useState( fastenersInstallation.snowBoard );

  const [ showSaveBtn, setShowSaveBtn ] = useState( false );

  function handleAddFastenersInstallation() {

    const form = {

      added: true,
      dryer,
      snowBoard,
      bike

    }

    environment.addEnvironmentToSmeta("fastenersInstallation", form );
    close();

  }

  useEffect(() => {

    if ( 

      dryer !== fastenersInstallation.dryer
      || snowBoard !== fastenersInstallation.snowBoard
      || bike !== fastenersInstallation.bike

    ) {

      setShowSaveBtn( true );

    } else {

      setShowSaveBtn( false );

    }

  }, [

    dryer,
    bike,
    snowBoard

  ]);

  return (

    <div className="smeta__isolation p-14" draggable="false">

      <Checkbox

        text = "Сушилка для белья"
        value = { dryer }
        set = { setDryer }

      />

      <Checkbox

        text = "Крепление для велосипеда"
        value = { bike }
        set = { setBike }

      />

      <Checkbox

        text = "Крепление для сноуборда"
        value = { snowBoard }
        set = { setSnowBoard }

      />

      { showSaveBtn &&

        <Button

          text = { !fastenersInstallation.added ? "Добавить" : "Сохранить" }
          action = { handleAddFastenersInstallation }

        />

      }

    </div>

  );

}

export default SmetaFastenersInstallation;