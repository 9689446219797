import React, { Suspense, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Canvas } from "@react-three/fiber";
import { Box, OrbitControls, Sky } from "@react-three/drei";
import Balkon from "../../../models/balkon";
import Environment from "../../../models/environment";
import ConstructorBar from "../../components/bar.constructor";
import useGlobal from "../../../store";
import Loading from "../../components/loading";
import PriceBar from "../../components/price.bar";
import cssIf from "../../../scripts/helpers/class.add.if";
import { Layers } from "three";
import { useControls } from "leva";
import lightSettings from "../../../settings/light";

const Constructor = () => {

  const navigate = useNavigate();

  const [ globalState, globalActions ] = useGlobal();

  const { 

    GLOBAL,

    balkon_isloaded, 
    balkon_type, 
    balkon_enter,

    selectedModelRef, 
    selectedModelPart,

    cupboard_left_full_added,
    cupboard_left_full_type, 
    cupboard_left_full_doors, 
    cupboard_left_full_position,
    cupboard_left_full_rotation,

    cupboard_left_tumb_added,
    cupboard_left_tumb_doors, 
    cupboard_left_tumb_position,
    cupboard_left_tumb_rotation,

    cupboard_left_top_added,
    cupboard_left_top_type, 
    cupboard_left_top_doors, 
    cupboard_left_top_position,
    cupboard_left_top_rotation,

    cupboard_left_bottom_added,
    cupboard_left_bottom_type, 
    cupboard_left_bottom_doors, 
    cupboard_left_bottom_position,
    cupboard_left_bottom_rotation,
    
    cupboard_right_full_added,
    cupboard_right_full_type, 
    cupboard_right_full_doors, 
    cupboard_right_full_position,
    cupboard_right_full_rotation,
    
    cupboard_right_tumb_added,
    cupboard_right_tumb_doors, 
    cupboard_right_tumb_position,
    cupboard_right_tumb_rotation,
    
    cupboard_right_top_added,
    cupboard_right_top_type, 
    cupboard_right_top_doors, 
    cupboard_right_top_position,
    cupboard_right_top_rotation,
    
    cupboard_right_bottom_added,
    cupboard_right_bottom_type, 
    cupboard_right_bottom_doors, 
    cupboard_right_bottom_position,
    cupboard_right_bottom_rotation,
    
    lar_left_added,
    lar_left_position,
    lar_left_rotation,
    
    lar_right_added,
    lar_right_position,
    lar_right_rotation,

  } = globalState;

  const { changeStates, constructor } = globalActions;
  const { setTexture, syncTextures } = constructor;

  const refGL = useRef( null );

  const ligthRef = useRef();
  const boxRef = useRef();
  const dirRef = useRef();

  const applyTextureBalkon = useRef();
  
  const applyTextureCupboardLeftFull = useRef();
  const applyTextureCupboardLeftTumb = useRef();
  const applyTextureCupboardLeftTop = useRef();
  const applyTextureCupboardLeftBottom = useRef();

  const applyTextureCupboardRightFull = useRef();
  const applyTextureCupboardRightTumb = useRef();
  const applyTextureCupboardRightTop = useRef();
  const applyTextureCupboardRightBottom = useRef();

  const applyTextureLarLeft = useRef();
  const applyTextureLarRight = useRef();

  const APPLY_TEXTURE_FUNCS = {

    balkon: applyTextureBalkon,

    cupboard_left_full: applyTextureCupboardLeftFull,
    cupboard_left_tumb: applyTextureCupboardLeftTumb,
    cupboard_left_top: applyTextureCupboardLeftTop,
    cupboard_left_bottom: applyTextureCupboardLeftBottom,

    cupboard_right_full: applyTextureCupboardRightFull,
    cupboard_right_tumb: applyTextureCupboardRightTumb,
    cupboard_right_top: applyTextureCupboardRightTop,
    cupboard_right_bottom: applyTextureCupboardRightBottom,

    lar_left: applyTextureLarLeft,
    lar_right: applyTextureLarRight,

  }

  // LEVA controls
 
  // const ambientLightSettings = useControls('ambientLight', {
  //   intensity: { value: lightSettings[ +balkon_type ].ambient.intensity, min: 0, max: 3.5, step: 0.01 }
  // });

  // let lightTargetSettings = useControls('lightTargetSettings', {

  //   posX: { value: lightSettings[ +balkon_type ].target.x, min: -150, max: 150, step: 0.01 }, 
  //   posY: { value: lightSettings[ +balkon_type ].target.y, min: -150, max: 150, step: 0.01 }, 
  //   posZ: { value: lightSettings[ +balkon_type ].target.z, min: -150, max: 150, step: 0.01 },

  // });

  // const directionallightSettings = useControls('DirectionalLight', {

  //   posX: { value: lightSettings[ +balkon_type ].directional.x, min: -150, max: 150, step: 0.01 }, 
  //   posY: { value: lightSettings[ +balkon_type ].directional.y, min: -150, max: 150, step: 0.01 }, 
  //   posZ: { value: lightSettings[ +balkon_type ].directional.z, min: -150, max: 150, step: 0.01 },
  //   intensity: { value: lightSettings[ +balkon_type ].directional.intensity, min: 0, max: 2.5, step: 0.01 },

  // });

  // const spotlightSettings = useControls('SpotLight', {

  //   posX: { value: lightSettings[ +balkon_type ].spot.x, min: -100, max: 100, step: 0.05 }, 
  //   posY: { value: lightSettings[ +balkon_type ].spot.y, min: -100, max: 100, step: 0.05 }, 
  //   posZ: { value: lightSettings[ +balkon_type ].spot.z, min: -100, max: 100, step: 0.05 },

  //   intensity: { value: lightSettings[ +balkon_type ].spot.intensity, min: 0, max: 2.5, step: 0.01 },
  //   angle: { value: 2.9, min: 0, max: 3.14, step: 0.1 },
  //   decay: { value: lightSettings[+balkon_type].spot.decay, min: -5, max: 5, step: 0.01 },

  // });
 
  // off LEVA conftols (production)

  const ambientLightSettings = { intensity: lightSettings[ +balkon_type ].ambient.intensity }

  const lightTargetSettings = {

    posX: lightSettings[ +balkon_type ].target.x, 
    posY: lightSettings[ +balkon_type ].target.y, 
    posZ: lightSettings[ +balkon_type ].target.z

  }

  const directionallightSettings = {

    posX: lightSettings[ +balkon_type ].directional.x, 
    posY: lightSettings[ +balkon_type ].directional.y, 
    posZ: lightSettings[ +balkon_type ].directional.z,
    intensity: lightSettings[ +balkon_type ].directional.intensity,

  }

  const spotlightSettings = {

    posX: lightSettings[ +balkon_type ].spot.x,
    posY: lightSettings[ +balkon_type ].spot.y,
    posZ: lightSettings[ +balkon_type ].spot.z,

    intensity: lightSettings[ +balkon_type ].spot.intensity,
    angle: 2.9,
    decay: lightSettings[+balkon_type].spot.decay,

  }

  function takeScreenShot() {

    const base64screenshot = refGL.current.domElement.toDataURL();
    changeStates({ balkon_screenshot: base64screenshot });

  }

  const syncTexturesRef = ( syncTarget = false ) => { 
    
    syncTextures( 
      
      APPLY_TEXTURE_FUNCS?.[ syncTarget || selectedModelRef ]?.current, 
      syncTarget
      
    ); 
  
  }

  useEffect(() => { !balkon_isloaded && navigate('/'); }, [ balkon_isloaded ]);

  useEffect(() => { 

    if ( GLOBAL.BALKONS.length > 0 ) {
    
      let balkon = GLOBAL.BALKONS.find( b => b.id === balkon_type );
      balkon = balkon.model.replace('.glb', '');

      const variants = {

        1: "",
        2: "_v2",
        3: "_v3",

      }

      const modelUrl = `${ balkon }${ variants[ +balkon_enter ] }.glb`;

      changeStates({ balkon_model: modelUrl });

      setTimeout(() => {
        
        takeScreenShot();

      }, 5000)
    
    }

    console.log( `-------------` );
    console.log( `store.state`, globalState );
    console.log( `-------------` );
    console.log( `store.actions`, globalActions );
    console.log( `-------------` );
    console.log( `-------------` );
  
  }, [ balkon_enter ]);

  const layers = new Layers()
  layers.enable( 1 )

  const update = () => {
  
    if (

      !( ligthRef && ligthRef.current ) 
      || 
      !( dirRef && dirRef.current ) 

    ) { return; }

    ligthRef.current.target.position.copy( boxRef.current.position )
    ligthRef.current.target.updateMatrixWorld()

    dirRef.current.target.position.copy( boxRef.current.position )
    dirRef.current.target.updateMatrixWorld()

  }

  setTimeout(() => {

    update()
    setTimeout(() => { update() }, 100)

  }, 100 );
  
  return (

    <React.Fragment>

      <PriceBar />

      <ConstructorBar

        selectedPartName = { selectedModelPart }
        setTexture = { ( obj ) => setTexture( obj, APPLY_TEXTURE_FUNCS?.[ selectedModelRef ]?.current ) }
        takeScreenShot = { takeScreenShot }

      />

      <Suspense fallback={ <Loading /> }>

        <Canvas

          shadows 
          colorManagement
          shadowMap
          dpr = {[ 1, 2 ]}
          camera = {{

            //// position: [ 5, 0, 0.01 ], //? Позиция камеры извне для дебага расположения шкафов. Чтобы сработало, нужно закомментить maxDistance в OrbitControls (внизу)
            position: [ 0, 0, -1.01 ],
            fov: 100

          }}
          gl={{ preserveDrawingBuffer: true }}
          onCreated={({ gl, events }) => {

            refGL.current = gl;

          }}

        >
        
          <Balkon
          
            syncTextures = { syncTexturesRef }
            refDOM = { applyTextureBalkon }

          />
          
          { lar_left_added &&

            <Environment

              id = "lar_left"
              model = { `models/lar/lar${ cssIf( +balkon_type === 5, "_small" )}.glb` }
              // position = {[ -0.9, -1.4, -0.02 ]}
              // rotationY = { 0 } 
              position = { lar_left_position }
              rotationY = { lar_left_rotation } 
              refDOM = { applyTextureLarLeft }
              syncTextures = { syncTexturesRef }
              onClick = { lar_left_added ? () => globalActions.environment.openLarSettings('left') : undefined }

            />

          }
          
          { lar_right_added &&

            <Environment

              id = "lar_right"
              model = { `models/lar/lar${ cssIf( +balkon_type === 5, "_small" )}.glb` }
              // position = {[ -0.36, -1.4, 0.01 ]}
              // rotationY = { 3.16 } 
              position = { lar_right_position }
              rotationY = { lar_right_rotation } 
              refDOM = { applyTextureLarRight }
              syncTextures = { syncTexturesRef }
              onClick = { lar_right_added ? () => globalActions.environment.openLarSettings('right') : undefined }

            />

          }
          
          { cupboard_left_full_added &&

            <Environment

              id = "cupboard_left_full"
              model = { `models/cupboards/cupboard_${ balkon_type }_${ cupboard_left_full_doors }_${ cupboard_left_full_type }.glb` }
              // position = {[ -1.53, -1.7, 0.19 ]}
              // rotationY = { 3.2 } 
              position = { cupboard_left_full_position }
              rotationY = { cupboard_left_full_rotation } 
              refDOM = { applyTextureCupboardLeftFull }
              syncTextures = { syncTexturesRef }
              onClick = { cupboard_left_full_added ? () => globalActions.environment.openCupboardSettings('left_full') : undefined }

            />

          }
          
          { cupboard_left_tumb_added &&

            <Environment

              id = "cupboard_left_tumb"
              model = { `models/cupboards/cupboard_${ balkon_type }_${ cupboard_left_tumb_doors }_tumb_left.glb` }
              // position = {[ -1.53, -1.7, 0.19 ]}
              // rotationY = { 3.2 } 
              position = { cupboard_left_tumb_position }
              rotationY = { cupboard_left_tumb_rotation } 
              refDOM = { applyTextureCupboardLeftTumb }
              syncTextures = { syncTexturesRef }
              onClick = { cupboard_left_tumb_added ? () => globalActions.environment.openCupboardSettings('left_tumb') : undefined }

            />

          }
          
          { cupboard_left_top_added &&

            <Environment

              id = "cupboard_left_top"
              model = { `models/cupboards/cupboard_${ balkon_type }_${ cupboard_left_top_doors }_${ cupboard_left_top_type }.glb` }
              // position = {[ -1.53, -1.7, 0.05 ]}
              // rotationY = { 3.2 } 
              position = { cupboard_left_top_position }
              rotationY = { cupboard_left_top_rotation } 
              refDOM = { applyTextureCupboardLeftTop }
              syncTextures = { syncTexturesRef }
              onClick = {cupboard_left_top_added ? () => globalActions.environment.openCupboardSettings('left_top') : undefined }

            />

          }
          
          { cupboard_left_bottom_added &&

            <Environment

              id = "cupboard_left_bottom"
              model = { `models/cupboards/cupboard_${ balkon_type }_${ cupboard_left_bottom_doors }_${ cupboard_left_bottom_type }.glb` }
              // position = {[ -1.53, -1.7, 0.05 ]}
              // rotationY = { 3.2 } 
              position = { cupboard_left_bottom_position }
              rotationY = { cupboard_left_bottom_rotation } 
              refDOM = { applyTextureCupboardLeftBottom }
              syncTextures = { syncTexturesRef }
              onClick = { cupboard_left_bottom_added ? () => globalActions.environment.openCupboardSettings('left_bottom') : undefined }

            />

          }
          
          { cupboard_right_full_added &&

            <Environment

              id = "cupboard_right_full"
              model = { `models/cupboards/cupboard_${ balkon_type }_${ cupboard_right_full_doors }_${ cupboard_right_full_type }.glb` }
              // position = {[ -0.45, -1.4, 0.2 ]}
              // rotationY = { cupboard_right_full_rotation } 
              position = { cupboard_right_full_position }
              rotationY = { cupboard_right_full_rotation } 
              refDOM = { applyTextureCupboardRightFull }
              syncTextures = { syncTexturesRef }
              onClick = { cupboard_right_full_added ? () => globalActions.environment.openCupboardSettings('right_full') : undefined }

            />

          }
          
          { cupboard_right_tumb_added &&

            <Environment

              id = "cupboard_right_tumb"
              model = { `models/cupboards/cupboard_${ balkon_type }_${ cupboard_right_tumb_doors }_tumb_right.glb` }
              // position = {[ -1.11, -1.7, 0.1 ]}
              // rotationY = { cupboard_right_rotation } 
              position = { cupboard_right_tumb_position }
              rotationY = { cupboard_right_tumb_rotation } 
              refDOM = { applyTextureCupboardRightTumb }
              syncTextures = { syncTexturesRef }
              onClick = { cupboard_right_tumb_added ? () => globalActions.environment.openCupboardSettings('right_tumb') : undefined }

            />

          }
          
          { cupboard_right_top_added &&

            <Environment

              id = "cupboard_right_top"
              model = { `models/cupboards/cupboard_${ balkon_type }_${ cupboard_right_top_doors }_${ cupboard_right_top_type }.glb` }
              // position = {[ -1.11, -1.7, 0.1 ]}
              // rotationY = { cupboard_right_rotation } 
              position = { cupboard_right_top_position }
              rotationY = { cupboard_right_top_rotation } 
              refDOM = { applyTextureCupboardRightTop }
              syncTextures = { syncTexturesRef }
              onClick = { cupboard_right_top_added ? () => globalActions.environment.openCupboardSettings('right_top') : undefined }

            />

          }
          
          { cupboard_right_bottom_added &&

            <Environment

              id = "cupboard_right_bottom"
              model = { `models/cupboards/cupboard_${ balkon_type }_${ cupboard_right_bottom_doors }_${ cupboard_right_bottom_type }.glb` }
              // position = {[ -1.11, -1.7, 0.1 ]}
              // rotationY = { cupboard_right_rotation } 
              position = { cupboard_right_bottom_position }
              rotationY = { cupboard_right_bottom_rotation } 
              refDOM = { applyTextureCupboardRightBottom }
              syncTextures = { syncTexturesRef }
              onClick = { cupboard_right_bottom_added ? () => globalActions.environment.openCupboardSettings('right_bottom') : undefined }

            />

          }            

          <fog attach="fog" args={['white', 0, 500]} />
          
          <ambientLight 

            layers={layers} 
            intensity={ambientLightSettings.intensity} 

          />

          <Box

            visible    = { false }
            color      = "#000000"
            ref        = { boxRef }
            scale      = {[ 0.2, 0.2, 0.2 ]}
            castShadow = { false }
            position   = {[ lightTargetSettings.posX, lightTargetSettings.posY, lightTargetSettings.posZ ]}
            size       = {[ 0.1, 0.1, 0.1 ]} 

          />

          <spotLight

            ref       = {ligthRef}
            intensity = { spotlightSettings.intensity } 
            angle     = { spotlightSettings.angle } 
            decay     = { spotlightSettings.decay } 
            penumbra  = { 2 } 
            position  = {[ spotlightSettings.posX, spotlightSettings.posY, spotlightSettings.posZ ]}
            color     = "#fff"
            layers    = {layers}
            shadow-bias          = { -0.004 }
            shadow-camera-left   = { -150 }
            shadow-camera-right  = { 150 }
            shadow-camera-top    = { 150 }
            shadow-camera-bottom = { -150 }
            shadow-mapSize       = {[ 4096, 4096 ]}

          />

          <directionalLight

            castShadow
            ref       = { dirRef }
            layers    = { layers }
            position  = {[ directionallightSettings.posX, directionallightSettings.posY, directionallightSettings.posZ ]}
            intensity = { directionallightSettings.intensity }
            penumbra  = { 2 } 
            shadow-camera-left   = { -10 }
            shadow-camera-right  = {  10 }
            shadow-camera-top    = {  10 }
            shadow-camera-bottom = { -10 }
            shadow-mapSize       = {[ 4096, 4096 ]}

          />

          <Sky 
          
            distance = { 450000 } 
            sunPosition = {[ 0, 1, 0 ]}
            inclination = { 0 } 
            azimuth = { 0.25 }  
            
          />

          <OrbitControls 

            enablePan = { true }
            enableZoom = { true } 
            enableDamping = { true }
            
            minPolarAngle = { 0 }
            maxPolarAngle = { Math.PI }
            maxAzimuthAngle = { Math.PI / 2 }

            maxDistance = { 0.51 } //// закомментить, когда надо камеру вынести за пределы балкона

          />

          {/* <axesHelper 
          
            args = {[ 5 ]}
            position = {[ directionallightSettings.posX, directionallightSettings.posY, directionallightSettings.posZ ]}
            
          />

          <axesHelper 
          
            args = {[ 5 ]}
            position = {[ spotlightSettings.posX, spotlightSettings.posY, spotlightSettings.posZ ]}
            
          />

          <axesHelper 
          
            args = {[ 5 ]}
            position={[ lightTargetSettings.posX, lightTargetSettings.posY, lightTargetSettings.posZ ]}
            
          /> */}

        </Canvas>

      </Suspense>

    </React.Fragment>

  );

}

export default Constructor;