import { BALKON_PART, MATERIAL_TYPES } from "../../constants";
import DEFAULT_MATERIAL_SIZE from "./default.material.sizes";

const BALKON_THREEMETR_SIZES = {

  [ BALKON_PART.HOME_WALL ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.76,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.76,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

  [ BALKON_PART.LEFT_WALL ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.70,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.70,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

  [ BALKON_PART.RIGHT_WALL ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.70,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.70,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],
  },

  [ BALKON_PART.PARAPET ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.58,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.58,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

  [ BALKON_PART.FLOOR ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.64,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.64,
    [ MATERIAL_TYPES.LINOLEUM       ]:  0.64,
    [ MATERIAL_TYPES.LAMINAT_FLOOR  ]:  0.64,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

  [ BALKON_PART.CEILING ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.54,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.54,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

}

export default BALKON_THREEMETR_SIZES;