import { post } from 'axios';
import cookie from 'react-cookies';
import API from '../../settings/api';

export async function get( store, id, setDataLoaded, setName, setEmail, setPhone, setDataFromServer ) {

  const token = cookie.load(`token`);

  try {

    const { status, data } = await post( API.employees.get, { id }, { headers: { token } } );
    if ( status !== 200 ) { throw(`Не удалось открыть проект с ID ${ id }`); }

    const { fullname, email, phone } = data.user;

    setName( fullname || "" );
    setEmail( email || "" );
    setPhone( phone || "" );
    setDataFromServer({ fullname, email, phone });
    setDataLoaded( true );

  } catch ( err ) {

    console.error( err );
    return false;

  }
  
}

export async function add( store, obj = {}, close ) {

  try {

    const token = cookie.load(`token`);

    const params = {

      company_id: store.state.user_company,
      ...obj

    }

    const { status, data } = await post( API.employees.add, params, { headers: { token } } );

    // console.log(`companies.addEmployee data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    await store.actions.balkon.all();

    close();

  } catch ( err ) {

    console.error( err );

  }
  
}

export async function edit( store, obj = {}, close ) {

  try {

    const token = cookie.load(`token`);

    const params = {

      company_id: store.state.user_company,
      ...obj

    }

    const { status, data } = await post( API.employees.edit, params, { headers: { token } } );

    // console.log(`companies.editEmployee data:`, data);

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    await store.actions.balkon.all();

    close();

  } catch ( err ) {

    console.error( err );

  }
  
}

export async function remove( store, id, close ) {

  try {

    if ( !window.confirm(`Вы точно хотите удалить сотрудника?`) ) { return false; }

    const token = cookie.load(`token`);

    const { status, data } = await post( API.employees.remove, { id }, { headers: { token } } );

    if ( status !== 200 || !data?.success) { throw( data.text ); }

    await store.actions.balkon.all();

    close();

  } catch ( err ) {

    console.error( err );

  }
  
}