const texturesPath = `images/textures`;

const BALKON = {

  SIXMETR: 1, 
  THREEMETR: 2, 
  VITRAGE: 3, 
  VITRAGE_SPLIT: 4,
  DOVETAIL: 5,
  SIXMETR_STANDART: 6,
  HRUSHEVKA: 7,

}

const BALKON_PART = {

  HOME_WALL: "homeLongWall",
  LEFT_WALL: "smallShortWall",
  RIGHT_WALL: "shortWall",
  PARAPET: "longWall",
  FLOOR: "floor",
  CEILING: "ceiling",
  SILL: "sill",
  WINDOW_SILL: "windowSill",
  WINDOWS: "windows",

}

const BALKON_PART_TYPE_ID = {

  longWall: 1,
  homeLongWall: 1,
  shortWall: 1,
  smallShortWall: 1,
  ceiling: 1,
  floor: 2,
  cupboard: 3,
  sill: 4,
  windowSill: 6,
  windows: 6,
  window: 6,

}

const MATERIAL_TYPES = {

  LAMINAT:        1,  //// ID ламината в material_types
  WOOD_PARKET:    2,  //// ID дерева стенового паркета в material_types
  WOOD_VAGONKA:   3,  //// ID дерева вагонки в material_types
  WOOD_MDF:       4,  //// ID МДФ в material_types
  STONE_BRICKS:   5,  //// ID декор. кирпича в material_types
  LINOLEUM:       6,  //// ID линолеума в material_types
  LAMINAT_FLOOR:  7,  //// ID ламината для пола в material_types
  TILE:           8,  //// ID плитки в material_types
  QUARC_VINIL:    9,  //// ID кварц-винила в material_types
  LDSP:           10, //// ID ЛДСП в material_types

  COLORIZE: {

    LDSP:         10, //// ID покраски ЛДСП в material_types
    WOOD:         11, //// ID покраски дерева в material_types
    SILL_PREMIUM: 12, //// ID покраски прем.подоконника в material_types
    STONE_BRICKS: 13, //// ID покраски декор. кирпича в material_types
    WINDOW:       14, //// ID покраски окон в material_types
    BRICKS_WALL:  15, //// ID покраски кирпичных стен (не путать с декор кирпичом) в material_types
    BETON_WALL:   16, //// ID покраски бетонных стен в material_types

  }

}

const MATERIAL_WITHOUT_COLORIZE = {

  STONE_BRICKS: 59,
  WOOD_VAGONKA: 115,
  WOOD_PARKET:  124

}

const ENVIRONMENTS_NAMES = {
  rollableTable: `Столик откидной`,
  ledStrip: `Светодиодная лента`,
  ledStripBox: `Блок для светодиодной ленты`
}

const WALL_NAMES_RU = {

  longWall: "Парапет",
  homeLongWall: "Задняя стена",
  shortWall: "Правая стена от входа",
  smallShortWall: "Левая стена от входа",
  floor: "Пол",
  ceiling: "Потолок"
}

const windows_insulation_types = [

  { id: 'standart', name: "Бюджетные" },
  { id: 'premium', name: "Премиальные" },
  { id: 'lamination', name: "С ламинацией" },

];

const windows_profile_types = [

  { id: 'standart', name: "Бюджетный" },
  { id: 'premium', name: "Премиальный" }

];

const windows_profile_color_types = [ "стандартный белый", "ламинация" ];
const clother_dryer_types = [ "Потолочная", "Настенная" ];

const sill_types = [

  { id: 'standart', name: "Бюджетный" },
  { id: 'premium', name: "Премиальный" }

];

const sill_widths = {

  standart: [ 100, 150, 200, 250, 300, 350, 400, 450, 500, 600 ],
  premium: [ 100, 200, 250, 300, 350, 400, 500, 600, 700 ],

}

const SMETA_PRICE_MODE = {

  SUM: 1,
  WORK: 2,
  MATERIALS: 3

}

const OBJECT_TYPE = { // в бд pricelist_type

  MATERIAL: 1,
  ENVIRONMENT: 2,

}

const insulation_types = [ "бюджетный", "термос", "премиум" ]; // 0, 1, 2

const inner_windows_types = {

  balkon_block_cheburashka: 1,
  balkon_block_s_oknom: 2,
  window_1_or_2_stvor: 3,

}

const BALKONS_WITHOUT_PARAPET = [ BALKON.VITRAGE ];

export { 
  
  texturesPath,

  BALKON,
  BALKON_PART,
  BALKON_PART_TYPE_ID,
  MATERIAL_TYPES,
  MATERIAL_WITHOUT_COLORIZE,
  WALL_NAMES_RU,
  ENVIRONMENTS_NAMES,
  OBJECT_TYPE,
  SMETA_PRICE_MODE,

  BALKONS_WITHOUT_PARAPET,

  inner_windows_types,
  windows_insulation_types,
  windows_profile_types,
  windows_profile_color_types,
  sill_types,
  sill_widths,

  insulation_types,
  clother_dryer_types,
 
}