import React, { useState, useEffect, useRef } from 'react';
import './input-lowtide.scss';

const InputLowtide = ( props ) => {

  const {

    amountFirst = "",
    amountSecond = "",
    amountThird = "",
    amountFactor = 1,

    setAmountFirst,
    setAmountSecond,
    setAmountThird,
    setAmountFactor,

    setSummary = false,

  } = props;

  const input = useRef( null );

  const [showInputFirst, setShowInputFirst] = useState( false );
  const [showInputSecond, setShowInputSecond] = useState( false );
  const [showInputThird, setShowInputThird] = useState( false );
  const [showInputFactor, setShowInputFactor] = useState( false );
  const [ amountSummary, setAmountSummary ] = useState("");

  function saveAmountFirst() {

    // console.log(`${ amountFirst }`);
    hideInputFirst();

  }

  function saveAmountSecond() {

    // console.log(`${ amountSecond }`);
    hideInputSecond();

  }

  function saveAmountThird() {

    // console.log(`${ amountThird }`);
    hideInputThird();

  }

  function saveAmountFactor() {

    // console.log(`${ amountFactor }`);
    hideInputFactor();

  }

  useEffect(() => {
    
    AmountSummaryCalc();
    
  }, [amountFirst, amountSecond, amountThird]);
  
  function AmountSummaryCalc() {

    const result = parseInt(amountFirst) + parseInt(amountSecond) + parseInt(amountThird);
    setAmountSummary( result );
    setSummary && setSummary( result );

  }

  function hideInputFirst() { setShowInputFirst( false ); }
  function hideInputSecond() { setShowInputSecond( false ); }
  function hideInputThird() { setShowInputThird( false ); }
  function hideInputFactor() { setShowInputFactor( false ); }

  return (

    <div className="lowtide flex center">

      <div className="lowtide-container">

        <p className="lowtide-subtitle">чертёж:</p>

        <div className="lowtide-block relative">

          <div className="lowtide-block__amount relative">

            <div className="lowtide-block__amount__first absolute flex row">

              { showInputFirst === true 
                
                ? <input
                  
                    ref = { input }
                    type="text" 
                    className="lowtide-block__amount__input pointer"
                    value={ amountFirst }
                    onBlur = { saveAmountFirst }
                    onChange={(e) => { setAmountFirst( e.target.value ) }}
                    onKeyUp={(e) => { 

                      if ( e.key === "Enter" ) {
                        
                        saveAmountFirst();

                      } else if ( e.key === "Escape" ) { 

                        hideInputFirst();

                      }

                    }}
                    
                  />
    
                : <div  
                  
                    className="lowtide-block__amount__input pointer"

                    onClick={() => { 
                      
                      setShowInputFirst(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );
                    
                    }}
                      
                  >
                    
                    <p className="lowtide-block__amount__number pointer">
                      { amountFirst }
                    </p>

                  </div>

                }

                <p 
                  className="lowtide-block__amount__size"                  
                  onClick = { () => {

                    if ( !showInputFirst ) {

                      setShowInputFirst(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );

                    }

                  }}
                >
                  мм
                </p>

              </div>

              <div className="lowtide-block__amount__second absolute flex row">

                  { showInputSecond === true 
                  
                    ? <input
                      
                        ref = { input }
                        type="text" 
                        className="lowtide-block__amount__input pointer"
                        value={ amountSecond }
                        onBlur = { saveAmountSecond }
                        onChange={(e) => { setAmountSecond( e.target.value ) }}
                        onKeyUp={(e) => { 

                          if ( e.key === "Enter" ) {
                            
                            saveAmountSecond();

                          } else if ( e.key === "Escape" ) { 

                            hideInputSecond();

                          }

                        }}
                        
                    />

                  : <div  
                  
                      className="lowtide-block__amount__input pointer"
                      onClick={ () => { 
                        
                        setShowInputSecond(true);
                        setTimeout( () => {

                          input.current.focus()

                        }, 100 );
                      
                      }}
                      
                    >
                      
                      <p className="lowtide-block__amount__number pointer">{ amountSecond }</p>

                    </div>

                }

                <p 
                  className="lowtide-block__amount__size"
                  onClick = { () => {

                    if ( !showInputSecond ) {

                      setShowInputSecond(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );

                    }

                  }}
                >
                  мм
                </p>
                  
              </div>

                <div className="lowtide-block__amount__third absolute flex row">

                  { showInputThird === true 
                  
                  ? <input
                    
                      ref = { input }
                      type="text" 
                      className="lowtide-block__amount__input pointer"
                      value={ amountThird }
                      onBlur = { saveAmountThird }
                      onChange={(e) => { setAmountThird( e.target.value ) }}
                      onKeyUp={(e) => { 

                        if ( e.key === "Enter" ) {
                          
                          saveAmountThird();

                        } else if ( e.key === "Escape" ) { 

                          hideInputThird();

                        }

                      }}
                      
                  />
              
                : <div  
                  
                    className="lowtide-block__amount__input pointer"
                    onClick={() => { 
                      
                      setShowInputThird(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );
                    
                    }}
                      
                  >
                    
                    <p className="lowtide-block__amount__number pointer">{ amountThird }</p>

                  </div>

              }

              <p 
                className="lowtide-block__amount__size"
                onClick = { () => {

                  if ( !showInputThird ) {

                    setShowInputThird(true);
                    setTimeout( () => {

                      input.current.focus()

                    }, 100 );

                  }

                }}
              >
                мм
              </p>
                  
            </div>

          </div>

          <div className="lowtide-block__total flex row">

            <p className="lowtide-block__total__length">Длина:</p>
            <p className="lowtide-block__total__length__number">{ amountSummary || 0 }</p>

            <p 
              className="lowtide-block__total__length__typesize"
              onClick = { () => {

                if ( !showInputFactor ) {

                  setShowInputFactor(true);
                  setTimeout( () => {

                    input.current.focus()

                  }, 100 );

                }

              }}
            >
              мм x
            </p>

            <div className="lowtide-block__total__length__factor">

              { showInputFactor === true 
                
                ? <input
                
                    ref = { input }
                    type="text" 
                    className="lowtide-block__amount__input__factor"
                    value={ amountFactor }
                    onBlur = { saveAmountFactor }
                    onChange={(e) => { setAmountFactor( e.target.value ) }}
                    onKeyUp={(e) => { 

                      if ( e.key === "Enter" ) {
                        
                        saveAmountFactor();

                      } else if ( e.key === "Escape" ) { 

                        hideInputFactor();

                      }

                    }}
                  
                  />
                  
                : <p  

                    className="lowtide-block__total__text__factor"
                    onClick={() => { 
                      
                      setShowInputFactor(true);
                      setTimeout( () => {

                        input.current.focus()

                      }, 100 );
                    
                    }}
                        
                  >
                        
                    { amountFactor }

                  </p>

              }

            </div>

          </div>
        
        </div>

      </div>

    </div>

  );

}

export default InputLowtide;