import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import generatePassword from '../../../scripts/helpers/generate.password';
import API from '../../../settings/api';
import useGlobal from '../../../store';
import Button from '../../components/button';
import Container from '../../components/container';
import EmptySpace from '../../components/emptyspace';
import Loading from '../../components/loading';

import { ReactComponent as PlusIcon } from "../../components/smeta/icons/plus.svg";
import Textfield from '../../components/textfield';

const WindowsPartnerForm = () => {

  const navigate = useNavigate();

  const [ globalState, globalActions ] = useGlobal();
  const { user_role, user_company, balkonsIsLoaded } = globalState;
  const { windowsPartners } = globalActions;

  const { id } = useParams();

  const [ dataLoaded, setDataLoaded ] = useState( false );
  const [ dataFromServer, setDataFromServer ] = useState({});

  const [ company, setCompany ] = useState("");
  const [ name, setName ] = useState("");
  const [ address, setAddress ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ whatsApp, setWhatsApp ] = useState("");
  const [ telegram, setTelegram ] = useState("");

  const isFormValidated = ( name.length > 2 && company.length > 2 )

  const isDataChanged = ( 

    company !== dataFromServer.company   ||
    name !== dataFromServer.name         ||
    address !== dataFromServer.address   ||
    email !== dataFromServer.email       ||
    phone !== dataFromServer.phone       ||
    whatsApp !== dataFromServer.whatsApp ||
    telegram !== dataFromServer.telegram
    
  );
  
  useEffect(() => { 

    !id 
      ? navigate(`/windows_partners/add`)
      : id !== "add" && 
        windowsPartners.get( 
          id, 
          setDataLoaded, 
          setCompany,
          setName,
          setAddress,
          setEmail,
          setPhone,
          setWhatsApp,
          setTelegram,
          setDataFromServer 
        ) 
      
  }, [ id ]);

  const backToWindowsPartners = () => navigate(`/windows_partners`);

  function sendForm() {

    let popupData = {

      company_name: company,
      owner_name: name,
      address,
      phone,
      email,     
      whatsApp,     
      telegram     

    }

    if ( id === "add" ) {

      windowsPartners.add( popupData, backToWindowsPartners );

    } else {

      popupData.id = id;
      windowsPartners.edit( popupData, backToWindowsPartners );

    }

  }
  
  return (

    <Container className = "employees smeta flex flex-col" style = {{ minHeight: '100%' }}>

      <div className="smeta__head flex justify-between items-center">
        
        <div className="smeta__head__title font-medium">{ id === "add" ? "Добавить" : "Карточка" } поставщика</div>

        <div 
          className = {`smeta__head__btn smeta__head__btn--close flex items-center font-medium`}
          onClick = { backToWindowsPartners }
        >
          
          { id === "add" ? "отмена" : "назад" }

          <PlusIcon />

        </div>

      </div>

      <hr style = {{ marginTop: '0px' }} />

      { !( dataLoaded || id === "add" ) ? <Loading /> :

        <div className = "flex-col flex-1" style = {{ display: 'flex' }}>

          <Textfield

            readOnly = { +user_role === 3 }
            title = "Название компании:"
            value = { company }
            set = { setCompany }

          />

          <Textfield

            readOnly = { +user_role === 3 }
            title = "Адрес компании:"
            value = { address }
            set = { setAddress }

          />

          <Textfield

            readOnly = { +user_role === 3 }
            title = "ФИО контактного лица:"
            value = { name }
            set = { setName }

          />

          <Textfield

            readOnly = { +user_role === 3 }
            title = "E-mail:"
            value = { email }
            set = { setEmail }

          />

          <Textfield

            readOnly = { +user_role === 3 }
            title = "Телефон:"
            value = { phone }
            set = { setPhone }

          />

          <Textfield

            readOnly = { +user_role === 3 }
            title = "WhatsApp:"
            value = { whatsApp }
            set = { setWhatsApp }

          />

          <Textfield

            readOnly = { +user_role === 3 }
            title = "Telegram:"
            value = { telegram }
            set = { setTelegram }

          />

          { user_role !== 3 &&
          
            <React.Fragment>

              <EmptySpace />

              <hr className="small" style = {{ marginBottom: '0px' }} />

              {( id === "add" || ( id !== "add" && isDataChanged ) ) &&

                <Button

                  text = { id === "add" ? "Добавить" : "Сохранить" }
                  action = { sendForm }
                  disabled = { !isFormValidated }

                />
              
              }              

              { id !== "add" &&

                <React.Fragment>

                  <hr className="small" style = {{ marginBottom: '0px' }} />

                  <Button

                    text = "Удалить поставщика окон"
                    color = "red"
                    action = { () => windowsPartners.remove( id, backToWindowsPartners ) }

                  />

                </React.Fragment>

              }
            
            </React.Fragment>

          }

        </div>

      }

    </Container>

  );

}

export default WindowsPartnerForm;