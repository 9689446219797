import { BALKON_PART, MATERIAL_TYPES } from "../../constants";
import DEFAULT_MATERIAL_SIZE from "./default.material.sizes";

const BALKON_SIXMETR_SIZES = {

  [ BALKON_PART.HOME_WALL ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  1.12,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  1.12,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

  [ BALKON_PART.LEFT_WALL ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  1.40,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  1.40,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

  [ BALKON_PART.RIGHT_WALL ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.78,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.78,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],
  },

  [ BALKON_PART.PARAPET ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.84,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.84,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

  [ BALKON_PART.FLOOR ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.80,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.80,
    [ MATERIAL_TYPES.LINOLEUM       ]:  0.80,
    [ MATERIAL_TYPES.LAMINAT_FLOOR  ]:  0.80,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

  [ BALKON_PART.CEILING ]: {

    [ MATERIAL_TYPES.LAMINAT        ]:  0.66,
    [ MATERIAL_TYPES.QUARC_VINIL    ]:  0.66,
    [ MATERIAL_TYPES.WOOD_PARKET    ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_PARKET ],
    [ MATERIAL_TYPES.WOOD_VAGONKA   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_VAGONKA ],
    [ MATERIAL_TYPES.WOOD_MDF       ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.WOOD_MDF ],
    [ MATERIAL_TYPES.STONE_BRICKS   ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.STONE_BRICKS ],
    [ MATERIAL_TYPES.TILE           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.TILE ],
    [ MATERIAL_TYPES.LDSP           ]:  DEFAULT_MATERIAL_SIZE[ MATERIAL_TYPES.LDSP ],

  },

}

export default BALKON_SIXMETR_SIZES;