import { useRef, useState } from "react";

const WindowInnerSizeField = ( props ) => {

  const {

    sortName = "",
    value = "",
    set = "",
    save = () => {}

  } = props;

  const input = useRef( null );

  const [ showInput, setShowInput ] = useState( false );

  function hideInput() { setShowInput( false ); }
  function toggleEditMode() { setShowInput(!showInput); }

  function apply() {

    save();
    hideInput();

  }

  return (

    <div className={`window_inner_textfield window_inner_textfield--${ sortName }`}>   
    
      { showInput
      
        ? <input
                    
            type = "text"
            ref = { input }
            placeholder = { sortName }
            className = {`window_inner_textfield__input window_inner_textfield__input--${ sortName } absolute`}
            value = { value }
            onBlur = { apply }
            onChange = { ( e ) => { set( e.target.value ) }}
            onKeyUp  = { ( e ) => { 

              if ( e.key === "Enter" ) {
                
                apply();

              } else if ( e.key === "Escape" ) { 

                hideInput();

              }

            }}
          
          />

        : <div  

            className="window_inner_textfield"
            onClick = { () => { 
              
              toggleEditMode();
              setTimeout( () => { input.current.focus() }, 100 );
            
            }}
            
          >
          
            <p className={`window_inner_textfield__text window_inner_textfield__text--${ sortName } `}>
              { value !== "" ? value : sortName }
            </p>

          </div>

      }

    </div>

  );

}

export default WindowInnerSizeField;