import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import generatePassword from '../../../scripts/helpers/generate.password';
import API from '../../../settings/api';
import useGlobal from '../../../store';
import Button from '../../components/button';
import Container from '../../components/container';
import EmptySpace from '../../components/emptyspace';
import Loading from '../../components/loading';

import { ReactComponent as PlusIcon } from "../../components/smeta/icons/plus.svg";
import Textfield from '../../components/textfield';

const EmployeeForm = () => {

  const navigate = useNavigate();

  const [ globalState, globalActions ] = useGlobal();
  const { user_role, user_company, balkonsIsLoaded } = globalState;
  const { employees } = globalActions;

  const { id } = useParams();

  const [ dataLoaded, setDataLoaded ] = useState( false );
  const [ dataFromServer, setDataFromServer ] = useState({});

  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ phone, setPhone ] = useState("");
  const [ pass, setPass ] = useState("");

  const [ isPasswordResetted, setIsPasswordResetted ] = useState( false );
  const isEmailOrPasswordChanged = isPasswordResetted || email !== dataFromServer.email;

  const isFormValidated = (
    name.length > 2 && email.indexOf('@') !== -1 && email.indexOf('.') !== -1 && phone.length >= 10 
    && ( ( id !== "add" && !isPasswordResetted ) || pass.length >= 3 )
  )

  useEffect(() => { ( balkonsIsLoaded && +user_role === 3 ) && navigate(`/`); }, [ balkonsIsLoaded ]);
  useEffect(() => { !id ? navigate(`/team/add`) : id !== "add" && employees.get( id, setDataLoaded, setName, setEmail, setPhone, setDataFromServer ) }, [ id ]);

  const backToEmployees = () => navigate(`/team`);

  function sendForm() {

    let popupData = {

      fullname: name,
      phone: phone,
      email: email      

    }

    if ( id === "add" || isPasswordResetted ) { popupData.pass = pass; }

    if ( id === "add" ) {

      employees.add( popupData, backToEmployees );

    } else {

      popupData.id = id;
      employees.edit( popupData, backToEmployees );

    }

  }
  
  return (

    <Container className = "employees smeta flex flex-col" style = {{ minHeight: '100%' }}>

      <div className="smeta__head flex justify-between items-center">
        
        <div className="smeta__head__title font-medium">{ id === "add" ? "Добавить" : "Карточка" } сотрудника</div>

        <div 
          className = {`smeta__head__btn smeta__head__btn--close flex items-center font-medium`}
          onClick = { backToEmployees }
        >
          
          { id === "add" ? "отмена" : "назад" }

          <PlusIcon />

        </div>

      </div>

      <hr style = {{ marginTop: '0px' }} />

      { !( dataLoaded || id === "add" ) ? <Loading /> :

        <div className = "flex-col flex-1" style = {{ display: 'flex' }}>

          <Textfield

            title = "ФИО сотрудника:"
            value = { name }
            set = { setName }

          />

          <Textfield

            title = "E-mail:"
            value = { email }
            set = { setEmail }

          />

          <Textfield

            title = "Телефон:"
            value = { phone }
            set = { setPhone }

          />

          { ( id === "add" || isPasswordResetted ) &&

            <React.Fragment>

              <div className={`textfield`}>
                <p className="textfield__title">Пароль:</p>
              </div>

              <div className="flex generate_pass">

                <Textfield

                  value={ pass }
                  set = { setPass }

                />

                <Button 

                  text="Сгенерировать"
                  className="popup__button__generate-pass__employee"
                  action = {() => setPass( generatePassword() )}

                />

              </div>

            </React.Fragment>

          }

          {( id !== "add" && !isPasswordResetted ) &&

            <React.Fragment>

              <div className={`textfield`}>
                <p className="textfield__title">Пароль:</p>
              </div>

              <div 
                className="resetpassword__text"
                onClick={() => { 
                  window.confirm("Вы точно хотите сбросить пароль?") && setIsPasswordResetted( true );
                }}
              >

                Мы не храним пароли в открытом виде.<br/>
                Установить новый пароль вы можете <span>сбросив старый</span>.
                
              </div>

            </React.Fragment>

          }

          {(

            ( id === "add" && email.indexOf('@') !== -1 && email.indexOf('.') !== -1 && pass.length > 3 && name.length > 7 && phone.length > 9 ) 
            || 
            ( id !== "add" && isEmailOrPasswordChanged ) 

          ) &&

            <a 
              href={`mailto:${email}?body=${ id === "add" ? "Данные" : "Изменились данные"} для входа в <a href="${ API.CONSTRUCTOR_URL }">${ API.CONSTRUCTOR_URL }</a>: <br>логин: ${( id === "add" || email !== dataFromServer.email ) ? email : "тот же, что и был"} <br>пароль: ${( id === "add" || isPasswordResetted ) ? pass : "тот же, что и был"}&subject=${ id === "add" ? "Добро пожаловать" : "Изменились данные для входа" } в конструктор балконов!`}
              rel="norefferer"
              target="_blank"
            >
              <Button

                className="popup__button__generate-pass__employee"
                text = "Отправить сотруднику данные для входа"
                color = "yellow"

              />

            </a>

          }

          <EmptySpace />

          <hr className="small" style = {{ marginBottom: '0px' }} />

          <Button

            text = { id === "add" ? "Добавить" : "Сохранить" }
            action = { sendForm }
            disabled = { !isFormValidated }

          />

          { id !== "add" &&

            <React.Fragment>

              <hr className="small" style = {{ marginBottom: '0px' }} />

              <Button

                text = "Удалить сотрудника"
                color = "red"
                action = { () => employees.remove( id, backToEmployees ) }

              />

            </React.Fragment>

          }

        </div>

      }

    </Container>

  );

}

export default EmployeeForm;