import React, { useState, useEffect } from "react";
import { insulation_types } from "../../../settings/constants";
import useGlobal from "../../../store";
import Button from "../button";
import Checkbox from "../checkbox";

const SmetaRemovalWithWelding = ({ close }) => {

  const [ globalState, globalActions ] = useGlobal();
  const { removalWithWelding } = globalState;
  const { environment } = globalActions;
  
  const [ forward, setForward ] = useState( removalWithWelding.forward );
  const [ left,    setLeft    ] = useState( removalWithWelding.left    );
  const [ right,   setRight   ] = useState( removalWithWelding.right   );

  const [ showSaveBtn, setShowSaveBtn ]     = useState( false );

  function handleAddRemovalWithWelding() {

    const form = {

      forward,
      left,
      right,

    }

    environment.addRemovalWithWelding( form );

  }

  useEffect(() => {

    if ( 

      removalWithWelding.forward !== forward
      ||
      removalWithWelding.left !== left
      ||
      removalWithWelding.right !== right

    ) {

      setShowSaveBtn( true );

    } else {

      setShowSaveBtn( false );

    }

  }, [

    forward,
    left,
    right

  ]);

  return (

    <div className="smeta__isolation p-14" draggable="false">

      <p className="smeta__smalltext">на 150-200 мм.</p>

      <Checkbox

        text = "Вперёд"
        value = { forward }
        set = { setForward }

      />

      <Checkbox

        text = "Влево от входа"
        value = { left }
        set = { setLeft }

      />

      <Checkbox

        text = "Вправо от входа"
        value = { right }
        set = { setRight }

      />

      <br />

      { showSaveBtn &&

        <Button

          text = "Применить"
          action = { handleAddRemovalWithWelding }

        />

      }

    </div>

  );

}

export default SmetaRemovalWithWelding;